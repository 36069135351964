
var snake = require('to-snake-case');
import { IsJsonArray, IsSchemaUnityVector3 ,GetGenericType ,GetNestedId } from "./../Utilities";
import TypeSupporter from './TypeSupporter';
import { ProgressPlugin } from "webpack";
var mongoose = require("mongoose");

interface Prop {
    type: string,
    ref_?: string,
}

export function BuildContent(schema, type : string, context, depth : Array<string> = []) 
{
    if (depth.length == 0)
    {
        depth = [String(context.content.length-1)];
    }

    if (new TypeSupporter().IsTypeSupported(type))
    {
        return _CreateContentFromType(type,context,depth);
    }
    else
        return BuildContentRecursive(schema, type, {} ,context,depth)
}

function BuildContentRecursive(schema, type : string, content, context, oldDepth : Array<string>) {

    if ("properties" in schema)
    {
        content["type"] = type;

        for (var key in schema.properties) {

            var prop = schema.properties[key];
            prop = convertRef(prop);
            
            if (typeof (prop.type) == 'undefined') {
                console.log("error building ", key, " : ", prop, ": ", prop.type);
                continue;
            }

            var depth = oldDepth.slice();
            depth.push(key);

            content = AddPropToContent(content,prop,key,context,depth);
        }
    }
    else
    {
        var prop : any = {};
        prop["type"] = type;

        content = AddPropToContent(content,prop,"Fake",context,oldDepth);
        content = content["Fake"];
    }

    return content;
}

export function _CreateContentFromType(type : string, context,depth : Array<string>)
{

    var key = "key";
    var content = AddPropToContent({}, {"type":type}, key,context,depth)
    content = content[key];

    if ((typeof content === 'object') && !(content.length == 0))
    {
        content["type"] =GetNestedId(type,false,true);
    }
        
    return content;
}

//add check for valid type and if so we dont run through properties. instead we just call add prop to content
function AddPropToContent(content, prop : Prop, key, context, depth : Array<string>) {
  
    if (prop.type.includes("ContentSelectionAttribute"))
    {
        content[key] = "";
        //prop.type = prop.type.split('.')[1];
    }

    else if (prop.type.includes("AdminConsoleDictionaryAttribute")) {
        content[key] = {};
    }

    /*
    else if (prop.type.includes("AbsoluteLengthArrayAttribute"))
    {
        console.log("aaaaa?" ,prop , " , ",key)

        //let defaultValue = BuildContent(props.)

        content[key] = [{},{},{},{},{},{}];

        /*
        for(let i =0;i<prop["AbsoluteLength"]-1;i++)
        {
            content[key].push({});
        }
    }
    */
    

    else if (prop.type.includes("AdminConsole")&&(prop.type.includes("Attribute."))&&(!prop.type.includes("[]")))
    {
        content[key] = "";
    }

    else if ((prop.type == "number")||(prop.type=="System.Single")) {
        content[key] = 0.0;

        if ("default_value" in prop)
            content[key] = parseFloat(prop["default_value"]);
    }
   
    else if (((key == "position") && (IsSchemaUnityVector3(prop)))||(prop.type == "UnityEngine.Vector3")) {

        content[key] = {
            "x": 0.0,
            "y": 0.0,
            "z": 0.0
        }

        if ("default_value" in prop)
        {
            content[key] = JSON.parse(prop["default_value"])
        }    
    }

    else if (prop.type == "integer") {
        content[key] = 0;

        if ("default_value" in prop)
            content[key] = parseInt(prop["default_value"]);
    }

    else if (prop.type == "boolean") {

        content[key] = false;

        if ("default_value" in prop)
        {
            content[key] = JSON.parse((prop["default_value"]));
        }
    }

    else if (((IsJsonArray(prop.type)) && (prop.type[0] == "string"))||(prop.type == "string")) {
        content[key] = "";

        if ("default_value" in prop)
            content[key] = JSON.parse(prop["default_value"]);

    }
    else if (((prop.type[prop.type.length - 2] == '[') && (prop.type[prop.type.length - 1] == ']'))|| (Array.isArray(prop.type))||(prop.type == "Admin.MultiSelect")||(prop.type.includes("System.Collections.Generic.List"))) {
        
        content[key] = [];
    }

    else if (prop.type.includes("LocId"))
    {
        var contentType = context.contentMode;
        var contextTracker = context.content;

        var identifier = ""

        if (("isSecondary" in context) &&(context.isSecondary))
        {
            for(var index in context.content)
            {
                if (context.content[index].id == depth[0])
                {
                    identifier = context.content[index].admin_console_display_name+"_"+new mongoose.Types.ObjectId();
                    contextTracker = context.secondaryContent;
                    break;
                }
                    
            }
        }
        else
            identifier = context.content[depth[0]].admin_console_display_name;
        
        //build out loc code
        var code = contentType+"_"+identifier;

        for(let index = 0; index < depth.length; index++)
        {
            var depthValue = ""+depth[index];

            if (!(depthValue in contextTracker))
                break;

            contextTracker = contextTracker[depthValue]

            if ((index > 0)&&(depthValue!="localization"))
                code += "_"+depthValue;
        }

        if (Array.isArray(contextTracker))
        {
            code+="_"+contextTracker.length;
        }
        else
            code+="_"+key;

        //update localizations 
        context.localization.forEach((language)=>{
            context.UpdateLocalization(code,"",language.title.code);
        })

        var codeName = "code";//Object.keys(prop.properties)[0];
        var locId = {};

        locId[codeName] = code;

        content[key] = locId;

        console.log("code is ",code);
    }

    else if (prop.type.includes('Core.Models.Id')) {

        var str = prop.type.split(", Assembly-CSharp, Version=0.0.0.0")[0].split(".");
        var idType = str[str.length - 1];

        //TODO remove this hack
        if (snake(idType) == "hex_rule_model") {
            idType = content.type;
        }

        content[key] = {
            "value": 0,
            "type": snake(idType)
        }
    }
    
    else if (prop.type.includes('Core.Models.AssetId')) {

        var folderName = "Prefabs";
        var trueType = GetGenericType(prop.type);

        if (prop.type.includes(".Fx"))
        {
            folderName = "Prefabs";
            trueType = GetGenericType(prop.type);
        }
        else if (prop.type.includes("UnityEngine.Sprite")) {
            folderName = "Sprites";
            trueType = "Sprites";
        }
        else if (prop.type.includes("UnityEngine.AudioClip"))
        {
            folderName = "Sounds";
            trueType = "Sounds";
        }
        else if (prop.type.includes("Material"))
        {
            folderName = "Materials";
            trueType = "Materials"
        }

        content[key] = { "folder_name": folderName, "resource_name": "", "true_type":trueType };
    }

    else if (prop.type == "UnityEngine.Color") {
        content[key] = {
            "r": 1.0,
            "g": 1.0,
            "b": 1.0,
            "a": 1.0
        }

        if ("default_value" in prop)
            content[key] = JSON.parse(prop["default_value"]);
    }

    else if ((prop.type == "UnityEngine.DateTime")|| (prop.type == "UnityEngine.Date")|| (prop.type == "UnityEngine.Time"))
    {
        content[key] = new Date();
        
        //if ("default_value" in prop)
           // content[key] = prop["default_value"];
    }
    else {
        if (key == "Fake")
        {
            content[key] = undefined;
        }
            
        else
        {
            var newDepth = depth.slice();
            newDepth.push(key);

            content[key] = BuildContentRecursive(prop, prop.type, {},context,newDepth);
        }   
    }

    return content;
}


function convertRef(prop : Prop) {
    if (typeof (prop.type) == "undefined") {
        prop["type"] = prop.ref_;
    }

    return prop;
}