import SchemaCache from '../utilities/Cache Utilities/SchemaCache';
import ContentCache from '../utilities/Cache Utilities/ContentCache';
import { ChangeMode } from './ContentActions';
var upperCamelCase = require('uppercamelcase');

export function AsyncChangeMode(dispatch,mode,subSelection)
{
    return async (dispatch,getState) =>{

        var type = mode;
        var allContent = getState().content.allContent
        var typeInfo = {};

        try {

            var schemaResults = await new SchemaCache().GetCacheValue(type);
    
            //case this content has already been pulled
            if (type in allContent) {
                typeInfo = { content: allContent[type].primaryContent, schema: schemaResults };
            }
    
            //case this content is yet to be added
            else {

                var contentResults = await new ContentCache().GetCacheValue(upperCamelCase(type));
                var content = [...contentResults];

                if (typeof (contentResults) == "undefined") {
                    content = []
                }
    
                typeInfo = { content: content, schema: schemaResults };
            }
        }
        catch (error) {

            console.log("error getting type info ",error);
            typeInfo = { content: null, schema: null };
        }

        await dispatch(ChangeMode({mode,subSelection,typeInfo}));
    }
}

/*
const AsyncChangeMode = async (type: string,allContent:any) =>{
    try {
        var schemaResults = await new SchemaCache().GetCacheValue(type);

        //case this content has already been pulled
        if (type in allContent) {

            return { content: allContent[type], schema: schemaResults };
        }

        //case this content is yet to be added
        else {
            var contentResults = await new ContentCache().GetCacheValue(upperCamelCase(type));
            var content = contentResults;

            if (typeof (contentResults) == "undefined") {
                content = []
            }

            return { content: content, schema: schemaResults };
        }
    }
    catch (error) {
        console.log("error getting type info ",error);
        return { content: null, schema: null };
    }
}*/