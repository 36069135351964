import React from "react";
var mongoose = require("mongoose");
var config = require("./../../../config.json")

import { BackupSelectorComponent } from "./../components/static components/BackupSelectorComponent";
import VerificationComponent from './../components/static components/VerificationComponent'
import { BackupContentPublishProduction, RepairContent } from './../utilities/web interface utilities/WebApi';
import Context from './../models/Context'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
    context: Context
}

interface State {
    isLoading:boolean
}


export class SettingsContainer extends React.Component<Props, State> {
    constructor(props : Props) {
        super(props);

        this.state = {
            isLoading:false
        }
        
        // PullBackups().then((backups)=>{
        //     this.backups = backups;
        // })
    }

    PushToProd() {
        console.log('pushing too prod')
        //pass name of most recent backup
        var timeStamp = new Date().toISOString();
        BackupContentPublishProduction(timeStamp).then((result) => {
            alert(result);
        });
        setTimeout(() => {
            // Your code to be executed after 2 seconds
            alert("Metatron Content successfully pushed to Production")
          }, 2000);
    }
    
    async CallRepairContent()
    {
        this.setState({isLoading:true});

        console.log("WTF?!");

        let result = await RepairContent();
        alert(result);

        this.setState({isLoading:false});
    }


    render() {
        // if (true) {
        if ("production_url" in config) {
            return (<div>
                <BackupSelectorComponent context={this.props.context} />
                <br />
                <br />
                <hr />

                <button className="btn btn-success" onClick={async () => {
                    await this.CallRepairContent();
                }}>{"Repair Content"}</button>

                <Backdrop open={this.state.isLoading } color={'#fff'}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <br />
                <br />
                <hr />
                <VerificationComponent key={new mongoose.Types.ObjectId().toString()} buttonColor="warning" buttonText="Push Changes To Production" verificationText="Are you sure you want to push your changes??" callback={() => this.PushToProd()} />
            </div>);
        }
        else
            return (
                <div>
                    <BackupSelectorComponent context={this.props.context} />
                    
                    <br />
                    <br />
                    <hr />

                    <button className="btn btn-success" onClick={async () => {
                        await this.CallRepairContent();
                    }}>{"Repair Content"}</button>

                    <Backdrop open={this.state.isLoading } color={'#fff'}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>);
    }
}


