
import { HeaderBar } from "./../components/static components/HeaderBar";
import { connect } from "react-redux";
import React, { useEffect } from "react";

import { AsyncChangeMode } from './../actions/AsyncContentActions';
import { SetContent, AddContent, UpdateContent ,ChangeSubSelection, RemoveSelectedContent,UpdateSecondaryContent, SetSecondaryContent, WipeContent, SetLocalization, UpdateLocalization, DeleteLocalization , AddContentListener,RemoveContentListener, RemoveSecondaryContent, ClearChanges } from "./../actions/ContentActions"
import { AddLink , RemoveLink, RemoveSource ,RemoveLinkContent} from './../actions/LinkingTableActions';

import { GetCookie } from "./../utilities/web interface utilities/WebApi";
import ContentCache from './../utilities/Cache Utilities/ContentCache';

import { LocalizationContainer } from "./LocalizationContainer";
import { HexRuleContainer } from "./HexRuleContainer";
import { HasSpawnInfoContainer } from "./HasSpawnInfoContainer";
import { LoginContainer } from "./LoginContainer";
import { SettingsContainer } from "./SettingsContainer";
import {SpawnLayerComponent} from "../components/static components/SpawnLayerComponent"

import TypeContainer from "./TypeContainer";

import Context from './../models/Context'

import { useSelector, useDispatch } from 'react-redux'

//import { AllStates, ContentState} from './../models/AllStates'
//import { ChangeMode } from "../actions/ContentActionsOld";
import { STATES } from "mongoose";

interface Props extends Context
{
}

export const App:React.FC<Props> = (props) => {
    const [initialized, UpdateInitialized] = React.useState(false);
    const [loginToken, UpdateLoginToken] = React.useState("");

    var initializeAdminConsole = (callback) => {
        new ContentCache().GetCacheValue("LanguageLocalizationSet").then((localization) => {
            props.SetLocalization(localization);
            UpdateInitialized(true);
            callback();
        })
    }

    var GetContainerFromId = (type,schemaValue) => {

        var isSingleton = false;

        if (!(typeof(schemaValue) == "undefined"))
            isSingleton = (("attribute" in schemaValue)&&(schemaValue["attribute"]=="is_singleton"));

        if (type == 'hex_rule_model') {
            return { displayType: HexRuleContainer, additionalInfo: {} }
        }
        else if (type == 'language_localization_set') {
            return { displayType: LocalizationContainer, additionalInfo: {} }
        }
        else if ((typeof(schemaValue) != "undefined")&&("attribute" in schemaValue)&&(schemaValue["attribute"] =="has_spawn_info"))
        {
            var isRequired = (schemaValue["attribute_value"] == 'True')
            var additionalInfo = { requiresSpawnInfo: isRequired}
            return { displayType: HasSpawnInfoContainer, additionalInfo: additionalInfo }
        }
        else if (type == 'version_control') {
            return { displayType: SettingsContainer, additionalInfo: {} }
        }
        else if (type == "spawn_layer"){
            return { displayType: SpawnLayerComponent, additionalInfo: {} }

        }
        else
            return { displayType: TypeContainer, additionalInfo: {isSingleton:isSingleton} }
    }

    var containerInfo = GetContainerFromId(props.contentMode,props.currentSchema);
    var additionalInfo = containerInfo.additionalInfo;

    useEffect(() => {
        var token = GetCookie("token");

        if (token.length > 0) {
            //new FirebaseLogin().Login().then(() => {

                initializeAdminConsole(() => {
                    UpdateLoginToken(token);
                    props.ChangeMode("charm") 
                });
            //})
        }
    }, [])

    if (loginToken == "") {
        return (<LoginContainer callback={
            (token : string) => {
                UpdateLoginToken(token);
                props.ChangeMode("charm");
            }
        } />);
    }
    if ((initialized) && (props.contentMode.length>0)) { 
        return (
            <div style={{ paddingLeft: "3%", width: "94%"}}>
                <HeaderBar key={props.currentTab} context={props} />
                <containerInfo.displayType key={props.contentMode} context={props} schema={props.currentSchema} containerType={props.contentMode} additionalInfo={additionalInfo} />
            </div>
        );
    }
    else {
        return (<div>LOADING...</div>);
    }
}

//set reducers here, state passed from store
const mapStateToProps = (state) => {

    return {
        content: state.content.content.primaryContent,
        secondaryContent: state.content.content.secondaryContent,
        allContent: state.content.allContent,
        localization: state.content.localization,
        contentMode: state.content.contentMode,
        contentUpdateListeners:state.content.contentUpdateListeners,
        subSelection : state.content.subSelection,
        currentSchema : state.content.currentSchema,
        LinkingTable: state.linkingTable,
        changes: state.content.changes,
    };
};

//maps functions to properties
const mapDispatchToProps = (dispatch : Function) => {
    return {
        SetContent: (content : any) => {
            dispatch(SetContent(content));
        },
        AddContent: (content: any, depth) => {
            dispatch(AddContent(content, depth))
        },
        ChangeMode: (mode : any, subSelection : any = -1) => {
            dispatch(AsyncChangeMode(dispatch,mode,subSelection));
        },
        ChangeSubSelection:(index : any) =>{
            dispatch(ChangeSubSelection(index));
        },
        RemoveSelectedContent:() =>{
            dispatch(RemoveSelectedContent());
        },
        UpdateContent: (newContent : any, depth: Array<string>) => {
            dispatch(UpdateContent(newContent, depth));
        },
        UpdateSecondaryContent: (id: any, newContent: any, depth: Array<string>) => {
            dispatch(UpdateSecondaryContent(id, newContent, depth));
        },
        RemoveSecondaryContent: (id: any, content: any, depth: Array<string>) => {
            dispatch(RemoveSecondaryContent(id, content, depth));
        },
        SetSecondaryContent: (key, value) => {
            dispatch(SetSecondaryContent({key, value}));
        },
        WipeContent: () => {
            dispatch(WipeContent())
        },
        SetLocalization: (localization) => {
            dispatch(SetLocalization(localization));
        },
        UpdateLocalization: (key, value, language) => {
            dispatch(UpdateLocalization({key, value, language}));
        },
        DeleteLocalization: (key, language) => {
            dispatch(DeleteLocalization({key, language}));
        },
        AddContentListener:(contentListener)=>{
            dispatch(AddContentListener(contentListener));
        },
        RemoveContentListener:(contentListener)=>{
            dispatch(RemoveContentListener(contentListener));
        },


        //linking
        AddLink:(sourceId,link,context)=>{
            dispatch(AddLink(sourceId,link,context));
        },
        RemoveLink:(link,context)=>{
            dispatch(RemoveLink(link,context));
        },
        RemoveSource:(sourceId)=>{
            dispatch(RemoveSource(sourceId));
        },
        RemoveLinkContent:(linkContentId,context)=>{
            dispatch(RemoveLinkContent(linkContentId,context));
        },

        //changes management
        ClearChanges:()=> {
            dispatch(ClearChanges());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);