
import React, {useEffect} from "react";
import SchemaCache from './../../utilities/Cache Utilities/SchemaCache';

import { BuildComponentFromSchema } from "./../../utilities/website building utilities/SchemaBuilder";
import { BuildContent } from "./../../utilities/website building utilities/ContentBuilder";
import ObjectManipulator from './../../utilities/ObjectManipulator';
import PropTypes, { func } from 'prop-types';
import {PullContent} from '../../utilities/web interface utilities/WebApi'
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import ContentUpdater from "../../../../server/views/view_helpers/ContentUpdater";
import Context from './../../models/Context'
import { PublishChanges } from "../../utilities/web interface utilities/WebApi";
import IdProvider from '../../utilities/web interface utilities/Providers/UniqueIdProvider'

var ObjectID = require("bson-objectid");


export const SpawnLayerComponent = (props : any) => {
    // const classes = useStyles();
    
    const [layers, setLayer] = React.useState([{ value: {name:"",capacity:0, admin_console_display_name: "", id:{value:0,type:""}}, label: '' },]);
    const [selection, setSelection] = React.useState({ value: {name:"",capacity:0, admin_console_display_name: "", id:{value:0,type:""}}, label: '' });

    useEffect(()=>{
        async function GetSpawnLayers(){
            var result = await PullContent("spawnlayers")
            if (result != null)
            {
                var values = result.content_value;
                let options = values.map(v => ({value: v , label: v.name}))
                setLayer(options)
                return options;
            }
            else
            {
                console.log("no content result");
                return null;
            }
            return result;
        }
        GetSpawnLayers()
    },[])
    
    return (
        <div>
            {<Select
                style={{margin: "400px"}}
                className="form-control-main"
                onChange={event => OnChangeSelected(event)}
                options={layers}
                value={selection}
                
                // value={}
            />}
            <div style={{marginTop: '2rem'}}>
            <div style={{marginBottom: "10px"}}>

            <label>Name:</label>
            <TextField   id="standard-basic" variant="standard"  value={selection.value.name} onChange={e=>updateName(e)} ></TextField>
                <span></span>
            </div >
            <label>Capacity: </label>
            <TextField  id="standard-basic" type="number" variant="standard" value={selection.value.capacity}  onChange={e=>updateCapacity(e)}  ></TextField>
            <span></span><span></span>
            </div>                        
            
            <div>
            <button style={{marginTop: "3rem"}}  className="btn btn-success" onClick={AddLayer}>Add Layer</button>

            </div>
            <span></span><span></span>
            <button style={{padding: "10px"}} className="btn btn-warning" onClick={DeleteLayer}>Delete Current Layer</button>
            <span></span><span></span>

            <button style={{margin: "10px"}} className="btn btn-success" onClick={PublishLayer} >Publish Layers</button>


            
        </div>
        )
        function updateName(event:any){
            let copy = Object.assign({}, selection);
            copy.value.name = event.target.value;  
            console.log(event.target.value)
            console.log(copy)
            setSelection(copy)
        }

        function updateCapacity(event:any){
            let copy = Object.assign({}, selection);
            copy.value.capacity = event.target.value;  
            setSelection(copy)
        }
            
        function OnChangeSelected(event: any) {
            console.log(event)
            setSelection(event)
        }
        async function AddLayer(){
            let def = { value: {name:selection.value.name,capacity:selection.value.capacity, admin_console_display_name: selection.value.name, id:{value:0,type:""}}, label: selection.value.name }

            if(layers.map(l=>layers.values.name).includes(selection.value.name)){
                alert('name for layer exists!')
                return
            }
            if(selection.value.capacity <= 0){
                alert('capacity must be greater than zero')
                return
            }

            var idNumber = await new IdProvider().GetUniqueId("", layers.map(l=>l.value))
            let newId = {value: parseInt(idNumber), type: 'spawn_layers'}
            def.value.id = newId;

            setLayer(layers => [...layers, def])
            setSelection({ value: {name:"",capacity:0, admin_console_display_name: "", id:{value:0,type:""}}, label: '' })
        }
        function DeleteLayer(){
            let newLayer = layers.filter(function(layer) { 
                return selection.label != layer.label 
            })
            setLayer(newLayer)
            setSelection(newLayer[0])
        }
        function PublishLayer(){
            let res = layers.map(l => l.value)
            let changes = {'spawnlayers':res}
            var timeStamp = new Date().toISOString();
            PublishChanges({'added':changes},timeStamp).then(result => {
                if (result.length == 0) {
                    alert("There was an error publishing ");
                    return;
                }
                else {
                    alert("Changes Successfully Published!");
                }
            });
        }

}
    
export default SpawnLayerComponent

