import React from 'react';
var snake = require('to-snake-case');

import { StringFieldComponent } from "./../../components/base types/StringFieldComponent";
import { ColorFieldComponent } from "./../../components/base types/ColorFieldComponent";
import { IntegerFieldComponent } from "./../../components/base types/IntegerFieldComponent";
import { DecimalFieldComponent } from "./../../components/base types/DecimalFieldComponent";
import { BoolFieldComponent } from "./../../components/base types/BoolFieldComponent";
import { EnumFieldComponent } from "./../../components/base types/EnumFieldComponent";
import { IdSelectorComponent } from "./../../components/base types/IdSelectorComponent";
import { ArrayFieldComponent } from "./../../components/base types/ArrayFieldComponent";
import { ExpandableArrayFieldComponent } from "./../../components/base types/ExpandableArrayFieldComponent";
import { SelectableDictionaryFieldComponent } from "./../../components/base types/SelectableDictionaryFieldComponent";
import { InventoryIdFieldComponent } from './../../components/base types/InventoryIdFieldComponent';
import { ContentSelectionFieldComponent } from '../../components/base types/ContentSelectionFieldComponent';
import { DateTimeFieldComponent } from './../../components/base types/DateTimeFieldComponent';
import { DateFieldComponent } from './../../components/base types/DateFieldComponent';

import { TimeFieldComponent } from './../../components/base types/TimeFieldComponent';
import { LocIdFieldComponent } from './../../components/base types/LocIdFieldComponent';
//import { ZeroSumFieldComponent } from './../../components/base types/ZeroSumFieldComponent';
import { SpecifiedTypeComponent } from './../../components/base types/SpecifiedTypeComponent';
import { DictionaryFieldComponent } from './../../components/base types/DictionaryFieldComponent';

//statics
import { OptionSelectorFieldComponent } from './../../components/static components/OptionSelectorFieldComponent';

import { AssetIdSelectorComponent } from "./../../components/base types/AssetIdSelectorComponent";
import { IdArrayComponent } from "./../../components/IdArrayComponent";
import { RuleFieldComponent } from "./../../components/RuleFieldComponent";
import { FrontEndDefinitionComponent } from './../../components/base types/FrontEndDefinitionComponent';

import { IsJsonArray, IsTypeArray, GetGenericType, GetNestedId } from "./../Utilities";
import ComponentConstuctor from "./ComponentConstructor";
import { ExtraSchemaComponent } from "./../../components/ExtraSchemaComponent";
import { Container, Row, Col, Input, Progress } from "reactstrap";
import { BuildContent } from "./ContentBuilder";
import TypeSupporter from "./TypeSupporter";
import { debug } from 'firebase-functions/lib/logger';
import { SchemaModel } from '../../../../server/models/SchemaModel';
import { Visibility } from '@material-ui/icons';

var GetLanguages = require('./../../../../global/GlobalStatics').GetLanguages;

//object attributes do not apply to properties
export function GetJSXComponentFromType(type : string, key : string = null, contentValue = null, schemaValue = null, oldDepth : Array<string> = null, objectAttribute = "", parentContentValue = {}) {

    //=================================
    //ATTRIBUTE TYPES
    //=================================
    /*
    if (type.includes('HiddenWithConditionAttribute')) 
    {
        var conditions = JSON.parse(schemaValue.HiddenWithConditionAttribute);
        var conditionKey = conditions["condition_property_name"];
        var conditionValue = conditions["reveal_value"]

        if (parentContentValue[conditionKey] == conditionValue)
        {
            type = type.replace("HiddenWithConditionAttribute.","");
        }
        else
            return {"skip":true}
    }*/

    //console.log("injured ",type , " , ",key , " , ",schemaValue , " , ",contentValue);


    if (type.includes('HiddenAttribute')) {
        return { "skip": true }
    }

    else if ((type.includes('SerializationRequiresSpecifiedTypeAttribute')) && (IsTypeArray(type))) {

        var allowDuplicates = false;
        if ("allow_duplicates" in schemaValue)
            allowDuplicates = schemaValue["allow_duplicates"]

        return { "component": RuleFieldComponent, "allowDuplicates": allowDuplicates, "header": "true", "schemaType": GetNestedId(type, true, true) };
    }

    else if (type.includes('SerializationRequiresSpecifiedTypeAttribute')) {

        return { "component": SpecifiedTypeComponent, "schemaType": GetNestedId(type, false, true) }

        /*
        var allowDuplicates = false;
        if ("allow_duplicates" in schemaValue)
            allowDuplicates = schemaValue["allow_duplicates"]

        return {"component":RuleFieldComponent,"allowDuplicates":allowDuplicates,"header":"true","schemaType":GetNestedId(type,true,true)};
        */
    }

    else if (type.includes('AdminConsoleDictionaryAttribute')) {
        var options = JSON.parse(schemaValue.AdminConsoleDictionaryAttribute);
        return { "component": SelectableDictionaryFieldComponent, "header": true, "schemaType": GetNestedId(type, false, true), "options": options };
        //return {"component":ExtraSchemaComponent,"schemaType": GetNestedId(type,false,true)};
    }

    else if (type.includes('ContentSelectionAttribute')) {

        var contentTypes = JSON.parse(schemaValue.ContentSelectionAttribute);
        var schemaTypes = [];
        for (var index in contentTypes) {
            schemaTypes.push(GetNestedId(contentTypes[index]))
        }

        return { "component": ContentSelectionFieldComponent, "schemaType": schemaTypes };
    }

    else if (type.includes('AbsoluteLengthArrayAttribute'))
    {
        let contentType = JSON.parse(schemaValue.AbsoluteLengthArrayAttribute);
        let length = schemaValue.AbsoluteLength;
        let idType : string = GetNestedId(contentType, false, true);
        let genericType:string = GetGenericType(contentType);

        //console.log("what is given? ",contentType," , ",length , " , ",idType, " , ",genericType);

        //return { "component": FrontEndDefinitionComponent, "schemaType": "initial_mannequin_slot_1", "genericType": genericType }

        return { "component": ArrayFieldComponent, "header": true, "arrayType": ExtraSchemaComponent, "schemaType": contentType };
        
        /*
           var idType : string = GetNestedId(type, true, true);

        if (contentValue.type != null)
            idType = contentValue.type;

        //case of an enum
        if (("items" in schemaValue) && ("enum" in schemaValue.items)) {
            return { "component": ExpandableArrayFieldComponent, "header": true, "arrayType": EnumFieldComponent, "options": schemaValue.items.options, "defaultValue": 0 };
        }
        else {
            return { "component": ExpandableArrayFieldComponent, "header": true, "arrayType": ExtraSchemaComponent, "schemaType": idType };
        */

        return {"skip":true}
    }

    else if (type.includes('ContentListSelectionAttribute')) {
        var contentTypes = JSON.parse(schemaValue.ContentListSelectionAttribute);
        var schemaTypes = [];
        for (var index in contentTypes) {
            schemaTypes.push(GetNestedId(contentTypes[index]))
        }

        return { "component": ExpandableArrayFieldComponent, "header": true, "arrayType": ContentSelectionFieldComponent, "schemaType":schemaTypes , "defaultValue": "" };
    }


    /*else if (objectAttribute == "ZeroSumAttribute") {
        return { "component": ZeroSumFieldComponent, "options": schemaValue.attribute_value };
    }*/

    else if (objectAttribute == "HasClientDefinitionAttribute") {
        return { "component": FrontEndDefinitionComponent, "schemaType": JSON.parse(schemaValue.attribute_value), "genericType": GetGenericType(type) }
    }

    else if (type.includes("HasClientDefinitionPropertyAttribute")) {

        let schemaTypes  = schemaValue["HasClientDefinitionPropertyAttribute"]
        return { "component": FrontEndDefinitionComponent, "schemaType": JSON.parse(schemaTypes), "genericType": GetGenericType(type) }
    }

    else if (objectAttribute == "allow_modifiers") {
        console.log("woah there ", schemaValue);
    }

    //=================================
    //BASE TYPES
    //=================================

    /*
    else if (type.includes("dictionary")) {
        return { "component": DictionaryFieldComponent, schemaType: type }
    }*/

    else if (type == "integer") {
        return { "component": IntegerFieldComponent };
    }
    else if ((type == "number") || (type == "System.Single")) {
        return { "component": DecimalFieldComponent };
    }
    else if (type == "System.Int32[]") {
        return { "component": ExpandableArrayFieldComponent, "header": true, "arrayType": IntegerFieldComponent, "defaultValue": 0 };
    }
    else if (type == "System.Single[]") {
        return { "component": ExpandableArrayFieldComponent, "header": true, "arrayType": DecimalFieldComponent, "defaultValue": 0 };
    }
    else if (type == "boolean") {
        return { "component": BoolFieldComponent };
    }
    else if (type == "enum") {

        return { "component": EnumFieldComponent, "options": schemaValue.options };
    }
    else if (type == "UnityEngine.Color") {
        return { "component": ColorFieldComponent, "header": true };
    }
    else if (type == "UnityEngine.Vector3") {
        return { "component": ArrayFieldComponent, arrayType: DecimalFieldComponent };
    }
    else if ((IsJsonArray(type)) && (type[0] == "string")) {
        return { "component": StringFieldComponent };
    }
    else if (type == "System.String[]") {
        return { "component": ExpandableArrayFieldComponent, "header": true, "arrayType": StringFieldComponent, "defaultValue": "" };
    }
    else if (type == "UnityEngine.DateTime") {
        return { "component": DateTimeFieldComponent };
    }
    else if (type == "UnityEngine.Date") {
        return { "component": DateFieldComponent };
    }
    else if (type == "UnityEngine.Time") {
        return { "component": TimeFieldComponent };
    }
    else if (type == "Admin.MultiSelect") {
        if(schemaValue.items.options){
            console.log('exist');
        }
        var options = schemaValue.items.options.map((input) => {
            return input.label;
        })

        return { "component": OptionSelectorFieldComponent, "header": true, "options": options, "multiSelect": true };
    }

    else if (type.includes("LocId[]")) {

        var idType : string = GetNestedId(type, true, false);
        return { "component": ExpandableArrayFieldComponent, "header": true, "options": GetLanguages(), "schemaType": idType, "arrayType": LocIdFieldComponent };
    }

    else if (type.includes("LocId")) {
        return { "component": LocIdFieldComponent, "options": GetLanguages() , "show": false};
    }
    //=================================
    //IDENTIFIER TYPES
    //=================================

    //TODO make arrays more generic
    else if ((type.includes('Core.Models.AssetId')) && (IsTypeArray(type))) {
        var idType : string = type.slice(0, type.length - 2);//removes [] from type
        var genericType = GetGenericType(type);

        if (genericType.includes("Sprite")) {
            genericType = "Sprites";
        }
        else if (genericType.includes("AudioClip")) {
            genericType = "Sounds";
        }
        else if (genericType.includes("Material")) {
            genericType = "Materials";
        }

        return { "component": ExpandableArrayFieldComponent, "header": true, "arrayType": AssetIdSelectorComponent, "schemaType": idType, "genericType": genericType };
    }
    //TODO make special case for this!!!
    else if (type.includes('Core.Models.AssetId')) {

        var AssetIdDepth = oldDepth.slice();
        AssetIdDepth.push(key);
        //AssetIdDepth.push("resource_name");

        var folderType = contentValue.folder_name;

        //hack fix for now
        //if ((contentValue.folder_name == "Prefabs")&&(contentValue.true_type == "")) {
        if ((contentValue.folder_name == "Prefabs") && (!(GetNestedId(type).includes("_")))) {

            contentValue.true_type = GetNestedId(type);

            folderType = contentValue.true_type;
        }
        if (contentValue.folder_name == 'Prefabs' && contentValue.true_type == 'Texture2D'){
            folderType = contentValue.true_type;
            contentValue.folder_name = 'Texture2D';
        }
        

        return { "component": AssetIdSelectorComponent, "value": contentValue, "genericType": folderType, "depth": AssetIdDepth };
    }

    else if ((type.includes('Core.Models.Id')) && (IsTypeArray(type))) {
        var idType : string = GetNestedId(type, false, false);

        if (idType == "InventoryItem") {
            var defaultValue = { "value": 0, type: "charm" };

            return { "component": ExpandableArrayFieldComponent, "header": true, "arrayType": InventoryIdFieldComponent, "defaultValue": defaultValue };
        }
        else {
            var allowDuplicates = false;
            if ("allow_duplicates" in schemaValue)
                allowDuplicates = schemaValue["allow_duplicates"]

            return { "component": IdArrayComponent, "allowDuplicates": allowDuplicates, "header": true, "schemaType": idType };
        }
    }

    else if (type.includes('Core.Models.Id')) {
        var idType : string = GetNestedId(type, false, false);

        if (idType == "InventoryItem") {
            var defaultValue = { "value": 0, type: "charm" };
            return { "component": InventoryIdFieldComponent, "defaultValue": defaultValue };
        }
        else {
            return { "component": IdSelectorComponent, "schemaType": idType };
        }
    }

    //=================================
    //UNIMPLEMENTED TYPES
    //=================================
    else if (type == "Core.Models.Inventory.ItemInInventory") {
        alert("THIS HAS NOT BEEN IMPLEMENTED PLEASE CONTACT JON! (item in inventory)");
    }

    //=================================
    //SPECIAL TYPES
    //=================================
    else if ((IsTypeArray(type)) && ((type.includes('Core')) || (IsTypeArray(type)) && ((type.includes('Metatron'))) || (type.includes("AdminConsoleTools")))) {
        var idType : string = GetNestedId(type, true, true);

        if (contentValue.type != null)
            idType = contentValue.type;

        //case of an enum
        if (("items" in schemaValue) && ("enum" in schemaValue.items)) {
            return { "component": ExpandableArrayFieldComponent, "header": true, "arrayType": EnumFieldComponent, "options": schemaValue.items.options, "defaultValue": 0 };
        }
        else {
            return { "component": ExpandableArrayFieldComponent, "header": true, "arrayType": ExtraSchemaComponent, "schemaType": idType };
        }
    }
    else if ((type.includes('Core')) || (type.includes("AdminConsoleTools"))) {
        return { "component": ExtraSchemaComponent, "schemaType": GetNestedId(type, false, true) };
    }

    return null;
}



export function BuildComponentFromSchema(schema, jsonString, context, oldDepth, keysToIgnore) {

    var UiJsx = [];

    //case base type needs to be drawn
    if (new TypeSupporter().IsTypeSupported(schema))
    {
        var type = schema;
        var value = jsonString;
        if (!type.includes("LocId")){
            var componentData = GetJSXComponentFromType(type);
    
            var constructor = new ComponentConstuctor();
            UiJsx.push(constructor.ConstructComponent(componentData, "", value, context, oldDepth, keysToIgnore, [], 0));
        }
    }
    else
    {
            

        InitializeEmptyContent(jsonString, schema, context, oldDepth, keysToIgnore)
    
        //orders attributes alphabetically
        var keys = Object.keys(jsonString);
        keys.sort();

        for (let index = 0; index < keys.length; index++) {
            var key = keys[index]
            // if (keysToIgnore.includes(index) || (typeof schema.properties[index] === 'object' && schema.properties[index].type.includes('LocId')))

            // if (keysToIgnore.includes(key)|| (typeof jsonString[key] === 'object' && 'code' in jsonString[key])) {
            if (keysToIgnore.includes(key)) {

                continue;
            }
    
            var schemaValue;
            var contentValue;
            var depth = oldDepth.slice();
            depth.push(key);
    
            try {
                var contentValue = jsonString[key];
                var schemaValue = schema.properties[key];

            }
            catch (exception) {
                //console.log("exception: ",exception, " , ",key);
                continue;
            }
    
            var constructor = new ComponentConstuctor();
    
            if (schemaValue != null) {
    
                var type = schemaValue.type;
    
                //case type is declared in the content
                if ((IsJsonArray(type)) && (type[0] == "object") && (type[1] == "null") && (contentValue.type != null)) {
                    type = contentValue.type;
                }
    
                if (schemaValue.enum != null) {
                    type = "enum";
                }
    
                var attribute = "";
                if ("attribute" in schemaValue) {
                    attribute = schemaValue.attribute;
                }
    
                var componentData = GetJSXComponentFromType(type, key, contentValue, schemaValue, oldDepth, attribute, jsonString);
    
                if ((componentData != null) && ("skip" in componentData)) {
    
                }
                else if (componentData == null) {
    
                    console.log("somehow got here building a title? ", key);
    
                    UiJsx.push(constructor.BuildTitle(key, depth));
                    UiJsx.push(BuildComponentFromSchema(schemaValue, contentValue, context, depth, keysToIgnore));
                }
                else {
                    UiJsx.push(constructor.ConstructComponent(componentData, key, contentValue, context, depth, keysToIgnore, schemaValue, index));
                }
            }
        }
    }

    return UiJsx;

}


//in some cases content may not be set for all the schema properties. this function ensures entire 
//schema made avaliable for content setting in the admin console UI.
function InitializeEmptyContent(content, schema, context, depth, keysToIgnore) {

    //console.log("failing on depth? ",depth, " , ",schema);
    // if (typeof loc === 'object' && 'code' in this.props.context.content[this.props.context.subSelection][field]){

    for (var index in schema.properties) {
        if (keysToIgnore.includes(index) || (typeof schema.properties[index] === 'object' && schema.properties[index].type.includes('LocId')))
            continue;

        var property = schema.properties[index];

        // if (!(index in content)) {

        //     var defaultValue = (BuildContent(property, property.type, context, depth));

        //     content[index] = defaultValue;
        // } 
    }

}





