import { BuildContent } from './../../utilities/website building utilities/ContentBuilder';
import SchemaCache from './../../utilities/Cache Utilities/SchemaCache';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { GetNestedId } from './../../utilities/Utilities';
import Context from './../../models/Context'
import React, { useState, useEffect } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface Props
{
	label: string,
	depth: Array<string>,
    value: Array<any>,
	arrayType: any,
    schemaType: string,
    genericType: string,
    defaultValue: string,
    context: Context,
    keysToIgnore: Array<string>,
}

export const ExpandableArrayFieldComponent :React.FC<Props> = (props) => {

    var [value, UpdateValue] = useState(props.value);

    //console.log("prop ",props.label, " , ",props.value)

    useEffect(() => {
    }, [props.value]);

    var generateKey = () => {
        var key = "";
        for(var item of props.depth) {
            key += item;
        }

        for(var rack of props.value) {
            key += rack.rack_type;
        }

        return key;
    }

    const [toggle, updateState] = React.useState(true);

    return  (<div>
        <View key={generateKey()} props={props} context={props.context} value={value} UpdateValue={(v)=>UpdateValue(v)} ForceUpdate={() => {
            updateState(!toggle);
        }} />
    </div>)
}

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));

const View = (props) => {

    const classes = useStyles();

    var RegenerateArray = () => {

        var UiJsx : Array<JSX.Element> = []
        var values : Array<string> = [];

        for (const index in props.value) {
            var currentValue = props.value[index];

            if (values.includes(JSON.stringify(currentValue))) {
            }
            else {
                values.push(JSON.stringify(currentValue));
            }
            var depth = props.props.depth.slice();
            depth.push(index);

            var color = "#F5E7F9";
            if (parseInt(index) % 2 != 0)
                color = "#FAF2E8";

            if (typeof (props.props.schemaType) != 'undefined') {

                var schemaType = GetNestedId(props.props.schemaType, false, true);

                UiJsx.push(<Grid container direction="row" style={{ backgroundColor: color }} alignItems="center" spacing={2} key={index}>
                    <Grid item xs={10}>
                        <props.props.arrayType value={currentValue} label={index} depth={depth} keysToIgnore={props.props.keysToIgnore} schemaType={schemaType} context={props.context} options={props.props.options} genericType={props.props.genericType} />
                    </Grid>

                    <Grid item xs={1}>
                        <Button color="danger" value={index} onClick={(event) => OnDestroyElement(event)} >Remove</Button>
                    </Grid>
                </Grid>)


            }
            else {
                UiJsx.push(<Grid container direction="row" style={{ backgroundColor: color }} alignItems="center" spacing={2} key={index}>
                    <Grid item xs={10}>
                        <props.props.arrayType value={currentValue} label={index} depth={depth} keysToIgnore={props.props.keysToIgnore} context={props.context} options={props.props.options} genericType={props.props.genericType} />
                    </Grid>

                    <Grid item xs={1}>
                        <Button color="danger" value={index} onClick={(event) => OnDestroyElement(event)} >Remove</Button>
                    </Grid>
                </Grid>)

            }
        }
        return UiJsx;
    }

    var OnCreateElement = () => {

        if ((typeof (props.props.defaultValue) != 'undefined')) {

            let newValue = [...props.value];
            newValue.push(props.props.defaultValue);
            props.context.UpdateContent(newValue, props.props.depth);
            props.UpdateValue(newValue);
            //props.ForceUpdate();
        }
        else {

            var schemaType = GetNestedId(props.props.schemaType, false, true);

            //hack fix for now
            if (props.props.schemaType.includes("Core.Models.AssetId"))
                schemaType = "asset_id_1";

            new SchemaCache().GetCacheValue(schemaType).then(result => {

                var schema = "";

                //case we pull a single schema
                if (result.properties != null) {
                    schema = result;
                }
                //case we pull an array of schema
                else {
                    schema = JSON.parse(result[schemaType]);
                }

                //(props.props.schemaType
                //var additionalContent = CreateContentFromType(props.props.schemaType, props.context, props.props.depth);
                var additionalContent =  BuildContent(schema, props.props.schemaType ,props.context, props.props.depth)
                
                let newValue = [...props.value];
                newValue.push(additionalContent);
                props.context.UpdateContent(newValue, props.props.depth);
                props.UpdateValue(newValue);

                //value.push(additionalContent);
                //props.context.UpdateContent(value, props.props.depth);
                //props.ForceUpdate();

            }).catch(err => console.log(err));
        }
    }

    var OnDestroyElement = (event) => {

        var index = event.target.value;
        let newValue = [...props.value];
        newValue.splice(index, 1);

        props.context.UpdateContent(newValue, props.props.depth);
        props.UpdateValue(newValue);
        //props.ForceUpdate();
    }

    var onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        var src = result.source.index;
        var dest = result.destination.index;

        let newValue = [...props.value]

        var oldValue = [];
        for(var element of newValue) {
            oldValue.push(element);
        }

        var keys = Object.keys(newValue);

        var arrayWithoutElement = [];
        for(var index in keys) {
            if(index != src) {
                arrayWithoutElement.push(keys[index]);
            }
        }
        
        var pushed = false;
        var arrayWithElement = [];
        for(var index in arrayWithoutElement) {
            if(index == dest) {
                arrayWithElement.push(keys[src])
                pushed = true;
            }

            arrayWithElement.push(arrayWithoutElement[index])
        }

        if(!pushed) {
            arrayWithElement.push(keys[src])
        }
        
        newValue.splice(0, newValue.length); // clear array
        for(var key of arrayWithElement) {
            newValue.push(oldValue[key])
        }

        props.context.UpdateContent(newValue, props.props.depth);
        props.UpdateValue(newValue);
       // props.ForceUpdate();
    }

    var UiJsx = RegenerateArray();

    var draggableContent = ( 
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-1" type="PERSON">
                {(provided, snapshot) => (
                    <div
                    ref={provided.innerRef}
                    style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                    {...provided.droppableProps}
                    >
                    {UiJsx.map((item, index) => (
                        <Draggable key={`${index}`} draggableId={`${index}`} index={index}>
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            >
                            {item}
                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>);
    
    return (
        <div className={classes.root} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <ExpansionPanel >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className={classes.heading}>{props.props.label}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails >
                    <div style={{ width: "100%" }}>
                        {draggableContent}
                        <button className="btn btn-success" onClick={()=>OnCreateElement()} >Add From {props.props.label}</button>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}