

const languages = () => { return [{label:"English",value:"language_set_english_title"},{label:"French",value:"language_set_french_title"}] }
const standAloneTypes = () => { 

  let standAloneTypes = [];

  let headers = GetHeaderInfo();

  for(let index in headers)
  {
    let subcategories = headers[index].subcategories;

    for (let subIndex in subcategories)
    {
      let subcategory = subcategories[subIndex];

      if ("standalone" in subcategory)
        standAloneTypes.push(subcategory.value);
    }

  }

  return standAloneTypes;
 }
//note stand alone content is content that will be seen by the spawning, or sigil backend server but not by the client
//
//header categories
//{label:"Interface Test",value:"interface_test",standalone:true},
var inventoryItems = [
  {label:"Charm", value:"charm"},
  {label:"Consumable Hex Items", value:"consumable_hex_item"},
  {label:"Equipment", value:"equipment"},
  {label:"Innate Hex Items", value:"innate_hex_item"},
  {label:"Ingredient", value:"ingredient"},
  {label:"Crafting Recipes", value:"crafting_recipe"},
  {label:"Costume Items", value:"costume_item"},
  {label:"Buff Items", value:"buff_item"}];
var inventoryItemHelpers = [
  {label:"Item Frame", value:"item_frame"},
  {label:"Item Material Archetype", value:"item_material_archetype", standalone: true},
  {label:"Item Discovery", value:"item_discovery", standalone: true},
];
var contentMenus = [
  {label:"Pawn Def", value:"pawn_definition"},
  {label:"Delivery Def", value:"delivery_definition"},
  {label:"Crafting Table Def", value:"crafting_table"},
  {label:"Quests", value:"quest_model", standalone:true},
  {label:"Login Rewards", value:"daily_objective_log_model", standalone:true},
  {label:"Mana Crystal Def", value:"mana_crystal"},
  {label:"Env Prop Spawn", value:"env_prop_spawn"},
  {label:"Npc Def", value:"npc_definition"},
  {label:"Pawn Audio Definition", value:"pawn_audio_definition"},
  {label:"Level Definition", value:"level_definition"},

];
var playableMenus = [
  {label:"Encounter Playables", value:"encounter_playable_model", standalone:true},
  {label:"Harvestable Playables", value:"harvest_playable_model", standalone:true},
  {label:"Conversation Playables", value:"conversation_playable_model", standalone:true},
  {label:"Crafting Table Playables", value:"crafting_table_playable_model", standalone:true},
  {label:"Traveling Merchant Playables", value:"traveling_merchant_playable_model", standalone:true},
  {label:"Npc Playables", value:"npc_playable_model", standalone:true}
];
var playableHelpers = [
  {label:"Baddy Modifiers", value:"baddy_modifier_schema", standalone:true},
  {label:"Loot Tables", value:"loot_table_schema", standalone:true},
  {label:"Outcome Blueprints", value:"outcome_blueprint_bundle_schema", standalone:true}
];
var ruleMenus = [
  {label:"Hex Rule Model", value:"hex_rule_model"},
  {label:"Pawn Rule Bundle", value:"pawn_rule_bundle"}
];
var storeMenus = [
  {label:"Main Store Deployment", value:"store_deployment", standalone:true},
  {label:"Store Bundles", value:"store_bundle", standalone:true},
  {label:"Spin Wheel", value:"spin_wheel_schema", standalone:true}
];
var contentManifestMenus = [
  {label:"Content Keyword Manifest", value:"content_keyword_manifest"},
  {label:"Content Item Manifest", value:"content_item_manifest"},
  {label:"App Loc Manifest", value:"app_loc_manifest"},
  {label:"Game Resource Manifest","value":"game_resource_manifest"},
  {label:"Palette Manifest", value:"palette_manifest"},
  {label:"Game Constants Manifest", value:"game_constants_manifest"},
  {label:"Tutorial Manifest", value:"tutorial_manifest"}
];
//var questMenus = [{label:"Character Records",value:"character_record_admin_view"},{label:"Character Records Manifest",value:"record_content_manifest",standalone:true},{label:"Quests",value:"quest_admin_view",standalone:true}];
var uiMenus = [
  {label:"Ui Category Models", value:"ui_category_model"},
  {label:"Simple Tooltip", value:"simple_tooltip_model"},
  {label:"Float Tooltip", value:"float_tooltip_model"},
  {label:"Range Tooltip", value:"range_tooltip_model"},
  {label:"Parent One Child Tooltip", value:"parent_one_child_tooltip_model"},
  {label:"Tutorial", value:"tutorial_model"},
  {label:"Tutorial Tooltip Model", value:"tutorial_tooltip_model"},

]
var localizationMenus = [
    {label:"Localizations",value:"language_localization_set"}
];
var generalMenus = [
  {label:"Initial Character", value:"initial_character_model"},
  {label:"Leveling", value:"level_table"},
  {label:"Content Keywords", value:"content_keyword"},
  {label:"Currencies", value:"currency_model"},
  {label:"Holidays", value:"holiday_schema", standalone: true},
  {label:"Mail", value:"mail_schema", standalone: true, isEventTrigger: true},
];
var settingsMenus = [
    {label:"Version Control",value:"version_control",hasSchema:false}
];
var ascensionMenus = [
  {label: "Ascension Vertex Def", value: "ascension_vertex_definition", standalone: true},
  {label: "Ascension Cluster Def", value: "ascension_cluster_definition", standalone: true},
  {label: "Ascension Biome Def", value: "ascension_biome_definition", standalone: true},
];
var augurMenus = [
  {label: "Augur Contact", value: "augur_contact", standalone: true},
  {label: "Augur Message", value: "augur_message", standalone: true},
  {label: "Augur Newsfeed Article", value: "augur_newsfeed_article", standalone: true},
];

var testMenus = [
  {label:"PropertyAttributeClass",value:"property_attribute_class"},
  {label:"ExtentionSchemaAttributeClass",value:"extention_schema_attribute_class"},
  {label:"HasClientDefinitionAttributeClass",value:"has_client_definition_attribute_class"},
  {label:"HasSpawnInformationAttributeClass",value:"has_spawn_information_attribute_class"},
  {label:"RenameObjectAttributeClass",value:"rename_object_attribute_class"},
  {label:"SingletonAttributeClass",value:"singleton_attribute_class"}
];

let promoMenu = [
  {label:'Promotions',value:'promo_model'}
]

//secondary content
var secondayContent = [
    {label:"Spawn Info", value:"spawn_infos", standalone:true, isSeconday:true}
];

var SpawnLayer = [
  {label:"SpawnLayer", value:"spawn_layer"}
]

function GetHeaderInfo() {return [
  {title:"Inventory Items", subcategories:inventoryItems},
  {title:"Inventory Item Helpers", subcategories:inventoryItemHelpers},
  {title:"Content", subcategories:contentMenus},
  {title:"Playables", subcategories:playableMenus},
  {title:"Playable Helpers", subcategories:playableHelpers},
  {title:"Rules", subcategories:ruleMenus},
  {title:"Store", subcategories:storeMenus},
  {title:"Ascension", subcategories:ascensionMenus},
  {title:"Augur", subcategories:augurMenus},
  {title:"Content Manifests", subcategories:contentManifestMenus},
  {title:"UI", subcategories:uiMenus},
//{title:"Records",subcategories:questMenus},
  {title:"Localizations", subcategories:localizationMenus},
  {title:"General", subcategories:generalMenus},
  {title:"Admin Console Settings", subcategories:settingsMenus},
  {title:"Promotions", subcategories:promoMenu},
  {title:"Test Tab", subcategories:testMenus},
  {title:"Spawn Layers",subcategories:SpawnLayer}
];}


function IsStandAlone(type)
{
  if (standAloneTypes().includes(type))
    return true;

  return false;
}

function GetInfo(type)
{
  var allHeaders = GetHeaderInfo();

  for(var index in allHeaders)
  {
    var subcategories = allHeaders[index].subcategories;

    for(var index2 in subcategories)
    {
      var cat = subcategories[index2];
      if (cat.value == type)
        return cat;
    }

  }

  //case type does not exist
  return {};
}


function GetHeaderTabFromType(type)
{
  var headers = GetHeaderInfo();

  for(var index in headers)
  {
    var header = headers[index];

    for(var subcategoryIndex in header.subcategories)
    {
      var subcategory = header.subcategories[subcategoryIndex];

      if (subcategory.value == type)
      {
        return {"tabIndex":parseInt(index),"categoryIndex":parseInt(subcategoryIndex)};
      }
    }
  }

  return {"tabIndex":0,"categoryIndex":0};
}

function GetAllInfo()
{
  var allInfo = GetHeaderInfo();
  allInfo.push({title:"Spawn Info",subcategories:secondayContent})
  return allInfo;
} 

function IsSecondayContent(type)
{
  var secondaryContent = secondayContent.map((input)=>{return input.value});

  if (secondaryContent.includes(type))
  {
    return true;
  }
   
  return false;
}



function GetDataTypes()
{
  var headerInfo = GetAllInfo();

  var DataTypes = [];

  for(var index in headerInfo)
  {
    var info = headerInfo[index].subcategories;

    for (var index2 in info)
    {
        var data = info[index2];

        if (("hasSchema" in data)&&(!data.hasSchema))
          continue;

        DataTypes.push(data);

          /*
        if ((isStandAlone)&&("standalone" in data))
          contentNames.push(data.value);//.replace(/_/gi, ''));
        else if (!isStandAlone)
        {
          contentNames.push(data.value);//.replace(/_/gi, ''));
        }
        */
    }
  }

  return DataTypes;
}


//add stand alone check


module.exports = {
    GetHeaderInfo: GetHeaderInfo,
    GetAllInfo: GetAllInfo,
    GetLanguages: languages,
    IsSecondayContent:IsSecondayContent,
    GetDataTypes:GetDataTypes,
    GetHeaderTabFromType:GetHeaderTabFromType,
    GetInfo:GetInfo,
    IsStandAlone:IsStandAlone
  };
