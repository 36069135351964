import {render} from "react-dom";
import {Provider} from "react-redux";
import React from "react";
import App from "./containers/App"
import store from "./store"
import 'bootstrap/dist/css/bootstrap.min.css';

// force rebuild
import {Router, Route} from "react-router";

if (module.hot)
{
    module.hot.accept();
} 

render(
<Provider store ={store}>
    <App />
</Provider>, 
window.document.getElementById('app'));


