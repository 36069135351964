

import ObjectManipulator from './../utilities/ObjectManipulator';
import { ADD_CONTENT_LISTENER, CLEAR_CHANGES, CONTENT_ADD_CONTENT, CONTENT_CHANGE_MODE, CONTENT_CHANGE_SUBSELECTION, CONTENT_DELETE_LOCALIZATION, CONTENT_REMOVE_SECONDARY_CONTENT, CONTENT_REMOVE_SELECTED_CONTENT, CONTENT_SET_CONTENT, CONTENT_SET_LOCALIZATION, CONTENT_SET_SECONDARY_CONTENT, CONTENT_UPDATE_CONTENT, CONTENT_UPDATE_LOCALIZATION, CONTENT_UPDATE_SECONDARY_CONTENT, CONTENT_WIPE_CONTENT, REMOVE_CONTENT_LISTENER } from './../actions/ContentActions'
import {} from './../actions/AsyncContentActions'

function UpdateContent(content, depth : Array<string>, value) {
    //var contentCopy = new ObjectManipulator().DeepCopy(content);
    
    return UpdateContentHelper(content, depth, value);
}

function UpdateContentHelper(content, depth : Array<string>, value) {
    var index = depth[0];

    if (depth.length == 1) {
        if(index == 'resource_name')
        {
            content.resource_name = value.resource_name;
            content.folder_name = value.resource_folder;
        }
        else
        {
        content[index] = value;
        }
    }
    else {
        var d = depth.slice(1);
        content[index] = UpdateContentHelper(content[index], d, value);
    }

    return content;
}

function AddChange(changes, mode, contentType, content) {

    //Make sure changes has the right fields
    if(!(mode in changes.added)) {
        changes.added[mode] = {};
    }

    if(!(contentType in changes.added[mode])) {
        changes.added[mode][contentType] = []
    }

    //Remove duplicates
    if(mode in changes.added && contentType in changes.added[mode]) {
        changes.added[mode][contentType] = changes.added[mode][contentType].filter(item => item != content)
    }

    changes.added[mode][contentType].push(content)
}

function RemoveChange(changes, mode, contentType, content) {

    //Make sure changes has the right fields
    if(!(mode in changes.removed)) {
        changes.removed[mode] = {};
    }

    if(!(contentType in changes.removed[mode])) {
        changes.removed[mode][contentType] = []
    }

    //Remove added content
    if(mode in changes.added && contentType in changes.added[mode]) {
        changes.added[mode][contentType] = changes.added[mode][contentType].filter(item => item.id.value != content.id.value)
    }

    //Remove duplicates
    if(mode in changes.removed && contentType in changes.removed[mode]) {
        changes.removed[mode][contentType] = changes.removed[mode][contentType].filter(item => item.id.value != content.id.value)
    }

    var item = {_id: content._id, created: content.created}
    changes.removed[mode][contentType].push(item)
}

interface State {
    subSelection?: number,
    contentMode: string,
    currentSchema?: any,
    content: any
    contentUpdateListeners?: Array<any>,
    allContent: any,
    isDirty: Array<string>,
    changes: {added: Object, removed: Object},
    localization?: Array<any>
}

//Contains all content that will be fed to the client
const ContentReducer = (state : State = {
    subSelection: 0,//the index of the item selected in content, ie index 5 on charms would be the 5th charm
    contentMode: "",
    currentSchema: {},
    content: { primaryContent: "", secondaryContent: {} },
    contentUpdateListeners: [],
    allContent: {}, 
    isDirty: [],
    changes: { added: {}, removed: {}},
    localization: [],
}, action : any) => {

    switch (action.type) {
        case ADD_CONTENT_LISTENER:
            var contentUpdateListeners = state.contentUpdateListeners;
            var existingListenerNames = contentUpdateListeners.map((listener) => listener.name)

            if (!existingListenerNames.includes(action.content.name))
                contentUpdateListeners.push(action.content);

            return Object.assign({}, state = {
                ...state,
                contentUpdateListeners: contentUpdateListeners
            });
        case REMOVE_CONTENT_LISTENER:

            var contentUpdateListeners = state.contentUpdateListeners;

            for (let index = 0; index < contentUpdateListeners.length; index++) {
                if (contentUpdateListeners[index].name == action.content.name) {
                    contentUpdateListeners.splice(index, 1);
                    break;
                }
            }

            return Object.assign({}, state = {
                ...state,
                contentUpdateListeners: contentUpdateListeners
            });

        case CONTENT_WIPE_CONTENT:
            return Object.assign({}, state = {
                contentMode: "",
                content: { primaryContent: "", secondaryContent: {} },
                allContent: {},
                isDirty: [],
                changes: { added: {}, removed: {}}
            });

        // case REFRESH_CONTENT:
        //     var allContent = state.allContent;
        //     var content : { primaryContent: any, secondaryContent: any} = state.content;
        //     //content.primaryContent.push(action.content);

        //     var changes = state.changes;
        //     var mode = state.contentMode
        //     AddChange(changes, mode, "primaryContent", action.content);

        //     var secondaryContent = {};
        //     if (state.contentMode in allContent)
        //         secondaryContent = allContent[state.contentMode].secondaryContent;

        //     allContent[state.contentMode] = { primaryContent: content.primaryContent, secondaryContent: content.secondaryContent };

        //     var isDirty = state.isDirty;
        //     if(!isDirty.includes(state.contentMode)) {
        //         isDirty.push(state.contentMode);
        //     }

        //     return Object.assign({}, state = {
        //         ...state,
        //         content: content,//{ primaryContent: primaryContent, secondaryContent: secondaryContent },
        //         allContent: allContent,
        //         subSelection: action.subSelection,
        //         isDirty: isDirty,
        //         changes: changes,
        //     });

        case CONTENT_ADD_CONTENT:

            var allContent = state.allContent;
            var content : { primaryContent: any, secondaryContent: any} = state.content;
            content.primaryContent.push(action.content);

            var changes = state.changes;
            var mode = state.contentMode
            AddChange(changes, mode, "primaryContent", action.content);

            var secondaryContent = {};
            if (state.contentMode in allContent)
                secondaryContent = allContent[state.contentMode].secondaryContent;

            allContent[state.contentMode] = { primaryContent: content.primaryContent, secondaryContent: content.secondaryContent };

            var isDirty = state.isDirty;
            if(!isDirty.includes(state.contentMode)) {
                isDirty.push(state.contentMode);
            }

            return Object.assign({}, state = {
                ...state,
                content: content,//{ primaryContent: primaryContent, secondaryContent: secondaryContent },
                allContent: allContent,
                subSelection: action.subSelection,
                isDirty: isDirty,
                changes: changes,
            });

        //TODO should be replaced with an add and remove function instead of a pure set
        case CONTENT_SET_CONTENT:

            var allContent = state.allContent;
            var secondaryContent = {};
            if (state.contentMode in allContent)
                secondaryContent = allContent[state.contentMode].secondaryContent;

            allContent[state.contentMode] = { primaryContent: action.content, secondaryContent: secondaryContent };

            var changes = state.changes;
            var mode = state.contentMode

            if(!(mode in changes.added)) {
                changes.added[mode] = {};
            }

            changes.added[mode].primaryContent = action.content
            
            return Object.assign({}, state = {
                ...state,
                content: { primaryContent: action.content, secondaryContent: secondaryContent },
                allContent: allContent,
            });

        case CONTENT_REMOVE_SELECTED_CONTENT:

            var content : { primaryContent: any, secondaryContent: any} = state.content;

            var primary = content.primaryContent;
            var newSecondary = content.secondaryContent;
            var subSelection = state.subSelection;

            var mode = state.contentMode;
            var changes = state.changes;
            RemoveChange(changes, mode, "primaryContent", primary[subSelection]);

            //update content
            primary.splice(subSelection, 1);
            content = { primaryContent: primary, secondaryContent: newSecondary }

            if(subSelection > 0) {
                var newSubSelection = subSelection - 1
            } else {
                var newSubSelection = subSelection + 1
            }

            var isDirty = state.isDirty;
            if(!isDirty.includes(state.contentMode)) {
                isDirty.push(state.contentMode);
            }

            var allContent = state.allContent;
            allContent[state.contentMode] = content;
            return Object.assign({}, state = {
                ...state,
                content: content,
                allContent: allContent,
                subSelection: newSubSelection,
                isDirty: isDirty,
            });


        case CONTENT_SET_SECONDARY_CONTENT:

            var key = action.content.key;
            var value = action.content.value;
            var secondaryContent : {} = state.content.secondaryContent;
            secondaryContent[key] = value;
            var content : { primaryContent: any, secondaryContent: any} = { primaryContent: state.content.primaryContent, secondaryContent: secondaryContent };

            var allContent = state.allContent;
            allContent[state.contentMode] = content;
            return Object.assign({}, state = {
                ...state,
                content: content,
                allContent: allContent,
            });

        case CONTENT_UPDATE_SECONDARY_CONTENT:

            var key = action.content.key;
            var value = action.content.value;
            var depth : Array<string> = action.content.depth;

            var changes = state.changes
            var mode = state.contentMode
            AddChange(changes, mode, "secondaryContent", state.content.secondaryContent[key]);

            var updatedContent = UpdateContent(state.content.secondaryContent, depth, value);
            var UpdatedContentFinal : {primaryContent: any, secondaryContent: any} = { primaryContent: state.content.primaryContent, secondaryContent: updatedContent };

            var allContent = state.allContent;
            allContent[state.contentMode] = UpdatedContentFinal;

            var isDirty = state.isDirty;
            if(!isDirty.includes(state.contentMode)) {
                isDirty.push(state.contentMode);
            }

            var changes = state.changes
            var mode = state.contentMode
            AddChange(changes, mode, "secondaryContent", state.content.secondaryContent[action.content.depth[0]]);

            return Object.assign({}, state = {
                ...state,
                content: UpdatedContentFinal,
                allContent: allContent,
                isDirty: isDirty
            });

        case CONTENT_REMOVE_SECONDARY_CONTENT:
            var key = action.content.key;
            var value = action.content.value;
            var depth : Array<string> = action.content.depth;

            var newSecondary = state.content.secondaryContent;
            var changes = state.changes;
            var mode = state.contentMode;
            RemoveChange(changes, mode, "secondaryContent", newSecondary[key]);
            delete newSecondary[key];


            return Object.assign({}, state = {
                ...state,
                content: {primaryContent: state.content.primaryContent, secondaryContent: newSecondary},
                changes: changes,
            });

        case CONTENT_UPDATE_CONTENT:

            var updatedContent = UpdateContent(state.content.primaryContent, action.depth, action.content);

            var changes = state.changes
            var mode = state.contentMode
            AddChange(changes, mode, "primaryContent", state.content.primaryContent[action.depth[0]]);

            var UpdatedContentFinal : {primaryContent: any, secondaryContent: any} = { primaryContent: updatedContent, secondaryContent: state.content.secondaryContent };

            //call all content listeners
            state.contentUpdateListeners.forEach((contentListener) => {
                var result = contentListener(action.depth[0]);
                if (result != null) {
                    if (result.type == "secondary") {
                        var secondaryContent = UpdateContent(state.content.secondaryContent, result.depth, result.value);
                        UpdatedContentFinal.secondaryContent = secondaryContent;
                    }
                }
            })

            var isDirty = state.isDirty;
            if(!isDirty.includes(state.contentMode)) {
                isDirty.push(state.contentMode);
            }

            var allContent = state.allContent;
            allContent[state.contentMode] = UpdatedContentFinal;
            return Object.assign({}, state = {
                ...state,
                content: UpdatedContentFinal,
                allContent: allContent,
                isDirty: isDirty
            });

        case CONTENT_SET_LOCALIZATION:

            state = {
                ...state,
                localization: action.content
            };

            break;

        case CONTENT_UPDATE_LOCALIZATION:

            var language = action.content.language;
            var depth : Array<string> = [];

            for (var index in state.localization) {
                if (state.localization[index].title.code == language) {
                    depth.push(index);
                    depth.push("localizations");
                    depth.push(action.content.key);
                    break;
                }
            }

            var updatedLocalization = UpdateContent(state.localization, depth, action.content.value);

            var allContent = state.allContent;
            allContent["language_localization_set"] = { primaryContent: updatedLocalization, secondaryContent: {} };

            var changes = state.changes;
            changes.added["language_localization_set"] = { primaryContent: updatedLocalization, secondaryContent: {} };

            var isDirty = state.isDirty;
            if(!isDirty.includes(state.contentMode)) {
                isDirty.push(state.contentMode);
            }

            return Object.assign({}, state = {
                ...state,
                localization: updatedLocalization,
                allContent: allContent,
                isDirty: isDirty
            });

        case CONTENT_DELETE_LOCALIZATION:

            var language = action.content.language;
            var depth : Array<string> = [];

            var updatedLocalization;

            for (var index in state.localization) {
                if (state.localization[index].title.code == language) {
                    depth.push(index);
                    depth.push("localizations");

                    var locIndex = state.localization[index].localizations;
                    delete locIndex[action.content.key];
                    updatedLocalization = UpdateContent(state.localization, depth, locIndex);
                    break;
                }
            }

            var allContent = state.allContent;
            allContent["language_localization_set"] = { primaryContent: updatedLocalization, secondaryContent: {} };
            
            var changes = state.changes;
            changes.added["language_localization_set"] = { primaryContent: updatedLocalization, secondaryContent: {} };

            var isDirty = state.isDirty;
            if(!isDirty.includes(state.contentMode)) {
                isDirty.push(state.contentMode);
            }

            return Object.assign({}, state = {
                ...state,
                localization: updatedLocalization,
                allContent: allContent,
                isDirty: isDirty
            });

        case CONTENT_CHANGE_SUBSELECTION:

            state = {
                ...state,
                subSelection: action.content
            };

            break;

        case CONTENT_CHANGE_MODE:
            if ((!state.isDirty.includes(state.contentMode)) && (state.contentMode != "")) {
                delete state.allContent[state.contentMode]
            }

            //get variables from content
            let contentMode = action.content.mode;
            var primaryContent : {contentMode: any, subSelection: any, typeInfo: any} = action.content.typeInfo.content;
            
            let schema = {}
            if (action.content.typeInfo.schema)
                schema = action.content.typeInfo.schema;

            var subSelection : number = action.content.subSelection

            if (subSelection == -1)
                subSelection = state.subSelection;

            //set up state
            var allContent = state.allContent;
            var secondaryContent = {};
            if (contentMode in allContent)
                secondaryContent = allContent[contentMode].secondaryContent;

            var contentFinal = { primaryContent: primaryContent, secondaryContent: secondaryContent }
            allContent[contentMode] = contentFinal;

            //assign state
            return Object.assign({}, state = {
                ...state,
                currentSchema: schema,
                contentMode: contentMode,
                content: contentFinal,
                allContent: allContent,
                subSelection: subSelection,
            });

        case CLEAR_CHANGES:
            state = {
                ...state,
                changes: {added: {}, removed: {}}
            };

            break;
    }

    return state;
};

export default ContentReducer;