import React, { useEffect } from "react";
import Select, { components } from 'react-select';
import ResourceCache from './../../utilities/Cache Utilities/ResourceCache';
import PropTypes from 'prop-types';
import Context from './../../models/Context'
var snake = require('to-snake-case');
import { GetGenericType } from './../../utilities/Utilities';

interface Props
{
	label: string,
	depth: Array<string>,
    value: any,
	genericType: string,//type of resource we need
    context: Context,
    data: Option,
    schema?: any,
}

interface Value {
    resource_name: string,
}

interface Option {
    label: string,
    value: string,
    resource_folder: string,
}

export const AssetIdSelectorComponent :React.FC<Props> = (props) => {
    const [value, UpdateValue] = React.useState(props.value.resource_name);
    const [selectedOption, UpdateSelectedOption] = React.useState(0);
    var optionsStart : Option[] = []; 
    const [options, UpdateOptions] = React.useState(optionsStart);
    var dictionaryStart : {[Key: string] : string;} = {};
    const [icons, UpdateIcons] = React.useState(dictionaryStart);
    const [sounds, UpdateSounds] = React.useState(dictionaryStart); 

    var OnUpdateValue = (event) => {

        var newValue = snake(event.label);

        UpdateValue(newValue);
        UpdateSelectedOption(event.value);

        //hack fix for depth
        var AssetIdDepth = props.depth.slice();
        AssetIdDepth.push("resource_name");
        
        var resource_name = snake(event.label);
        newValue = {resource_name: resource_name, resource_folder: event.resource_folder}

        props.context.UpdateContent(newValue, AssetIdDepth);
    }

    useEffect(() => {
        SetOptions();
    }, [])

    var SetOptions = () => {

        let genericType = props.genericType;
       

        if ((props.value.resouce_name == null)&&(props.value.folder_name == null))
            genericType = GetGenericType(props.schema.type);

        new ResourceCache().GetCacheValue(genericType).then(results => {

            var icons : {[Key: string] : string;} = {};
            var sounds : {[Key: string] : string;} = {};
            var options : Option[] = [];
            var selectedOption = 0;

         
            for (var index in results) {

                var resource = results[index];

                var option = resource.name.replace(/_/gi, ' ');

                if (resource.name == value) {
                    selectedOption = parseInt(index);
                }

                if (value == "")
                {
                    //console.log("empty value? ",index);
                    selectedOption = 0;
                }

                // options.push({ label: option+ " | " + resource.resource_folder, value: index });
                options.push({ label: option, value: index , resource_folder: resource.resource_folder});

                if ((genericType == "Sprites") && (resource.additionalData != null) && (resource.additionalData.length > 0)) {
                    icons[option] = resource.additionalData;
                }
                else if ((genericType == "Sounds") && (resource.additionalData != null) && (resource.additionalData.length > 0)) {
                    sounds[option] = resource.additionalData;
                }
            }

            UpdateOptions(options);
            UpdateIcons(icons);
            UpdateSounds(sounds);
            UpdateSelectedOption(selectedOption);
        });
    }

    var GetIcon = (key : string) => {
        var UiJsx : JSX.Element[] = [];
        if (key in icons) {
            UiJsx.push(<img src={icons[key]} width="30" height="30" style={{ backgroundColor: "lightgrey" }}></img>)
        }

        return UiJsx;
    }

    const { Option } = components;
    const IconOption = (props : Props) => (

        <Option {...props}>
            {GetIcon(props.label)}
            {props.data.label}
        </Option>
    );

    var key = "";

    //TODO get key properly
    if (options.length > selectedOption)
    {
        key = options[selectedOption]["label"];
    }
    if (options.length == 0) {

        return (
            <div>
                <p>{props.label}</p>
                loading...
            </div>);
    }
    else if (key in sounds) {

        return (
            <div>
                <div className="form-inline form-inline-id">
                    <p>{props.label}</p>

                    <Select
                        className="form-control-id"
                        components={{ Option: IconOption }}
                        options={options}
                        onChange={OnUpdateValue}
                        value={options[selectedOption]}
                    />

                    <audio controls ref="audio">
                        <source src={sounds[key]} type="audio/wav" />
                            <p>Your browser doesn't support HTML5 audio.</p>
                </audio>
            </div>
                </div>);
    }
    else if (Object.keys(sounds).length > 0)
    {
        return (
            <div>
                <div className="form-inline form-inline-id">
                    <p>{props.label}</p>

                    <Select
                        className="form-control-id"
                        components={{ Option: IconOption }}
                        options={options}
                        onChange={OnUpdateValue}
                        value={options[selectedOption]}
                    />

                    <p> sound effect must be .wav format to preview</p>
            </div>
                </div>);
    }
    else
    {
        return (
            <div>
                    <div className="form-inline form-inline-id">
                        <p>{props.label}</p>

                        <Select
                            className="form-control-id"
                            components={{ Option: IconOption }}
                            options={options}
                            onChange={OnUpdateValue}
                            value={options[selectedOption]}
                        />
                    </div>
                </div>
                );
    }
}