import React, { useState } from 'react';
import {IsInt} from "./../../utilities/Utilities";
import Context from './../../models/Context'

interface Props
{
	label: string,
	depth: Array<string>,
    value: number,
	context: Context //you will need a context object eventually
}

export const IntegerFieldComponent:React.FC<Props> = (props) => {
    
    const [value, UpdateValue] = React.useState(props.value);
    const [displayValue, UpdateDisplayValue] = React.useState(String(props.value));
    

	var OnUpdateNewValue = (event : React.ChangeEvent<HTMLInputElement>)=>
	{
        UpdateDisplayValue(event.target.value)
	}

	var UpdateState = () =>
    {
        if (IsInt(displayValue))
        {
            UpdateValue(parseInt(displayValue));
            props.context.UpdateContent(parseInt(displayValue), props.depth);
        }
        else    
        {
            alert("Error, this value must be an integer value");
            UpdateDisplayValue(String(value));
        }   
    }

	return (
		<span className ="form-inline">
            <p>
                {props.label}
            </p>
            <input className="form-control" type="text" value={displayValue} onChange={(event : React.ChangeEvent<HTMLInputElement>) => OnUpdateNewValue(event)} onBlur={UpdateState} />
        </span>
	);
}