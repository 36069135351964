
import { PullContent } from "./../web interface utilities/WebApi";

let instance = null;

class ContentCache {
    constructor() {
        if (!instance) {
            instance = this;
            this.cache = new Map();
            this.promiseCache = new Map();
            this.bannedList = [];
        }
    }

    WipeCache()
    {
        instance.cache = new Map();
        instance.promiseCache = new Map();
        instance.bannedList = [];
    }

    GetCacheValue(key)
    {

        if (key == "SpawnLayer")
            key = "spawnlayers"
        //console.log("key ",key);
        //console.trace()

        if (instance.bannedList.includes(key))
        {
            console.log("was banned?!");
             return null;
        }
       
        if (!instance.cache.has(key))
            instance.cache.set(key, "");

        //case key is set 
        if (instance.cache.get(key) != "")
        {
            return new Promise(function(resolve,reject){resolve(instance.cache.get(key))});
        }
        //case we are already pulling this schema from the server
        else if (instance.promiseCache.has(key))
        {
            return instance.promiseCache.get(key);
        }
        //case we need to pull this schema from the server
        else
        {
            var request = PullContent(key).then(function(result)
            {
                if (result != null)
                {
                    var value = result.content_value;

                    if (Object.prototype.toString.call(result.content_value) === "[object String]")
                    {
                        value = JSON.parse(result.content_value);
                    }
                        
                    instance.cache.set(key, value);
                    return value;
                }
                else
                {
                    console.log("no content result for ",key);
                    //instance.bannedList.push(key);
                    instance.cache.delete(key);
                    return [];
                }
            });

            instance.promiseCache.set(key,request);
            return request;
        }
    }

    //checks cache for all ids and returns a new one that is unique
    GetUniqueIdFromCache()
    {

    }
}


export default ContentCache;
