
import { IsJsonArray, IsSchemaUnityVector3 } from "./../Utilities";

class TypeSupporter {
    IsTypeSupported(type : string) {
        try {

            if ((type.includes("AdminConsoleDictionaryAttribute")) ||
                (type.includes("AdminConsole") && (type.includes("Attribute."))) ||
                (type == "number") ||
                (type == "UnityEngine.Vector3") ||//todo fix this (IsSchemaUnityVector3(prop)))||
                (type == "integer") ||
                (type == "boolean") ||
                (((IsJsonArray(type)) && (type[0] == "string")) || (type == "string")) ||
                (((type[type.length - 2] == '[') && (type[type.length - 1] == ']')) || (Array.isArray(type)) || (type == "Admin.MultiSelect") || (type.includes("System.Collections.Generic.List"))) ||
                (type.includes("LocId")) ||
                (type.includes('Core.Models.Id')) ||
                (type.includes('Core.Models.AssetId')) ||
                (type.includes('Metatron.Schemas') && type.includes('[]')) ||
                (type == "UnityEngine.Color") ||
                (type == "UnityEngine.Time")||
                (type == "UnityEngine.Date")||
                (type == "UnityEngine.DateTime")) {
                return true;
            }

            return false;
        } catch (error) {
            return false;
         }

    }

}

export default TypeSupporter;
