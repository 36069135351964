import React, { useEffect } from "react";
import Select from 'react-select';
import { OptionSelectorFieldComponent } from "./OptionSelectorFieldComponent";
import { GetHeaderInfo ,GetHeaderTabFromType } from './../../../../global/GlobalStatics';
import Category from './../../models/Category';
import DropDownOption from './../../models/DropDownOption';

import Context from './../../models/Context'

interface Props {
    context: Context;
}

const categories: Array<Array<DropDownOption<string>>> = GetHeaderInfo().map((input: Category) => { return input.subcategories })

export const HeaderBar: React.FC<Props> = (props) => {

    useEffect(()=>{
        var headerTabInfo = GetHeaderTabFromType(props.context.contentMode)

        var tabIndex:number = headerTabInfo.tabIndex;
        var categoryIndex: number = headerTabInfo.categoryIndex;

        SetTabIndex(tabIndex);
        SetCategoryIndex(categoryIndex);
    });


    const [tabIndex,SetTabIndex] = React.useState(0);
    const [categoryIndex,SetCategoryIndex] = React.useState(0);

    const OnChangeTab = (index: number) => {

        //props.context.ChangeSubSelection(0);
        props.context.ChangeMode(categories[index][0].value,0);
    }

    const OnChange = (event:DropDownOption<string>)=>
    {
        //props.context.ChangeSubSelection(0);
        props.context.ChangeMode(event.value,0);
    }

    return (
        <div className="container-main">
            <OptionSelectorFieldComponent key={tabIndex} value={[tabIndex]} OnChangeTab={(index: number) => OnChangeTab(index)} options={GetHeaderInfo().map((input: Category) => { return input.title; })} />
            <br/>
            <span><label>SELECT A CATEGORY</label>
            <div style={{ width: "400px" }}>

          <Select
            className="form-control-main"
            onChange={(event:any) => OnChange(event)}
            options={categories[tabIndex]}
            value={new DropDownOption<string>(categories[tabIndex][categoryIndex].label,props.context.contentMode)}/></div>
            <hr/>
        </span></div>);
}
