//var baseUrl = "https://sigil.admin.thisislarge.com/";
var baseUrl = require("./../../../../config.json").server_url;

export function GetCookie(key)
{   
    if (document != null)
    {
        var name = key + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
    }
    return "";
}

function _parseJSON(response) {

    return response.text().then(function(text) 
    {
        try {
            return JSON.parse(text)
        }catch(e) {return null;} 
    })
  }
  
export async function PullSchema(schemaName)
{
    var uri = baseUrl+"schema/get_schema/?schema_name="+schemaName;

    const response = await fetch(uri,{method:'GET',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return _parseJSON(body);
}

export async function PullAllSchema(token = "")
{
    var uri = baseUrl+"schema/pull_all_schema";

    if (token == "")
    { 
        GetCookie("token");
    }

    const response = await fetch(uri,{method:'GET',headers: {'Content-Type': 'application/json','Authorization': 'Token '+token}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return _parseJSON(body);
}



export async function PullResource(resourceFolder)
{
    var uri = baseUrl+"resources/get_resource/?resource_folder="+resourceFolder;

    const response = await fetch(uri,{method:'GET',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return _parseJSON(body);
}

export async function RepairContent()
{
    var uri = baseUrl+"repair/repair_content/";

    const response = await fetch(uri,{method:'POST',timeout:600000,headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return body.text();
}

export async function PullContent(contentName)
{
    var uri = baseUrl+"content/get_content/?content_name="+contentName;

    const response = await fetch(uri,{method:'GET',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return _parseJSON(body);
}

export async function PullSpawnInfoContent(spawnId)
{
    var uri = baseUrl+"content/get_spawn_info_content/?spawn_id="+JSON.stringify(spawnId);

    const response = await fetch(uri,{method:'GET',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return _parseJSON(body);
}

export async function GetUniqueId(contentType)
{
    var uri = baseUrl+"content/get_unique_id/?content_type="+contentType;

    const response = await fetch(uri,{method:'GET',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return body.text();
}


export async function PublishChanges(changes,timeStamp)
{
    var uri = baseUrl+"content/update_content/?time_stamp="+timeStamp;

    const response = await fetch(uri,{method:'POST',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")},body:JSON.stringify( changes )});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return body.text();
}

export async function PullFirebaseToken()
{
    var uri = baseUrl+"users/generate_firebase_token";

    const response = await fetch(uri,{method:'GET',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return body.text();
}

export async function VerifyPassword(password)
{
    var passwordParam = "?password="+password

    var uri = baseUrl+"content/verify_publish/"+passwordParam

    const response = await fetch(uri,{method:'GET',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return body.text();
}

export async function Login(username,password)
{
    var requestBody = {"username":username,"password":password}

    var uri = baseUrl+"users/login/";//+usernameParam+passwordParam

    const response = await fetch(uri,{method:'POST',headers: {'Content-Type': 'application/json'},body:JSON.stringify(requestBody)});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return _parseJSON(body);
}


export async function PullBackups()
{
    var uri = baseUrl+"content/get_backup_options"

    const response = await fetch(uri,{method:'GET',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return _parseJSON(body);
}

export async function RevertToBackup(backup_name)
{
    var backup_nameParam = "?backup_name="+backup_name;

    var uri = baseUrl+"content/revert_to_backup/"+backup_nameParam;

    const response = await fetch(uri,{method:'GET',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return body.text();
}

export async function PushChangesToProduction(timestamp)
{
    var uri = baseUrl+"content/backup_content_publish_production/";

    const response = await fetch(uri,{method:'POST',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return body.text();
}

export async function BackupContentPublishProduction(timeStamp){
    var uri = baseUrl+"content/backup_content_publish_production/?time_stamp="+timeStamp;

    const response = await fetch(uri,{method:'POST',headers: {'Content-Type': 'application/json','Authorization': 'Token '+GetCookie("token")}});

    const body = await response;

    if (response.status !== 200) throw Error(body.message);

    return body.text();
}
