import React from "react";
import { Container, Row, Col, Label, Button,Input } from "reactstrap";
import {Login} from "./../utilities/web interface utilities/WebApi";
//import FirebaseLogin from "./../utilities/web interface utilities/FirebaseLogin";
import PropTypes from 'prop-types';

interface Props {
    callback: Function,
}

interface State {
    username: string,
    password: string
}

export class LoginContainer extends React.Component<Props, State> {

    
    constructor(props) {
        super(props);

        this.state = {
            username:"",
            password:""
        }
    }

    OnLogin()
    {
        Login(this.state.username,this.state.password).then(result=>{

            //TODO user user scope somewhere
            if (typeof(result) == "object")
            {
                console.log(result);
                document.cookie = "token="+result.token; 

                //new FirebaseLogin().Login().then(()=>{
                    this.props.callback(result);
                //});
            }
                
            else
                alert("Your username or password is incorrect!");
        });
    }

    render() {
        return(
        <Container>
           
            <Row style={{ height: '200px' }}></Row>

            <Row>
            <Col lg={{size:5, offset: 3}}  className ="login-background">
            <h1>Login</h1>
            <Label className="title">Username</Label>
            <input className="form-control form-control-style" type="text" placeholder="Enter Username" onChange={(event)=>this.setState({username:event.target.value})}/>

     
            <Label className="title">Password</Label>
            <Input className="form-control-style" type="password" placeholder="Enter Password" onChange={(event)=>this.setState({password:event.target.value})}/>
       
            <Button color = "primary" size ="lg" onClick={(()=>this.OnLogin())} block>Login</Button>
     
            </Col>
            </Row>
            </Container>);
    }

}