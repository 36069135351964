
import { PullSchema } from "./../web interface utilities/WebApi";

let instance = null;

class SchemaCache {
    constructor() {
        if (!instance) {
            instance = this;
            this.cache = new Map();
            this.promiseCache = new Map();
            this.bannedList = [];
        }
    }

    WipeCache()
    {
        instance.cache = new Map();
        instance.promiseCache = new Map();
        instance.bannedList = [];
    }

    GetCacheValue(key)
    {
        if (instance.bannedList.includes(key))
        {
            //return null;
            return new Promise(function(resolve,reject){resolve(null)});
        }

        if (!instance.cache.has(key))
            instance.cache.set(key, "");

        //case key is set 
        if (instance.cache.get(key) != "")
        {
            return new Promise(function(resolve,reject){resolve(instance.cache.get(key))});
        }
        //case we are already pulling this schema from the server
        else if (instance.promiseCache.has(key))
        {
            return instance.promiseCache.get(key);
        }
        //case we need to pull this schema from the server
        else
        {
            var request = PullSchema(key).then(function(result)
            {
                if (result!= null)
                { 
                    var value = JSON.parse(result.schema_value);
                    instance.cache.set(key, value);
                }
                else
                {
                    //instance.bannedList.push(key);
                    instance.cache.delete(key);
                }
                   
                return value;
            });

            instance.promiseCache.set(key,request);
            return request;
        }
    }
}


export default SchemaCache;

