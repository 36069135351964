
import { PullResource } from "./../web interface utilities/WebApi";
var upperCamelCase = require('uppercamelcase');

let instance = null;

class ResourceCache {
    constructor() {
        if (!instance) {
            instance = this;
            this.cache = new Map();
            this.promiseCache = new Map();
            this.bannedList = [];
        }
    }

    WipeCache()
    {
        instance.cache = new Map();
        instance.promiseCache = new Map();
        instance.bannedList = [];
    }

    GetCacheValue(key)
    {
        key = upperCamelCase(key);
        
        if (instance.bannedList.includes(key))
        return null;

        // if (!instance.cache.has(key))
        //     instance.cache.set(key, "");

        // //case key is set 
        // if (instance.cache.get(key) != "")
        // {
        //     return new Promise(function(resolve,reject){resolve(instance.cache.get(key))});
        // }
        // //case we are already pulling this schema from the server
        // else if (instance.promiseCache.has(key))
        // {
        //     return instance.promiseCache.get(key);
        // }
        //case we need to pull this schema from the server
        if(true)
        {
            var request = PullResource(key).then(function(result)
            {
                if (result != null)
                {                    
                    var emptyValue = [{name:"",additionalData:""}];
                 
                    const resource_values = result.map(resource => JSON.parse(resource.resource_value));
                    for (var i in resource_values){
                        let resourceValue  = resource_values[i];
                        resourceValue.resource_folder = result[i].resource_folder;
                    }
                    return [...emptyValue, ...resource_values];
                }
            
                else
                {
                    //instance.bannedList.push(key);
                    instance.cache.delete(key);
                    return []
                }
            });

            instance.promiseCache.set(key,request);
            return request;
        }
    }
}


export default ResourceCache;
