import { string } from "prop-types";

//used for react-select option for the drop down menu
export default class DropDownOption<T> 
{
    public label:string;
    public value:T;

    constructor(label:string,value:T)
    {
        this.label = label;
        this.value = value;
    }

}