import React, { useState, useEffect} from 'react';

import ContentCache from './../../utilities/Cache Utilities/ContentCache';
var upperCamelCase = require('uppercamelcase');
var snake = require('to-snake-case');
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import Context from './../../models/Context'
//import {Linker} from './../../utilities/linking utilities/Linker'
var mongoose = require("mongoose");

interface Props {
	label: string,
	depth: Array<string>,
	value: Value,
	context: Context //you will need a context object eventually
}

interface Value {
    value: number,
    type: string,
}

interface Option {
    label: string,
    value: number,
}

const defaultType:string = "charm"

export const InventoryIdFieldComponent: React.FC<Props> = (props) => {

    var [value, UpdateValue] = useState(props.value.value);
    //var [type, UpdateType] = useState(props.value.type == "inventory_item" ? "charm" : props.value.type);
    var [type, UpdateType] = useState(props.value.type);
    var startOptions : Array<Option> = []
    var [options, UpdateOptions] = useState(startOptions);
    var startOption : Option  = { label: "none", value: 0 };
    var [selectedOption, UpdateSelectedOption] = useState(startOption);

    var PullOptions = (type: string) => {

        if (type == "inventory_item")
        {
            UpdateType(defaultType);

            var depth = props.depth.slice();
            //depth.push("type");
            //props.context.UpdateContent(defaultType, depth);
            props.context.UpdateContent({"type":defaultType,"value":value}, depth);
        }

        new ContentCache().GetCacheValue(upperCamelCase(type)).then(result => 
        {
            var options : Array<Option> = [];
            var newSelectedOption : Option | null = null;

            for (var item in result) {
                var option = result[item].admin_console_display_name.replace(/_/gi, ' ');
                var id = result[item].id;

                if (id.value == value) {
                    newSelectedOption = { label: option, value: id };
                }

                options.push({ label: option, value: id });
            }

            if ((newSelectedOption == null)&&(options.length>0))
            {
                newSelectedOption = options[0];
                var depth = props.depth.slice();
                props.context.UpdateContent(newSelectedOption.value, depth);
            }

            UpdateOptions(options);
            UpdateSelectedOption(newSelectedOption);
        });
    }

    var OnUpdateType = async (event) => {
        var type = snake(event.target.value);
        var content = await new ContentCache().GetCacheValue(upperCamelCase(type));

        var oldID = {value: value, type: type};
        var newValue = content[0].id.value;
        UpdateValue(newValue);
        UpdateType(type);

        //var linker = new Linker();
        var index = props.depth[0];
        var linkID = {value: props.context.content[index].id, type: props.context.contentMode}
        //await linker.ChangeLink(oldID, content[0].id, linkID, [...props.depth], props.context);

        let depth = props.depth.slice();
        props.context.UpdateContent({"type":type,"value":value}, depth);

        PullOptions(type);
    }

    var OnUpdateValue = async (event) => {

        var newValue = parseInt(event.value.value);
        //var linker = new Linker();
        var index = props.depth[0];
       // var linkID = {value: props.context.content[index].id, type: props.context.contentMode}
        //await linker.ChangeLink({value: value, type: type}, {value: newValue, type: type}, linkID, [...props.depth], props.context);

        UpdateValue(newValue);
        UpdateSelectedOption(event);
        var depth = props.depth.slice();

        props.context.UpdateContent({"type":type,"value":newValue}, depth);
    }

    useEffect(() => {
        PullOptions(type);
    }, []);

    var GetTypeOptions = () => {

        var UiJsx : Array<JSX.Element> = [];
        UiJsx.push(<option value={"Charm"} key={0}>{"Charm"}</option>);
        UiJsx.push(<option value={"ConsumableHexItem"} key={1}>{"Consumable Hex Item"}</option>);
        UiJsx.push(<option value={"Equipment"} key={2}>{"Equipment"}</option>);
        UiJsx.push(<option value={"Ingredient"} key={3}>{"Ingredient"}</option>);
        UiJsx.push(<option value={"InnateHexItem"} key={4}>{"Innate Hex Item"}</option>);
        UiJsx.push(<option value={"CraftingRecipe"} key={5}>{"Recipe"}</option>);
        UiJsx.push(<option value={"CostumeItem"} key={6}>{"Costume Item"}</option>);
        return UiJsx;
       
    }

    return (
        <span className="form-inline">
                <p>{props.label}</p>

                <select className="form-control" value={upperCamelCase(type.toString())} onChange={OnUpdateType} >
                    {GetTypeOptions()}
                </select>

                <Select
                    className="form-control-id"
                    options={options}
                    onChange={OnUpdateValue}
                    value={selectedOption}
                />
            </span>
    );
}