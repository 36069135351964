
import { GetUniqueId } from './../WebApi';

class UniqueIdProvider
{
    GetUniqueId(collectionType, existingContent)
    {
        return GetUniqueId(collectionType).then((idNumber)=>{

            idNumber = parseInt(idNumber);

            var highestId = 0;
            for(var index in existingContent)
            {
                var id = existingContent[index].id

                if (typeof (id) == "object")
                    id= parseInt(id.value);

                if (id == idNumber)
                {
                    return idNumber+1;
                }
                    
            }
            return idNumber;
        });
    }
}

export default UniqueIdProvider;