import React, {useEffect} from "react";
import Select from 'react-select';
import SchemaCache from '../utilities/Cache Utilities/SchemaCache';
import ContentCache from '../utilities/Cache Utilities/ContentCache';
import { Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

var snake = require('to-snake-case');

interface Props
{
    selectedOptions: Array<string>,
    schemaType: any,
    allowDuplicates: boolean,
    depth: Array<string>,
    keysToIgnore: Array<string>,
    add: Function,
    remove: Function,
}

interface Option {
    label: string,
    value: any
}

export const ContentSelectorComponent:React.FC<Props> = (props) => {
    var startOptions : Array<Option> = [];
    var startOption : Option = null;
    const [addOptions, UpdateAddOptions] = React.useState(startOptions);
    const [removeOptions, UpdateRemoveOptions] = React.useState(startOptions);
    const [selectedAddOption, UpdateSelectedAddOption] = React.useState(startOption);
    const [selectedRemoveOption, UpdateSelectedRemoveOption] = React.useState(startOption);
    
    var GetContentToAdd = () => {
        var options : Array<Option> = [];
        new ContentCache().GetCacheValue(props.schemaType).then(result => {
            for (var key in result) {
                var option = result[key].admin_console_display_name;

                if (props.selectedOptions === undefined || !props.selectedOptions.includes(snake(option.toLowerCase())) || props.allowDuplicates) {
                    var label = option.replace(/_/gi, ' ');
                    var id = result[key].id;
                    options.push({ label: label, value: id });
                }
            }

            if (options.length > 0) {
                UpdateAddOptions(options);
                UpdateSelectedAddOption(options[0]);
            } else {
                UpdateSelectedAddOption(null);
            }
        });
    }

    var GetContentToRemove = () => {

        var options = [];

        new ContentCache().GetCacheValue(props.schemaType).then(result => {

            var contentTypes : Array<string> = result.map((contentType)=>snake(contentType.admin_console_display_name.toLowerCase()));
           
            var trueIndex = 0;
            for (var index in props.selectedOptions)
            {
                var option =  props.selectedOptions[index];
                if (contentTypes.includes(option)) {
                    var label = option.replace(/_/gi, ' ');
                    options.push({ label: label, value: trueIndex });
                    trueIndex++;
                }
            }
           
            if (options.length > 0) {
                UpdateRemoveOptions(options);
                UpdateSelectedRemoveOption(options[0]);
            }
        });
    }


    var OnChangeSelectedAdd = (selectedAddOption) => {
        UpdateSelectedAddOption(selectedAddOption);
    }

    var OnChangeSelectedRemove = (selectedRemoveOption) => {
        UpdateSelectedRemoveOption(selectedRemoveOption);
    }

    var Update = () => {
        GetContentToAdd();
        GetContentToRemove();
    }

    useEffect(() => {
        Update();
    }, [props.selectedOptions])

    const addSelection = (<Select
        className="form-control-main"
        options={addOptions}
        onChange={OnChangeSelectedAdd}
        value={selectedAddOption}
    />);

    const addButton = (<Button color="success" onClick={async (event) =>{
        if (selectedAddOption.label.length > 0) {
            props.add(selectedAddOption.value, props.schemaType)
        }

        Update();
    }} >Add Element</Button>);


    const removeSelection = (<Select
        className="form-control-main"
        options={removeOptions}
        onChange={OnChangeSelectedRemove}
        value={selectedRemoveOption}
    />);


    const removeButton = (<Button color="danger" onClick={(event) => {
            props.remove(selectedRemoveOption.value)
            Update();
    }}>Remove Element</Button>);

    return <View addSelection={addSelection} addButton={addButton} removeSelection={removeSelection} removeButton={removeButton} depth={props.depth.length} />
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));

const View = (props) => {

    const classes = useStyles();
    return (
        <div className={classes.root} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Grid container direction="row" spacing={3} style={{ padding: 10 }}>
                <Grid item xs={2}>
                    {props.addSelection}
                </Grid>
                <Grid item xs={2}>
                    {props.addButton}
                </Grid>

                <Grid item xs={2}>
                    {props.removeSelection}
                </Grid>
                <Grid item xs={2}>
                    {props.removeButton}
                </Grid>
            </Grid>
        </div>
    );
}