import React from 'react';
import Select from 'react-select';
import Context from './../../models/Context'

interface Props
{
	label: string,
	depth: Array<string>,
    value: any,
    language?: string,
    options: any,
    context: Context //you will need a context object eventually
    keysToIgnore: Array<string>,
    show:boolean
}


function SetDefaultText(localization,language,key)
{
    var text = "";

    for(var index in localization)
    {
        if (localization[index].title.code == language)
        {
            if (key in localization[index].localizations)
            {
                text = localization[index].localizations[key]
            }
        }
    }

    return text;
}

export const LocIdFieldComponentt:React.FC<Props> = (props) => {
    const [render, rerender] = React.useState(false);


    if (!props.value.code)
    // if (true)
    {
        let category = props.depth[props.depth.length-2];
        let contentType = props.context.contentMode;

        let identifier = "";

        if (props.depth[0] in props.context.content)
        {
            identifier = props.context.content[props.depth[0]].admin_console_display_name;
        }
        //secondary content
        else
        {
            for(let index in props.context.content)
            {
                let content = props.context.content[index];

                if (content.id == props.depth[0])
                {
                    identifier = content.admin_console_display_name+"_secondary";
                    break;
                }
            }
        }

        let value = {code:contentType+"_"+identifier+"_"+category+"_"+props.label}

        //let code = contentType+"_"+identifier+"_"+category+"_"+props.label;
        //props.value.code = contentType+"_"+identifier+"_"+category+"_"+props.label;
        
        props.context.UpdateContent(value, props.depth);
        // rerender(!render);

    }


    //var text =  SetDefaultText(props.context.localization,props.options[0].value,props.value.code);

    const [value, UpdateValue] = React.useState(props.value);
    const [selectedLanguage, UpdateSelectedLanguage] = React.useState(props.options[0]);
    const [textValue, UpdateTextValue] = React.useState(SetDefaultText(props.context.localization,props.options[0].value,props.value.code))
    const [newTextValue, UpdateNewTextValue] = React.useState(SetDefaultText(props.context.localization,props.options[0].value,props.value.code));
    const [options, UpdateOptions] = React.useState(props.options)

    var OnUpdateValue = (event) =>
    {
        UpdateNewTextValue(event.target.value);
    }

    var UpdateState = () =>
    {
        var textValue = newTextValue;
        UpdateTextValue(textValue);
        props.context.UpdateLocalization(props.value.code,textValue,selectedLanguage.value);
    }

    //UpdateState = UpdateState.bind(this);

    var OnChangeLanguage = (newLanguage) =>
    {
        var text = SetDefaultText(props.context.localization,newLanguage.value,props.value.code);
        UpdateSelectedLanguage(newLanguage);
        UpdateTextValue(text);
        UpdateNewTextValue(text);
    }

    return (
        // props.show?
        // <span className ="form-inline form-inline-custom">
        // <p>{props.label}</p>
        // <span className="resizable-input">
        // <textarea className="form-control" type="text" rows={3} value={newTextValue} onChange={OnUpdateValue} onBlur={UpdateState} />
        // </span>
        // <Select 
        //     className="form-control-small"
        //     onChange={(newLanguage)=>OnChangeLanguage(newLanguage)}
        //     options={options}
        //     value = {selectedLanguage} 
        //     />
        
        
        // </span>: 
        // <span className ="gone">
        // <p>{props.label}</p>
        // <span className="resizable-input">
        // <textarea className="form-control" type="text" rows={3} value={newTextValue} onChange={OnUpdateValue} onBlur={UpdateState} />
        // </span>
        // <Select 
        //     className="form-control-small"
        //     onChange={(newLanguage)=>OnChangeLanguage(newLanguage)}
        //     options={options}
        //     value = {selectedLanguage} 
        //     />
        
        
        // </span>
        <p></p>
    );
}