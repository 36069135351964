import { string } from "prop-types";
import React, { Fragment } from "react";

import { Alert, Button, Label, Container, Row, Col, Input, Progress } from "reactstrap";
import { PublishChanges, VerifyPassword } from "./../utilities/web interface utilities/WebApi";
var upperCamelCase = require('uppercamelcase');

interface TypeContainer {
    setState: Function
    props: Props,
}

interface Props {
    context: any
}

export const GetPublishJsx = (typeContainer : TypeContainer) => {
    var UiJsx = [];

    UiJsx.push(<Button color="success" onClick={() => OnAreYouSurePublish(typeContainer)} >Publish Changes</Button>);

    typeContainer.setState({ publishDisplay: UiJsx });
}


// <Button color="success" onClick={() => this.OnVerifyPublish()} block>Yes!</Button>
//this.OnPublish()
const OnAreYouSurePublish = (typeContainer : TypeContainer) => {
    var UiJsx = [];
    UiJsx.push(<Alert color="danger">
        <h4 className="Alert-heading">Are you sure you want to publish these changes?</h4>
        <hr />
        <Container>
            <Row>
                <Col>
                    <Button color="success" onClick={() => OnPublish(typeContainer)} block>Yes!</Button>
                </Col>
                <Col>
                    <Button color="danger" onClick={() => GetPublishJsx(typeContainer)} block>No!</Button>
                </Col>
            </Row>
        </Container>
    </Alert>);

    typeContainer.setState({ publishDisplay: UiJsx });
}

export const OnVerifyPublish = (typeContainer : TypeContainer) => {
    var UiJsx = [];
    UiJsx.push(<Alert color="danger">
        <h4 className="Alert-heading">Please enter your verification password</h4>
        <hr />
        <Container>
            <Row>
                <Input type="password" placeholder="Enter verification password" onChange={(event) => typeContainer.setState({ verificationPassword: event.target.value })} />
            </Row>
            <Row style={{ height: '25px' }} />
            <Row>
                <Col>
                    <Button color="success" onClick={() => OnPublish(typeContainer)} block>Verify</Button>
                </Col>
                <Col>
                    <Button color="danger" onClick={() => GetPublishJsx(typeContainer)} block>Cancel</Button>
                </Col>
            </Row>
        </Container>
    </Alert>);

    typeContainer.setState({ publishDisplay: UiJsx });
}

export const OnPublish = (typeContainer : TypeContainer) => {
    /*
    VerifyPassword(this.state.verificationPassword).then(result => {
        if (result.length == 0) {
            alert("Your verification password was inccorect");
        }
        else {
*/
    var timeStamp = new Date().toISOString();

    var UiJsx = [];
    UiJsx.push(<Alert color="success">
        <h4>Upload Progress</h4>
        <Progress striped color="success" value="0" />
    </Alert>);
    typeContainer.setState({ publishDisplay: UiJsx });

    Publish(typeContainer, timeStamp)
    //}
    //});
}

export const Publish = (typeContainer, timeStamp) => {
    var changes = typeContainer.props.context.changes;

    PublishChanges(changes, timeStamp).then(result => {
        if (result.length == 0) {
            // alert("There was an error publishing ",index);
            GetPublishJsx(typeContainer);
            return;
        }
        else {
            GetPublishJsx(typeContainer);
            alert("Changes Successfully Published!");
            typeContainer.props.context.ClearChanges();
        }
    });
}