import Select from 'react-select';
import React from 'react';
import {IsInt, IsFloat} from "./../../utilities/Utilities";
//import { optionsToTrigger } from 'firebase-functions/lib/cloud-functions';

import Context from './../../models/Context'

interface Props
{
	label: string,
	depth: Array<string>,
    value: number,
    options:Array<any>,
	context: Context //you will need a context object eventually
}

interface Option {
    name?: string | undefined;
    value: number;
}

export const EnumFieldComponent:React.FC<Props> = (props) => {
	
    const [value, UpdateValue] = React.useState(props.value);
    
    var OnValueChanged = (option: Option) => {
        UpdateValue(option.value);
        props.context.UpdateContent(option.value, props.depth);
    }

    var GetSelectedOption = () => {

        let option = props.options[0];
        for(let index in props.options)
        {
            if (props.options[index].value == value)
            return props.options[index];
        }

        return option;
    }

	return (
		<div className="form-inline">
                <p>{props.label}</p>

                <Select 
                    options={props.options}
                    className="form-control-small"
                    onChange={OnValueChanged}
                    
                    // value = {this.state.value} 
                    value = {GetSelectedOption()} 
                />
            </div>
	);
}