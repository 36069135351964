
//notations
//source - the content id that is being linked to
//link - the depth to the content that has selected the source id

/* link table structure
    {
        sourceId1:{
            contentType1:{
                linkedContentId1:[path1,path2,path3]
            }
        }
    }

    ===inverse link table structure====
    {
        serializeFullLink:sourceId,
        serializeFullLink2: soruceId2
    }


*/

function AddLink(linkTable, inverseLinkTable, sourceId, link) {

    var sourceIdKey = JSON.stringify(sourceId);

    var contentType = link.contentType;
    var linkContentId = link.id;
    var path = JSON.stringify(link.path);

    var serializedLink = JSON.stringify(link);

    if (!(sourceIdKey in linkTable))
        linkTable[sourceIdKey] = {}

    if (!(contentType in linkTable[sourceIdKey]))
        linkTable[sourceIdKey][contentType] = {};

    if (!(linkContentId in linkTable[sourceIdKey][contentType]))
        linkTable[sourceIdKey][contentType][linkContentId] = [];

    //add to link table
    linkTable[sourceIdKey][contentType][linkContentId].push(path);

    //add to inverse table
    inverseLinkTable[serializedLink] = sourceIdKey;
}

function RemoveLink(linkTable, inverseLinkTable, link) {

    var sourceId;

    var contentType = link.contentType;
    var linkContentId = link.id;
    var path = JSON.stringify(link.path);

    var serializedLink = JSON.stringify(link);

    //remove from inverse link table
    if (serializedLink in inverseLinkTable) {
        sourceId = inverseLinkTable[serializedLink];
        delete inverseLinkTable[serializedLink];
    }
    //case there is nothing to remove
    else
        return;

    //remove from link table
    if ((sourceId in linkTable) && (contentType in linkTable[sourceId]) && (linkContentId in linkTable[sourceId][contentType])) {
        var paths = linkTable[sourceId][contentType][linkContentId];

        for (var index in paths) {
            if (paths[index] == path) {
                paths = paths.splice(index, 1);
                break;
            }
        }
    }
}


const LinkingTableReducer = (state = {
    linkTable: {},
    inverseLinkTable: {},
    isDirty: false,
}, action) => {

    switch (action.type) {
        case "ADD_LINK":

            var linkTable = state.linkTable;
            var inverseLinkTable = state.inverseLinkTable;

            var sourceId = JSON.stringify(action.payload.sourceId); //the id being linked to //ex {value: 0, type: "inventory"}
            var link = action.payload.link; //the depth of the item using the link ex. ["Playables",0,"spawns",2];

            RemoveLink(linkTable, inverseLinkTable, link);
            AddLink(linkTable, inverseLinkTable, sourceId, link);

            //console.log("link info, ",linkTable," , ",inverseLinkTable);

            state = {
                ...state,
                linkTable: linkTable,
                inverseLinkTable: inverseLinkTable,
                isDirty: true
            };

            break;

        //case a link is removed by the pointer
        case "REMOVE_LINK":

            var linkTable = state.linkTable;
            var inverseLinkTable = state.inverseLinkTable;

            var link = action.payload.link;

            RemoveLink(linkTable, inverseLinkTable, link);

            state = {
                ...state,
                linkTable: linkTable,
                inverseLinkTable: inverseLinkTable,
                isDirty: true
            };

            break;


        case "REMOVE_SOURCE":

            var linkTable = state.linkTable;
            var inverseLinkTable = state.inverseLinkTable;

            var sourceId = action.payload.sourceId
            var serializedSourceId = JSON.stringify(sourceId);

            //remove all links from table
            delete linkTable[serializedSourceId];

            //no need to remove inverse links 

            state = {
                ...state,
                linkTable: linkTable,
                isDirty: true
            };

            break;

        //TODO implement this
        //case we delete a piece of content that links to a source
        case "REMOVE_LINK_CONTENT":

            var linkTable = state.linkTable;
            var inverseLinkTable = state.inverseLinkTable;

            var linkContentId = action.payload.linkContentId;
            var contentMode = action.payload.contentMode;

            for(var index in inverseLinkTable)
            {
                var inverseLink = JSON.parse(index);

                //case we find a link that must be removed
                if ((JSON.stringify(inverseLink.id) == JSON.stringify(linkContentId))&&(contentMode == inverseLink.contentMode))
                    RemoveLink(linkTable, inverseLinkTable, inverseLink)                
            }


            state = {
                ...state,
                linkTable: linkTable,
                inverseLinkTable: inverseLinkTable,
                isDirty: true
            };

            break;

    }
    return state;
};

export default LinkingTableReducer;