import React from "react";
import PropTypes from 'prop-types';
import Context from './../../models/Context'

interface Props
{
	label: string,
	depth: Array<string>,
    value: string,
	context: Context //you will need a context object eventually
}

export const DateFieldComponent:React.FC<Props> = (props) => {
    const [value, UpdateValue] = React.useState(new Date(props.value));
    const [displayValue, UpdateDisplayValue] = React.useState(value.toISOString().split('T')[0])

    var UpdateDate = (event : React.ChangeEvent<HTMLInputElement>) => {
        var newValue : Date = value;
        
        var dateElements = event.target.value.split('-');
        newValue.setFullYear(parseInt(dateElements[0]),(parseInt(dateElements[1])-1),parseInt(dateElements[2]));
        newValue.setHours(0,0,0,0);
        UpdateValue(newValue);
        UpdateDisplayValue(newValue.toISOString().split('T')[0]);

        props.context.UpdateContent(newValue,props.depth);
    }

    return (
        <span className ="form-inline form-inline-custom">
            <p>{props.label}</p>
            <input type="date" onChange={UpdateDate} value={displayValue} min="2018-01-01" max="2025-12-31"/>
        </span>
    );
}