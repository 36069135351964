import React, { useState } from 'react';
import { InputGroupText, Input } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Context from './../../models/Context'

const rgbHex = require('rgb-hex');
const hexRgb = require('hex-rgb');
//NOTES: this is broken because props.value, value and new value are all aliased

interface Color {
    r: number,
    b: number,
    g: number
}

interface Props {
	label: string,
	depth: Array<string>,
	value: Color,
	context: Context //you will need a context object eventually
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));

const View = (props) => {

    const classes = useStyles();
    return (
        <Grid container direction="row" alignItems="center" spacing={1} style={{ padding: 10 }}>
            <Grid item xs={1}>
                {props.label}
            </Grid>
            <Grid item xs={1}>
                {props.hexCode}
            </Grid>
            <Grid item xs={1}>
                {props.color}
            </Grid>
        </Grid>
    );
}

//you can break down props into its components if that is your coding style
export const ColorFieldComponent: React.FC<Props> = (props) => {

    var [value, UpdateValue] = useState(props.value);
    var [hexColor, UpdateHexColor] = useState(rgbHex(Math.round(props.value.r * 255), Math.round(props.value.g * 255), Math.round(props.value.b * 255)));
    var [newHexColor, UpdateNewHexColor] = useState(rgbHex(Math.round(props.value.r * 255), Math.round(props.value.g * 255), Math.round(props.value.b * 255)));
    
    var IsRgb = (rgb: string) => {
        if (rgb.length != 6)
            return false;

        var test = parseInt(rgb, 16);
        var compare = test.toString(16);
        while(compare.length < rgb.length) { // Adding leading zeroes lost in parsing
            compare = "0" + compare;
        }
        return (compare === rgb)
    }

    var OnUpdateHexColor = (value) => {
        UpdateNewHexColor(value);
    }

    var UpdateState = () => {
        if (!IsRgb(newHexColor)) {
            alert(newHexColor + " is not a valid hex value");
            UpdateNewHexColor(hexColor);
            return;
        }

        var rgb = hexRgb(newHexColor);
        var newValue = value;

        newValue.r = rgb.red / 255;
        newValue.g = rgb.green / 255;
        newValue.b = rgb.blue / 255;

        UpdateHexColor(newHexColor);
        props.context.UpdateContent(newValue, props.depth);
    }
    
    const hexCode = <input className="form-control-small" type="text" value={newHexColor} onChange={(event) => OnUpdateHexColor(event.target.value)} onBlur={UpdateState} />
    const colorSetter = <input type="color" id="head" name="color" value={'#' + hexColor} onChange={(event) => {
        var value = event.target.value.slice(1, event.target.value.length);
        UpdateNewHexColor(value);
        UpdateState();
    } } />

	return (
        <View hexCode = {hexCode} color ={colorSetter} label = {props.label}/>
	);

}