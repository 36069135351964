//import { event } from 'firebase-functions/lib/providers/analytics';
import React, { useState, Fragment } from 'react';
import { InputGroupText, Input } from 'reactstrap';
import Context from './../../models/Context'

interface Props {
	label: string,
	depth: Array<string>,
	value: boolean,
	context: Context //you will need a context object eventually
}

//you can break down props into its components if that is your coding style
export const BoolFieldComponent: React.FC<Props> = (props) => {

	var [value, UpdateValue] = useState(props.value);

	var OnUpdateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		var value = event.target.checked;
		UpdateValue(value);
		props.context.UpdateContent(value, props.depth);
	}

	var UiJsx = (
		<Fragment>
			<InputGroupText>
				<Input addon type="checkbox" aria-label="Checkbox for following text input" value={""+value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => OnUpdateValue(event)} />
			</InputGroupText>
		</Fragment>
	);

	if (value)
		var UiJsx = <input type="checkbox" onChange={(event: React.ChangeEvent<HTMLInputElement>) => OnUpdateValue(event)} checked />;

	return (
		<div className="form-inline">
			<p>
				{props.label}
			</p>

			{UiJsx}
		</div>
	);

}