import React, { useEffect } from "react";
var mongoose = require("mongoose");
import Select from 'react-select';
import {PullBackups,RevertToBackup} from './../../utilities/web interface utilities/WebApi';
import {Button,Alert} from "reactstrap";
import VerificationComponent from './VerificationComponent'
import SchemaCache from './../../utilities/Cache Utilities/SchemaCache';
import ContentCache from './../../utilities/Cache Utilities/ContentCache';
import ResourceCache from './../../utilities/Cache Utilities/ResourceCache';
import SpawnInfoCache from './../../utilities/Cache Utilities/SpawnInfoCache';

import Context from './../../models/Context'

interface Props
{
	context: Context //you will need a context object eventually
}

interface Option
{
    value: Object,
}

export const BackupSelectorComponent:React.FC<Props> = (props) => {
    var optionsStart : Array<Option> = [];
    const [options, UpdateOptions] = React.useState(optionsStart);
    var selectedStart : Option = {value: 0}
    const [selected, UpdateSelected] = React.useState(selectedStart);

    var InitializeOptions = () =>
    { 
        var newOptions = [];

        PullBackups().then((backups)=>{

            backups.forEach(element => {
                newOptions.push({label:element,value:element});
            });

            if (newOptions.length>0)
            {
                //alert("there are no older revisions")
                UpdateOptions(newOptions);
                UpdateSelected(newOptions[0]);
            }
               
        })
    }

    useEffect(() => {
        InitializeOptions()
    }, [])

    var Revert = () =>
    {
        RevertToBackup(selected.value).then((result)=>
        {
            props.context.WipeContent();
            new SchemaCache().WipeCache();
            new ContentCache().WipeCache();
            new ResourceCache().WipeCache();
            new SpawnInfoCache().WipeCache();
            alert("Successfully Reverted To "+selected.value.toString());
        });

    }

    var OnChangeSelected = (event) =>
    {
        UpdateSelected(event)
    }

    if (options.length > 0)
        {
            return(<div>

                <h4>Revert to older content!</h4>
    
                  <Select
                    value = {selected}
                    className="form-control-main"
                    onChange={OnChangeSelected}
                    options={options}/>
                    <br/>
                    <VerificationComponent key={ new mongoose.Types.ObjectId().toString()} buttonColor= "danger" buttonText="Revert to older save" verificationText="Are you sure you want to revert the content?" callback = {Revert}/>
                </div>);
        }
        else 
        {
            return (<h4>Loading Revisions</h4>)
        }
}