import React, { useState } from 'react';
import Context from './../../models/Context'

interface Props
{
	label: string,
	depth: Array<string>,
    value: string,
	context: Context //you will need a context object eventually
}

export const StringFieldComponent:React.FC<Props> = (props) => {
	
    const [value, UpdateValue] = React.useState(props.value);
    

	var OnUpdateValue = (event : React.ChangeEvent<HTMLInputElement>)=>
	{
        UpdateValue(event.target.value)
	}

	var UpdateState = () => 
    {
        
        UpdateValue(value)
        props.context.UpdateContent(value,props.depth);
    }

	return (
		<span className ="form-inline form-inline-custom">
                <p>{props.label}</p>
                <input className="form-control" type="text" value={value} onChange={(event) => OnUpdateValue(event)} onBlur={UpdateState} />
            </span>
	);
}