import PropTypes from 'prop-types';

import React, {useEffect} from "react";
import Select from 'react-select';
import { Container, Row, Col, Button, Label } from "reactstrap";
import { DecimalFieldComponent } from "./../../components/base types/DecimalFieldComponent";
import { StringFieldComponent } from "./../../components/base types/StringFieldComponent";
import { GetJSXComponentFromType } from "./../../utilities/website building utilities/SchemaBuilder";
import { BuildContent } from "./../../utilities/website building utilities/ContentBuilder";

import { ArrayFieldComponent } from "./../../components/base types/ArrayFieldComponent";
import { ExtraSchemaComponent } from "./../../components/ExtraSchemaComponent";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AnyIfEmpty, Options } from 'react-redux';

import DropDownOption from "./../../models/DropDownOption";

import Context from './../../models/Context'

interface Props
{
	label: string,
	depth: Array<string>,
    value: any,
    options: Array<string>,
    schemaType: string,
    schema: Schema,
    context: Context //you will need a context object eventually
    keysToIgnore: Array<string>,
}

interface Schema {
    type: string,
}

interface Component {
    component: JSX.Element
}


export const SelectableDictionaryFieldComponent:React.FC<Props> = (props) => {

    var UiJsxStart : JSX.Element[] = [];
    const [UiJsx, UpdateUiJsx] = React.useState(UiJsxStart);
    const [value, UpdateValue] = React.useState(props.value);
    var selectionStart = props.options.length > 0 ? {label: props.options[0], value: props.options[0]} : null;
    const [selection, UpdateSelection] = React.useState(selectionStart);
    var removeSelectStart : any = {label:"", value:null}
    const [removeSelection, UpdateRemoveSelection] = React.useState(removeSelectStart)

    useEffect(() => {
        GenerateArray(value);
    }, [])

    var GenerateArray = (value) => {

        var UiJsx : JSX.Element[] = [];

        var component = GetJSXComponentFromType(props.schema.type.replace("AdminConsoleDictionaryAttribute.", ""));
        var arrayType : JSX.Element = component["component"] as unknown as JSX.Element; 

        var key = String(props.depth);
        for(var field in value) {
            key += field;
        }
        
        UiJsx.push(<ArrayFieldComponent key={key} label={props.label} arrayType={arrayType} value={value} schemaType={props.schemaType} context={props.context} depth={props.depth} keysToIgnore={props.keysToIgnore} onReorder={OnReorder}/>);

        UpdateUiJsx(UiJsx);
    }

    var OnAdd = () => {
        var additionalContent = BuildContent(props.schema, props.schemaType, props.context, props.depth);

        var val = value;

        if (val == null || val == 0) {
            val = {}
        }
        
        val[selection.label] = additionalContent;

        props.context.UpdateContent(val, props.depth);

        var options = GetOptions(val);

        if (options.length > 0) {
           UpdateValue(val);
           UpdateSelection(options[0]);
           GenerateArray(val);
        }
        else {
            GenerateArray(value);
        }
    }

    var OnReorder = (newValue) => {
        props.context.UpdateContent(newValue, props.depth);
        UpdateValue(newValue)
        GenerateArray(newValue);
    }

    var GetOptions = (lastValue = null) => {
       
        var val = value;
        
        if (lastValue != null)
            val = lastValue;
        
        var options = [];

        if (val == 0) {
            val = {}
        }

        for (var index in props.options) {
            var option = props.options[index];
            
            if (!(option in val)) {
                
                options.push({ label: option, value: option })
            }
        }

        return options;
    }


    var OnRemove = () => {

        var index = removeSelection.label;
        delete value[index];
        props.context.UpdateContent(value, props.depth);
        var options = Object.keys(value);

        if (options.length > 0) {
            UpdateRemoveSelection({label: options[0], value: options[0]});
        }
        GenerateArray(value)
    }


    var _GetAddOptions = () => {
        var UiJsx = [];

        if (GetOptions().length > 0) {
            UiJsx.push(
                <Select
                    value={selection}
                    className="form-control-main"
                    onChange={UpdateSelection}
                    options={GetOptions()} />);
        }

        return UiJsx;

    }

    var _GetRemoveOptions = () => {
        var UiJsx : JSX.Element[] = [];
        var labels = Object.keys(value);

        if (labels.length > 0) {
            var options = [];
            for (var index in labels) {
                options.push({ label: labels[index], value: labels[index] })
            }

            if ((removeSelection == 0 || removeSelection.value == null) && options.length > 0) {    
                UpdateRemoveSelection(options[0]);
            }

            UiJsx.push(
                <Select
                    value={removeSelection}
                    className="form-control-main"
                    onChange={UpdateRemoveSelection}
                    options={options} />);
        }

        return UiJsx;
    }

    const addSelection = _GetAddOptions();

    const addButton = <Button color="success" onClick={() => OnAdd()}>Add</Button>

    const removeSelectionUiJsx = _GetRemoveOptions();

    const removeButton = <Button color="danger" onClick={() => OnRemove()}>Remove</Button>

    return <View addSelection={addSelection} addButton={addButton} removeSelection={removeSelectionUiJsx} removeButton={removeButton} content={UiJsx} label={props.label} />;
}



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));



const View = (props) => {

    const classes = useStyles();
    return (
        <div className={classes.root} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <ExpansionPanel >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className={classes.heading}>{props.label}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails >
                    <div style={{ width: "100%" }}>
                        <Grid container direction="row" spacing={3} style={{ padding: 10 }}>
                            <Grid item xs={12}>
                                {props.content}
                            </Grid>
                            <Grid item xs={2}>
                                {props.addSelection}
                            </Grid>
                            <Grid item xs={2}>
                                {props.addButton}
                            </Grid>

                            <Grid item xs={2}>
                                {props.removeSelection}
                            </Grid>
                            <Grid item xs={2}>
                                {props.removeButton}
                            </Grid>
                        </Grid></div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}