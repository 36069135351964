import React from "react";
import Select from 'react-select';
import style from './../../styles/Main.css';
import PropTypes from 'prop-types';
import SchemaCache from './../../utilities/Cache Utilities/SchemaCache';
import { GetGenericType } from "./../../utilities/Utilities";

export class FrontEndDefinitionComponent extends React.Component {

    constructor(props) {
        super();

        this.schemaCache = new SchemaCache();

        this.state = {
            value: props.value,
            options:[],
        }

        this.GetOptions(props.schemaType,props.genericType);
    }

    OnValueChanged = (result) => {

        this.setState({value: result.value})
        this.props.context.UpdateContent(result.value, this.props.depth);
    }

    async GetOptions(schemaTypes,genericType)
    {
        var options = [];

        for(var index in schemaTypes)
        {
            var schemaType = schemaTypes[index]
            var frontEndDefinition = await this.schemaCache.GetCacheValue(schemaType);

            for(var index in frontEndDefinition)
            {
                var value = frontEndDefinition[index].value;
                var type = GetGenericType(frontEndDefinition[index].type);
                if ((genericType.length == 0)||(genericType == type))
                {
                    var label = value.label.replace(/_/gi, ' ');
                    options.push({ label: label, value: value});
                }  
            }
        }

        this.setState({options:options});
    }

    render() {
        return (
            <div className="form-inline">
                <p>{this.props.label}</p>
                <Select 
                    className="form-control-small"
                    onChange={this.OnValueChanged}
                    options={this.state.options}
                    value = {{ label: this.state.value.label, value: this.state.value}} 
                />
            </div>
        );
    }
}

FrontEndDefinitionComponent.propTypes = {
    label: PropTypes.string.isRequired,
    depth: PropTypes.array.isRequired,
    value: PropTypes.object.isRequired,
    schemaType:PropTypes.array.isRequired,
    genericType:PropTypes.string.isRequired,
    context:PropTypes.object.isRequired
};