

//TODO remove this and replace with an ADD / Remove Content
export const CONTENT_SET_CONTENT = "CONTENT_SET_CONTENT";

export interface SetContent
{
	type:typeof CONTENT_SET_CONTENT
    content: any,
}

export const SetContent = (content : any):SetContent => ({
	type: "CONTENT_SET_CONTENT",
    content: content
});

//used when adding a new piece of content, TODO rename to add primary content
export const CONTENT_ADD_CONTENT = "CONTENT_ADD_CONTENT";

export interface AddContent
{
	type:typeof CONTENT_ADD_CONTENT
    content: any,
    subSelection: any,
}

export const AddContent = (content : any, subSelection : any):AddContent => ({
	type: "CONTENT_ADD_CONTENT",
    content: content,
    subSelection: subSelection
});

export const CONTENT_REMOVE_SELECTED_CONTENT = "CONTENT_REMOVE_SELECTED_CONTENT";

export interface RemoveSelectedContent
{
	type:typeof CONTENT_REMOVE_SELECTED_CONTENT
}

export const RemoveSelectedContent = ():RemoveSelectedContent => ({
	type: "CONTENT_REMOVE_SELECTED_CONTENT",
});

//TODO remove this and replace with an ADD / Remove Content
export const CONTENT_SET_SECONDARY_CONTENT = "CONTENT_SET_SECONDARY_CONTENT";

export interface SetSecondaryContent
{
	type:typeof CONTENT_SET_SECONDARY_CONTENT
    content: {key: string, value: any},
}

export const SetSecondaryContent = (content: {key: string, value: any}):SetSecondaryContent => ({
	type: "CONTENT_SET_SECONDARY_CONTENT",
    content
});

export const CONTENT_UPDATE_CONTENT = "CONTENT_UPDATE_CONTENT";

export interface UpdateContent
{
	type:typeof CONTENT_UPDATE_CONTENT
    content: any,
    depth: Array<string>
}

export const UpdateContent = (newContent : any, depth : Array<string>):UpdateContent => ({
	type: "CONTENT_UPDATE_CONTENT",
    content: newContent,
    depth: depth
});

//set secondary content

//update secondarycontent
export const CONTENT_CHANGE_SUBSELECTION = "CONTENT_CHANGE_SUBSELECTION";

export interface ChangeSubSelection
{
	type:typeof CONTENT_CHANGE_SUBSELECTION
    content: {index : any},
}

export const ChangeSubSelection = (content: {index : any}):ChangeSubSelection => ({
	type: "CONTENT_CHANGE_SUBSELECTION",
	content
});


export const CONTENT_CHANGE_MODE = "CONTENT_CHANGE_MODE";

export interface ChangeMode
{
	type:typeof CONTENT_CHANGE_MODE
    content: {mode: any, subSelection: any, typeInfo: any},
}

export const ChangeMode = (newContent: {mode: any, subSelection: any, typeInfo: any}):ChangeMode => ({
	type: "CONTENT_CHANGE_MODE",
	content: newContent
});


export const CONTENT_UPDATE_SECONDARY_CONTENT = "CONTENT_UPDATE_SECONDARY_CONTENT";

export interface UpdateSecondaryContent
{
	type:typeof CONTENT_UPDATE_SECONDARY_CONTENT
    content: {key:string,value:any,depth:Array<string>},
}

export const UpdateSecondaryContent = (id : string, newContent : any ,depth : Array<string>):UpdateSecondaryContent => ({
	type: "CONTENT_UPDATE_SECONDARY_CONTENT",
	content: {key:id,value:newContent,depth:depth},
});

export const CONTENT_REMOVE_SECONDARY_CONTENT = "CONTENT_REMOVE_SECONDARY_CONTENT";

export interface RemoveSecondaryContent
{
	type:typeof CONTENT_REMOVE_SECONDARY_CONTENT
    content: {key:string,value:any,depth:Array<string>},
}

export const RemoveSecondaryContent = (id : string, content : any ,depth : Array<string>):RemoveSecondaryContent => ({
	type: "CONTENT_REMOVE_SECONDARY_CONTENT",
	content: {key:id,value:content,depth:depth},
});


export const CONTENT_WIPE_CONTENT = "CONTENT_WIPE_CONTENT";

export interface WipeContent
{
	type:typeof CONTENT_WIPE_CONTENT
    content: {}
}

export const WipeContent = ():WipeContent => ({
	type: "CONTENT_WIPE_CONTENT",
	content: {}
});

export const CONTENT_SET_LOCALIZATION = "CONTENT_SET_LOCALIZATION";

export interface SetLocalization
{
	type:typeof CONTENT_SET_LOCALIZATION
	content: {key: string, value:any, language:any}
}

export const SetLocalization = (content: {key: string, value:any, language:any}):SetLocalization => ({
	type: "CONTENT_SET_LOCALIZATION",
	content
});

export const CONTENT_UPDATE_LOCALIZATION = "CONTENT_UPDATE_LOCALIZATION";

export interface UpdateLocalization
{
	type:typeof CONTENT_UPDATE_LOCALIZATION
	content: {key: string, value:any, language:any}
}

export const UpdateLocalization = (content: {key: string, value:any, language:any}):UpdateLocalization => ({
	type: "CONTENT_UPDATE_LOCALIZATION",
	content: content
});

export const CONTENT_DELETE_LOCALIZATION = "CONTENT_DELETE_LOCALIZATION";

export interface DeleteLocalization
{
	type:typeof CONTENT_DELETE_LOCALIZATION
	content: {key:string,language:any}
}

export const DeleteLocalization = (content: {key:string,language:any}):DeleteLocalization => ({
	type: "CONTENT_DELETE_LOCALIZATION",
	content: content
});

export const CONTENT_UPDATE_TOGGLE_STATE = "CONTENT_UPDATE_TOGGLE_STATE";

export interface UpdateToggleState
{
	type:typeof CONTENT_UPDATE_TOGGLE_STATE
	content: {key:string, value:any}
}

export const UpdateToggleState = (content: {key:string, value:any}):UpdateToggleState => ({
	type: "CONTENT_UPDATE_TOGGLE_STATE",
	content: content
});

export const ADD_CONTENT_LISTENER = "ADD_CONTENT_LISTENER";

export interface AddContentListener
{
	type:typeof ADD_CONTENT_LISTENER
	content: {listener:any}
}

export const AddContentListener = (content: {listener:any}):AddContentListener => ({
	type: "ADD_CONTENT_LISTENER",
	content: content
});

export const REMOVE_CONTENT_LISTENER = "REMOVE_CONTENT_LISTENER";

export interface RemoveContentListener
{
	type:typeof REMOVE_CONTENT_LISTENER
	content: {listener:any}
}

export const RemoveContentListener = (content: {listener:any}):RemoveContentListener => ({
	type: "REMOVE_CONTENT_LISTENER",
	content: content
});

export const CLEAR_CHANGES = "CLEAR_CHANGES";

export interface ClearChanges
{
	type:typeof CLEAR_CHANGES
}

export const ClearChanges = ():ClearChanges => ({
	type: "CLEAR_CHANGES",
});