
import { BuildComponentFromSchema } from "./../utilities/website building utilities/SchemaBuilder";
import React, { useEffect } from "react";
import PropTypes from 'prop-types';

//utilities
import SchemaCache from './../utilities/Cache Utilities/SchemaCache';
import TypeSupporter from './../utilities/website building utilities/TypeSupporter';

import Context from './../models/Context'

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

interface Props
{
    value: any,
    label: string,
    depth: Array<string>,
    keysToIgnore: Array<string>,
    schemaType: string,
	context: Context //you will need a context object eventually
}

export const ExtraSchemaComponent:React.FC<Props> = (props) => {

    const [extraSchema, UpdateExtraSchema] = React.useState("");

    useEffect(() => {
        UpdateExtraSchema("");
        _LoadSchema();
    }, [props.schemaType])

    var _LoadSchema = async () => {

        try {
            if (new TypeSupporter().IsTypeSupported(props.schemaType))
            {
                UpdateExtraSchema(props.schemaType);
            }
            else
            {
                var schemaCache = new SchemaCache();

                var extraSchema = await schemaCache.GetCacheValue(props.schemaType);

                if ("attribute" in extraSchema)
                    extraSchema.type = props.schemaType;

                //case we pull a single schema
                if (extraSchema.properties != null) {

                    UpdateExtraSchema(extraSchema);
                }
                //case we pull an array of schema
                else {

                    UpdateExtraSchema(JSON.parse(extraSchema[props.value.type]));
                }
            }
        } catch (error){
            console.log("error in extra schema component ", error, " , ", props.schemaType, " key: ",props.label);
        }
    }

    if (extraSchema == "") {
        return (<div>
            <p>Loading Component...</p>
        </div>);
    }

    else {

        var content = BuildComponentFromSchema(extraSchema, props.value, props.context, props.depth, props.keysToIgnore);
        return <View label={props.label} depth={props.depth} content={content} />
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));


const View = (props) => {

    const classes = useStyles();
    return (
        <div className={classes.root} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <ExpansionPanel >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className={classes.heading}>{props.label}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails >
                    <div style={{ width: "100%" }}>
                        {props.content}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}