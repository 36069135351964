import React, {useEffect} from "react";
//import style from './../styles/Main.css';
import TypeContainer, {TypeContainerProps} from "./TypeContainer";
import { Button} from "reactstrap";
import UniqueIdProvider from './../utilities/web interface utilities/Providers/UniqueIdProvider'
import PropTypes from 'prop-types';

var snake = require('to-snake-case');
var upperCamelCase = require('uppercamelcase');

export class LocalizationContainer extends TypeContainer {

    constructor(props : TypeContainerProps) {
        super(props);

        console.log("localizations ");

        this.state = {
            ...this.state,
            locIds: props.context.content[props.context.subSelection].localizations,
            locId: "",
            locValue: ""
        }
    }

    OnChange(index,event)
    {
        var key = index;
        var value = event.target.value;
        var language = this.props.context.content[this.props.context.subSelection].title.code;

        var locIds = this.state.locIds;
        locIds[key]= value;

        this.setState({locIds:locIds})
        this.props.context.UpdateLocalization(key,value,language)
    }
    
    GenerateContent()
    {
        var content =[];

        var unordered = this.props.context.content[this.props.context.subSelection].localizations//this.state.locIds

        const ordered = {};
        Object.keys(unordered).sort().forEach(function(key) {
          ordered[key] = unordered[key];
        });

        for (var index in ordered)
        {
            var style : any = {backgroundColor:'#f2f2f2',padding:30};

            if (content.length%2 ==1)
                style = {padding:30}

            content.push(<span className ="form-inline form-inline-custom" style={style}><p>{index+" : "}</p>
            <span  className="resizable-input" >
            <textarea className="form-control"style={{width:500}} type="text" value={this.state.locIds[index]} onChange={this.OnChange.bind(this,index)}/>
            </span>
            <Button color="danger" onClick ={this.OnDelete.bind(this,index)} >Delete</Button>
            </span>);
        }

        return content;
    }

    OnDelete(index)
    {
        var key = index;
        var language = this.props.context.content[this.props.context.subSelection].title.code;

        var locIds = this.state.locIds;
        delete locIds[index];

        this.props.context.DeleteLocalization(key,language)
        this.setState({locIds:locIds});
    }

    OnAdd()
    {
        var locId = this.state.locIds;

        if ((this.state.locId.length == 0)||(this.state.locValue.length == 0))
        {
            alert("you must fill in values for new loc id");
        }
        else if (Object.keys(locId).includes(this.state.locId))
        {
            alert("a loc id with that code already exists");
        }
        else
        {
            var key = snake(this.state.locId);
            var value = this.state.locValue;
            var language = this.props.context.content[this.props.context.subSelection].title.code;

            var locIds = this.state.locIds;
            locIds[key]= value;

            this.setState({locIds:locIds,locId:"",locValue:""});
            this.props.context.UpdateLocalization(key,value,language)
        }
    }

    async CreateElement(type)
    {   
        var errorMsg = this.IsValidElement(this.state.newDisplayName,type);

        if (errorMsg != "")
        {
            alert(errorMsg);
            return;
        }

        var idProvider = new UniqueIdProvider();

        var idNumber = await idProvider.GetUniqueId(upperCamelCase(type),this.props.context.content);

        //idProvider.GetUniqueId(upperCamelCase(type),this.props.context.content).then((idNumber)=>{

                var content : any = {};

                var title = "language_set_"+snake(this.state.newDisplayName)+"_title";

                content["admin_console_display_name"] = this.state.newDisplayName;
                content["id"] = {value:idNumber,type:"language_localization_set"}
                content["localizations"]= {}
                content["title"] ={code:title}
                content["content"]={content:""}

                this.props.context.content.push(content);

                this.props.context.SetContent(this.props.context.content);

                await this.props.context.ChangeSubSelection(this.state.options.length);
                this.componentDidMount();
                this.setState({defaultValue: {label: snake(this.state.newDisplayName), value: snake(this.state.newDisplayName)}}) 

                /*
                this.setState({selectedOption: this.state.options.length},()=>{
                    this.componentDidMount();
                    //change default here 
                    this.setState({defaultValue: {label: snake(this.state.newDisplayName), value: snake(this.state.newDisplayName)}}) 
                });
                */
        //})
    }

    render() {

        if(this.state.locId === undefined || this.state.locIds === undefined || this.state.locValue === undefined) {
            return <div>Loading..</div>
        } 
        
        return (
            <div className="container-main">

                {this.GetSelectionJSX("Select A " + this.state.containerType.replace(/_/gi, ' '), () => { this.setState({ locIds: this.props.context.content[this.props.context.subSelection].localizations }) })}

                <br /><hr />
                {this.GetCreateJsx("Create New " + this.state.containerType.replace(/_/gi, ' '), () => this.CreateElement(this.state.containerType))}

                <br /><hr />
                <span className="form-inline form-inline-custom">
                    <p>LocId</p>
                    <input className="form-control" type="text" placeholder="Enter Loc Code" id="code" onChange={(input) => this.setState({ locId: input.target.value })} />
                    <input className="form-control" type="text" placeholder="text" id="Value" onChange={(input) => this.setState({ locValue: input.target.value })} />
                    <Button color="success" onClick={() => this.OnAdd()}> Add LocId To Set</Button>
                </span>

                <br /><hr />
                {this.GenerateContent()}

                <br /><br />
                {this.GetDeleteJsx("Delete " + this.state.containerType.replace(/_/gi, ' '))}

                <br /><br /><hr />
                {this.state.publishDisplay}
            </div>
        );
        
       
    }
}
