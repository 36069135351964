import {createStore,combineReducers, applyMiddleware} from "redux";
import {createLogger} from "redux-logger";

import thunk from "redux-thunk";
import promise from "redux-promise-middleware";

import content from "./reducers/ContentReducer";
import LinkTable from './reducers/LinkingTableReducer';

//import user from "./reducers/userReducer";
export default createStore(
    combineReducers({
        content,LinkTable
    }),
    {},
//applyMiddleware(thunk,promise()));
applyMiddleware(createLogger(),thunk,promise()));
