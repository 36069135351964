class ObjectManipulator
{
    DeepCopy(inputObject)
    {
        var outputObject = {};

        for(var index in inputObject)
        {
            var field = inputObject[index];

            if  (Array.isArray(field))
            {
                var arrayField = [];
                field.forEach((arrayItem)=>{
                    arrayField.push(this.CopyField(arrayItem));
                })

                outputObject[index] = arrayField;
            }
            else
            {
                outputObject[index] = this.CopyField(field)
            }
        }

        return outputObject;
    }

    CopyField(field)
    {
        if (typeof(field) != "object") 
            return field;
        else
            return this.DeepCopy(field);
    }

    //returns object value at a given depth
    GetValueAtDepth(object,depth)
    {
        var objectCopy = this.DeepCopy(object)

        for(var index in depth)
        {
            var value = depth[index];
            objectCopy = objectCopy[value];
        }

        return objectCopy;
    }
}




export default ObjectManipulator;