
import {  PullSpawnInfoContent } from "./../web interface utilities/WebApi";


let instance = null;

class SpawnInfoCache {
    constructor() {
        if (!instance) {
            instance = this;
            this.cache = new Map();
            this.promiseCache = new Map();
            this.bannedList = [];
        }
    }

    WipeCache()
    {
        instance.cache = new Map();
        instance.promiseCache = new Map();
        instance.bannedList = [];
    }

    GetCacheValue(key)
    {
        if (instance.bannedList.includes(key))
        {
            console.log("return banned key");
            return new Promise(function(resolve,reject){resolve(undefined)});
        }
        
        if (!instance.cache.has(key))
            instance.cache.set(key, "");

        //case key is set 
        if (instance.cache.get(key) != "")
        {
            return new Promise(function(resolve,reject){resolve(instance.cache.get(key))});
        }
        //case we are already pulling this schema from the server
        else if (instance.promiseCache.has(key))
        {
            return instance.promiseCache.get(key);
        }
        //case we need to pull this schema from the server
        else
        {
            var request = PullSpawnInfoContent(key).then(function(result)
            {
                if (result != null)
                {
                    var value = result;
                    instance.cache.set(key, value);
                    return value;
                }
            
                else
                {
                    console.log("no content result for ",key);
                    //instance.bannedList.push(key);
                    instance.cache.delete(key);
                    return []
                }
            });

            instance.promiseCache.set(key,request);
            return request;
        }
    }
}


export default SpawnInfoCache;
