import React, { useEffect } from "react";

//components
import {ExtraSchemaComponent} from './../ExtraSchemaComponent';

//utilities
import SchemaCache from './../../utilities/Cache Utilities/SchemaCache'

import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import DropDownOption from "../../models/DropDownOption";

interface Props
{
	label: string,
	depth: Array<string>,
    value: any,
    schemaType: any,
    context: any //you will need a context object eventually
    keysToIgnore: Array<string>,
}

export const SpecifiedTypeComponent:React.FC<Props> = (props) => {
    
    const [value, UpdateValue] = React.useState(props.value);
    const [type, UpdateType] = React.useState(props.value.type);
    const [typeOptions, UpdateTypeOptions] = React.useState<DropDownOption<string>[]>([]);
    const [selectedTypeOption, UpdateSelectedTypeOption] = React.useState<DropDownOption<string>>({ label: "Loading options...", value: '0' });

	var PullTypeOptions = async (type) => {

        var specifiedTypes = await new SchemaCache().GetCacheValue(type);

        var typeOptions : DropDownOption<string>[] = [];
        var selectedTypeOption : DropDownOption<string> | null = null;

        for (var key in specifiedTypes) {

            var keyName = key.replace(/_/gi, ' ');
            //var value = JSON.parse(specifiedTypes[key]);

            typeOptions.push({ label: keyName, value: key });

            if (key == value.true_type) {
                selectedTypeOption = { label: keyName, value: key };
            }
        }

        if (selectedTypeOption == null)
         selectedTypeOption = typeOptions[0];

        await UpdateTypeOptions(typeOptions);
        await UpdateSelectedTypeOption(selectedTypeOption);
    }

    useEffect(() => {
        PullTypeOptions(type);
    }, [])

    var GetData = () =>
    {
        if(selectedTypeOption.value == '0')
            return [];
        else
        {
            var value = value;

            if (Object.keys(value).length == 0)
            {
                value["type"] = props.schemaType;
                value["true_type"] = selectedTypeOption.value;
            }    

            var label = selectedTypeOption.value+" data";

            return <ExtraSchemaComponent label={label} depth={props.depth} value={ value } keysToIgnore={props.keysToIgnore} schemaType={selectedTypeOption.value} context={props.context}/>;
        }
    }

    var ChangeType = (event) =>
    {
        var value={}
        value["type"] = props.schemaType;
        value["true_type"]= event.value;

        UpdateSelectedTypeOption(event);
        UpdateValue(value);
        props.context.UpdateContent(value, props.depth);
    }

	return (
        <span className="form-inline">
            <p>{props.label}</p>

            <Select
                className="form-control-id"
                options={typeOptions}
                onChange={(event)=>ChangeType(event)}
                value={selectedTypeOption}
            />
            <br/>
            { GetData()}
        </span>
    );
}