import React from "react";

import ContentCache from '../utilities/Cache Utilities/ContentCache';
import SchemaCache from '../utilities/Cache Utilities/SchemaCache';
import TypeContainer, {TypeContainerProps} from "./TypeContainer";
import { SpawnInfoFieldComponent } from "../components/static components/SpawnInfoFieldComponent";
import { Button } from "reactstrap";
import ComponentConstructor from "../utilities/website building utilities/ComponentConstructor";
import PropTypes from 'prop-types';
import * as mongoose from 'mongoose';
import { SpawnInfoTableComponent } from "../components/static components/SpawnInfoTableComponent";

interface State {
    spawnInfo: Array<JSX.Element>,
    spawnInfoType: string,
    requiresSpawnInfo: boolean
}

interface HasSpawnInfoContainerProps extends TypeContainerProps {
    additionalInfo: {requiresSpawnInfo: boolean},
    containerType: string,
    schema: Schema,
    context: any,
}

interface Schema {
    properties: Array<any>
}


export class HasSpawnInfoContainer extends TypeContainer {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            spawnInfo: [],
            spawnInfoType: props.containerType + "_spawn_info",
            requiresSpawnInfo: props.additionalInfo.requiresSpawnInfo
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.SetSpawnInfo();
    }

    SetSpawnInfo() {
        new SchemaCache().GetCacheValue("spawn_info_schema")
            .then(schema => {
                this.SetSpawnInfoHelper(schema);
            });
    }

    CreateSpawnInfo(pawn_id, schema) {
        var UiJsx = [];
        var key : any = new mongoose.Types.ObjectId();
        UiJsx.push(<SpawnInfoFieldComponent key={key} content={{}} schema={schema} depth={[pawn_id]} spawnInfoType={this.state.spawnInfoType} context={this.props.context} />)
        UiJsx.push(<Button color="danger" onClick={() => this.RemoveSpawnInfo(pawn_id, schema)}>Remove Spawn Info</Button>);
        this.setState({ spawnInfo: UiJsx });
    }

    RemoveSpawnInfo(pawn_id, schema) {
        var removalInfo = {};
        //removalInfo["spawn_id"]= {value:pawn_id,type:this.state.spawnInfoType};
        removalInfo["remove"] = { value: pawn_id, type: this.state.spawnInfoType };

        var UiJsx = [];
        this.props.context.RemoveSecondaryContent(pawn_id, removalInfo, [pawn_id]);
        UiJsx.push(<Button color="success" onClick={() => this.CreateSpawnInfo(pawn_id, schema)}>Add Spawn Info</Button>)
        this.setState({ spawnInfo: UiJsx });
    }


    SetSpawnInfoHelper(schema) {
        var UiJsx = [];

        if ((this.props.context.content.length >  this.props.context.subSelection) && (this.props.context.subSelection >= 0)) {
            var id:any = this.props.context.content[this.props.context.subSelection].id;

            if (typeof (id) == "object")
                id = id.value;

            if (typeof (this.props.context.secondaryContent[id]) != 'undefined') {

                UiJsx.push(<SpawnInfoFieldComponent key={id} content={this.props.context.secondaryContent[id]} schema={schema} depth={[id]} spawnInfoType={this.state.spawnInfoType} context={this.props.context} />);

                if (!this.state.requiresSpawnInfo)
                    UiJsx.push(<Button color="danger" onClick={() => this.RemoveSpawnInfo(id, schema)}>Remove Spawn Info</Button>);

                this.setState({ spawnInfo: UiJsx });
            }
            else {
                new ContentCache().GetCacheValue("SpawnInfos").then(results => {

                    var result : any = [];

                    for (var index in results) {
                        if ("remove" in results[index])
                            continue;

                        if ((parseInt(results[index].id.value) == parseInt(id)) && (results[index].id.type == this.state.spawnInfoType)) {
                            result = results[index];
                            break;
                        }
                    }

                    //case it is required
                    if ((result.length > 0) && (this.state.requiresSpawnInfo)) {
                        UiJsx.push(<SpawnInfoFieldComponent key={result.id.value} content={result} schema={schema} depth={[id]} spawnInfoType={this.state.spawnInfoType} context={this.props.context} />)
                        this.setState({ spawnInfo: UiJsx });
                    }
                    else if ((result.length == 0) && (this.state.requiresSpawnInfo)) {
                        var key : any = new mongoose.Types.ObjectId();
                        UiJsx.push(<SpawnInfoFieldComponent key={key} content={{}} schema={schema} depth={[id]} spawnInfoType={this.state.spawnInfoType} context={this.props.context} />)
                        this.setState({ spawnInfo: UiJsx });
                    }

                    //case it is not required and empty
                    else if ((result.length == 0) && (!this.state.requiresSpawnInfo)) {
                        UiJsx.push(<Button color="success" onClick={() => this.CreateSpawnInfo(id, schema)}>Add Spawn Info</Button>)
                        this.setState({ spawnInfo: UiJsx });
                    }
                    //case it is not required and not empty
                    else {
                        UiJsx.push(<SpawnInfoFieldComponent key={result.id.value} content={result} schema={schema} depth={[id]} spawnInfoType={this.state.spawnInfoType} context={this.props.context} />)

                        if (!this.state.requiresSpawnInfo)
                            UiJsx.push(<Button color="danger" onClick={() => this.RemoveSpawnInfo(id, schema)}>Remove Spawn Info</Button>);

                        this.setState({ spawnInfo: UiJsx });
                    }
                });
            }
        }
        else {
            if (this.state.requiresSpawnInfo)
                this.setState({ spawnInfo: [] });
            else {
                UiJsx.push(<Button color="success">Add Spawn Info</Button>)
                this.setState({ spawnInfo: UiJsx });
            }
        }
    }

    OnDeleteSecondaryContent(index) {

        var removalInfo = {};
        var spawnId:any = this.props.context.content[index].id;

        if (typeof (spawnId) == "object")
            spawnId = spawnId.value;

        removalInfo["remove"] = { value: spawnId, type: this.state.spawnInfoType }//true;
        this.props.context.RemoveSecondaryContent(spawnId, removalInfo, [spawnId]);
    }

    render() {

        return (<div className="container-main">
            {super.GetSelectionJSX("Select A " + this.state.containerType.replace(/_/gi, ' '), () => this.SetSpawnInfo())}

            <br /><hr></hr>
            {super.GetCreateJsx("Create New " + this.state.containerType.replace(/_/gi, ' '), () => super.CreateElement(this.state.containerType))}

            <br />
            {super.GenerateContent(["_id"])}

            <br /><br />
            {super.GetDeleteJsx("Delete", (index) => this.OnDeleteSecondaryContent(index))}

            <br /><br /><hr />
            {new ComponentConstructor().BuildTitle("Spawn Info", [])}
            {this.state.spawnInfo}

            <br /><br /><hr />
            {new ComponentConstructor().BuildTitle("Spawn Rate Table", [])}
            <SpawnInfoTableComponent context={this.props.context} activeTab={this.state.activeTab} schema={this.props.schema}/>

            <br /><br /><hr />
            {this.state.publishDisplay}
        </div>);
    }
}
