import React, { useState, useEffect } from "react";
import Select from 'react-select';
import ContentCache from '../../utilities/Cache Utilities/ContentCache';
import chroma from 'chroma-js';
import { Alert } from "reactstrap";
import PropTypes from 'prop-types';

interface Props {
	label: string,
	depth: Array<string>,
    value: any,
    schemaType: Array<string>,
	context: any //you will need a context object eventually
}

interface Option {
    label: string,
    value: any
}

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

        var test = chroma('black');
        var color;

        if (data.value == "#ERROR") {
            color = 'red';
            test = chroma('red');
        }
        else
            color = 'black';

        return {
            ...styles,
            backgroundColor: 'white',
            color: color,
            cursor: isDisabled ? 'not-allowed' : 'default',
        };
    },
    input: styles => ({ ...styles }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => {
        var color;

        if (data.value == "#ERROR") {
            color = 'red';
        }
        else
            color = 'black';

        return {
            ...styles, color: color
        }
    },
};

export const ContentSelectionFieldComponent: React.FC<Props> = (props) => {

    var [value, UpdateValue] = useState(props.value);
    var [label, UpdateLabel] = useState("");
    var startOptions : Array<Option> = [];
    var [options, UpdateOptions] = useState(startOptions);

    var OnValueChanged = (event) => {

        UpdateValue(event.value);
        UpdateLabel(event.label);
        props.context.UpdateContent(event.value, props.depth);
    }

    var GetOptions = async () => {

        var content : any[] = [];

        for (var index in props.schemaType) {
            var type = props.schemaType[index];
            var rawOptions = await new ContentCache().GetCacheValue(type);
            content.push(...rawOptions);
        }

        var options : Option[] = [{ label: "None", value: "" }];
        var val : any = -1;
        var label = "";

        for (var item in content) {
            var itemLabel = content[item].admin_console_display_name;
            var itemValue = { "value": content[item].id, "type": content[item].type }

            if (typeof (itemValue.value) === "object")
                itemValue = content[item].id
           
            options.push({ label: itemLabel, value: itemValue });

            if ((typeof (props.value) === "object") && ((props.value.value == itemValue.value) && (props.value.type == itemValue.type))) {
                val = itemValue;
                label = itemLabel;
            }
        }

        if (typeof (val) === "object") {
            UpdateOptions(options);
            UpdateValue(val);
            UpdateLabel(label);
        }
        else {
            options.push({ label: props.value, value: "#ERROR" })
            UpdateOptions(options);
            UpdateValue("#ERROR");
            UpdateLabel(label);
            
        }

    }

    useEffect(() => {
        GetOptions();
    }, [])

    var GetView = () => {
        var jsx : JSX.Element[] = [];

        jsx.push(<Select key={0}
            className="form-control-small"
            onChange={OnValueChanged}
            options={options}
            value={{ label: label, value: value }}
            styles={colourStyles}
        />);

        if (value == "#ERROR")
            jsx.push(
                <Alert color="danger" key={1}>
                    <p className="Alert-heading">selected item does not exist</p>
                </Alert>
            )

        return jsx;
    }

    return (
        <div className="form-inline">
            <p>{props.label}</p>
            {GetView()}
        </div>
    );
}