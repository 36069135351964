import React, { useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Context from './../../models/Context'
import { BuildContent } from './../../utilities/website building utilities/ContentBuilder';

interface Props
{
	label: string,
	depth: Array<string>,
    value: any,
	arrayType: any,
    schemaType: string,
    context: Context,
    keysToIgnore: Array<string>,
    onReorder?: Function
}


export const ArrayFieldComponent :React.FC<Props> = (props) => {
	
    const [value, UpdateValue] = React.useState(props.value);

    var onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        var src = result.source.index;
        var dest = result.destination.index;

        var newValue = {}
        var keys = Object.keys(value);

        var arrayWithoutElement = [];
        for(var index in keys) {
            if(index != src) {
                arrayWithoutElement.push(keys[index]);
            }
        }

        var pushed = false;
        var arrayWithElement = [];
        for(var index in arrayWithoutElement) {
            if(index == dest) {
                arrayWithElement.push(keys[src])
                pushed = true;
            }

            arrayWithElement.push(arrayWithoutElement[index])
        }

        if(!pushed) {
            arrayWithElement.push(keys[src])
        }
        
        var count = 0;
        for(var key of arrayWithElement) {
            if(isNaN(key)) {
                newValue[key] = value[key]; //keep non-numerical keys
            } else {
                newValue[count] = value[key]; //use new numerical keys
            }

            count += 1;
        }

        if(props.onReorder != undefined) {
            props.onReorder(newValue);
        }
    }

	var RegenerateArray = () => {

        /*
        if ((("schema" in props)&&("AbsoluteLength" in props["schema"]))&&(value.length == 0))
        {
            let defaultValue = BuildContent(props["schema"],props.schemaType,props.context,props.depth);
            console.log("regenerate array",props, " , ",defaultValue );
        }*/

        var UiJsx : JSX.Element[] = []

        var counter = 0;
        for (var index in value) {
            var val = value[index];

            var depth = props.depth.slice();
            depth.push(index);

            if ((val.type != null) && (!Number.isNaN(parseInt(index))))
            {
                index = val.type;
            }
            
            var color = "#F5E7F9";
            if (counter % 2 != 0)
                color = "#FAF2E8";

            if (typeof (props.schemaType) != 'undefined') {
                UiJsx.push(
                    <div style={{ backgroundColor: color }}>
                        <props.arrayType key={index} value={val} label={index} depth={depth} keysToIgnore={props.keysToIgnore} schemaType={props.schemaType} context={props.context} />
                    </div>
                );
            }
            else {
                UiJsx.push(
                    <div  style={{ backgroundColor: color }}>
                        <props.arrayType key={index} value={val} label={index} context={props.context} depth={depth} keysToIgnore={props.keysToIgnore} />
                    </div>
                );
            }
            counter++;
        }
        return UiJsx;
    }

	let UiJsx = RegenerateArray();

    var draggableContent = ( 
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-1" type="PERSON">
                {(provided, snapshot) => (
                    <div
                    ref={provided.innerRef}
                    style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                    {...provided.droppableProps}
                    >
                    {UiJsx.map((item, index) => (
                        <Draggable key={`${index}`} draggableId={`${index}`} index={index}>
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            >
                            {item}
                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>);

    var content = UiJsx;
    if(props.onReorder != undefined) {
        content = [draggableContent];
    }

    if (typeof (props.schemaType) != 'undefined') {
        return (<View label={""} content={content} depth={props.depth.length} expandable={true} />);
    }
    else {
        return (<View label={props.label.replace(/_/gi, ' ')} content={content} depth={props.depth.length} expandable={false} />);
    }
}

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { array } from "prop-types";
import { SchemaType } from "mongoose";

const useStyles = makeStyles(theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));

interface ViewProps {
    label: string,
    content: Array<JSX.Element>,
    depth: number,
    expandable: boolean
}

const View = (props : ViewProps) => {

    const classes = useStyles();
    return (
        <div>
            <Typography>{props.label}</Typography>
            {props.content}
        </div>
    );
}