import React, { useEffect } from "react";
import Select from 'react-select';
import { BuildComponentFromSchema } from "./../utilities/website building utilities/SchemaBuilder";
import ComponentConstructor from "./../utilities/website building utilities/ComponentConstructor";
import PropTypes from 'prop-types';

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText, Alert, Button, Label, Container, Row, Col, Input, Progress } from "reactstrap";
import { ExtraSchemaComponent } from "./../components/ExtraSchemaComponent";
import { LocIdFieldComponent } from "./../components/base types/LocIdFieldComponent";
import { LocIdFieldComponentt } from "./../components/base types/LocIdFieldComponentt";

import LabelComponent from './../components/static components/LabelComponent';

import SchemaCache from './../utilities/Cache Utilities/SchemaCache';
import { BuildContent } from "./../utilities/website building utilities/ContentBuilder";
import UniqueIdProvider from './../utilities/web interface utilities/Providers/UniqueIdProvider'
import ObjectManipulator from './../utilities/ObjectManipulator';

import { GetPublishJsx } from './Publishing'

import Context from './../models/Context'

var mongoose = require("mongoose");
import { display } from "@material-ui/system";
var GetLanguages = require('./../../../global/GlobalStatics').GetLanguages;
import { IsStandAlone } from './../../../global/GlobalStatics';//require('./../../../global/GlobalStatics').IsStandAlone;

var snake = require('to-snake-case');
var upperCamelCase = require('uppercamelcase');

export interface TypeContainerProps {
    additionalInfo: {},
    containerType: string,
    schema: Schema,
    context: Context,
}

interface Schema {
    properties: Array<any>
}

interface Option {
    label: string,
    value: string
}

interface TypeContainerState {
    activeTab: string,
    options: Array<Option>,
    defaultValue: Option,
    newDisplayName: string
    publishDisplay: Array<any>,
    verificationPassword: string,
    isSingleton: boolean,
    containerType: string,
}

class TypeContainer extends React.Component<TypeContainerProps, any> {

    constructor(props : TypeContainerProps) {
        super(props);

        var isSingleton = props.additionalInfo["isSingleton"];

        var newDisplayName = "";
        if (isSingleton)
            newDisplayName = props.containerType

        this.state = {
            activeTab: '1',
            options: [],
            defaultValue: null,
            newDisplayName: newDisplayName,
            publishDisplay: [],
            verificationPassword: "",
            isSingleton: isSingleton,
            containerType: props.containerType,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.newDisplayName != nextState.newDisplayName)
            return false;

        return true;
    }

    componentDidMount() {
        this.GetOptionsJSX();
        GetPublishJsx(this);
    }

    //=======================================
    //Main Content
    //=======================================

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    GetTabs(content) {
        var tabs = <div>
            <br /><hr />
            <Nav tabs>
                <NavItem key={0}>
                    <NavLink onClick={() => { this.toggle('1'); }}>
                        CONTENT
          </NavLink>
                </NavItem>
                <NavItem key={1}>
                    <NavLink onClick={() => { this.toggle('2'); }}>
                        LOCALIZATION
          </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                    {content[0]}
                </TabPane>
                <TabPane tabId="2">
                    {content[1]}
                </TabPane>
            </TabContent>
        </div>;

        return tabs;
    }

    GenerateContent(additonalKeysToIgnore = [], customSchema : Schema = null, localiz = null) {
        // this.setState({content: this.props.context.content})
        var UiJsx = [];
        var constructor = new ComponentConstructor();

        if (this.props.context.content.length == 0) {
            if (this.state.isSingleton) {
                this.CreateElement(this.state.containerType)
                return UiJsx;
            }
            else
                return UiJsx;
        }

        var depth = [];
        depth.push(this.props.context.subSelection);

        var displayName = "Loading.."
        var localizationExists = false;

        var keysToIgnore = ["display_name", '_id', 'id', 'created', "localization"];

        //checks something is selected for us to even generate content
        if ((+this.props.context.subSelection >= 0) && (this.props.context.content.length > +this.props.context.subSelection)) {
            if ("localization" in this.props.context.content[this.props.context.subSelection])
                localizationExists = true;

            displayName = this.props.context.content[this.props.context.subSelection].admin_console_display_name;

        }

        if (additonalKeysToIgnore.length > 0) {
            additonalKeysToIgnore.forEach(function (key) {
                keysToIgnore.push(key);
            });
        }

        var contentSchema = this.props.schema;

        if (customSchema != null) {
            contentSchema = customSchema;
        }

        var tab1 = [];
        //tab1.push(constructor.BuildTitle(displayName, []));
        var labelDepth = [this.props.context.subSelection, "admin_console_display_name"]

        tab1.push(<LabelComponent key={displayName} depth={labelDepth} value={displayName} context={this.props.context} />)

        for (var field in this.props.context.content[this.props.context.subSelection]){
            var loc = this.props.context.content[this.props.context.subSelection][field];
            if (typeof loc === 'object' && 'code' in this.props.context.content[this.props.context.subSelection][field]){
                tab1.push(<LocIdFieldComponentt key={loc.code} label={field} value={loc} keysToIgnore={keysToIgnore} options={GetLanguages()} context={this.props.context} depth={depth} show={true} />);
            }
        }
        // var loc = this.props.context.content[this.props.context.subSelection].plural_noun;
        // if (loc)
        //     tab1.push(<LocIdFieldComponent key={loc.code} label={"Localization"} value={loc} keysToIgnore={keysToIgnore} options={GetLanguages()} context={this.props.context} depth={depth} />);

        tab1.push(
            <div>
                {BuildComponentFromSchema(contentSchema, this.props.context.content[this.props.context.subSelection], this.props.context, depth, keysToIgnore)}
            </div>);

        var tab2 = [];

        keysToIgnore = ['created'];

        for (var property in contentSchema.properties) {
            if (property != "localization")
                keysToIgnore.push(property);
        }

        depth.push("localization");

        if (localizationExists) {
            var loc = this.props.context.content[this.props.context.subSelection].localization;

            if ("code" in loc) {
                tab2.push(constructor.BuildTitle(displayName + " Localization", []));
                tab2.push(<LocIdFieldComponent show={false} key={loc.code} label={"Localization"} value={loc} keysToIgnore={keysToIgnore} options={GetLanguages() } context={this.props.context} depth={depth} />);
            }
            else {
                tab2.push(constructor.BuildTitle(displayName + " Localization", []));
                tab2.push(<ExtraSchemaComponent key={displayName} label={"Localization"} value={loc} keysToIgnore={keysToIgnore} schemaType={"content_localization_set"} context={this.props.context} depth={depth} />);
            }

            UiJsx.push(this.GetTabs([tab1, tab2]));
        }
        else
            UiJsx.push(tab1);



        return UiJsx;
    }

    async GetOptionsJSX() {

        var options = [];
        var defaultValue;

        var content = this.props.context.content;

        if (content.length > 0) {
            content[this.props.context.subSelection]["selected"] = true;

            //alphabetize content 
            content = content.sort((a, b) => {
                return a["admin_console_display_name"].localeCompare(b["admin_console_display_name"]);
            });

            var sortedSelectionIndex = '0';

            for (var index in content) {

                var option : string;

                option = this.props.context.content[index].admin_console_display_name;

                options.push({ label: option, value: index });

                if ("selected" in content[index]) {

                    sortedSelectionIndex = index;
                    delete content[index]["selected"];
                }
            }

            defaultValue = options[sortedSelectionIndex]
        }
        else {
            defaultValue = 0;
            sortedSelectionIndex = '0';
        }

        await this.props.context.ChangeSubSelection(sortedSelectionIndex)

        this.setState({ options: options, verificationPassword: "", defaultValue: defaultValue });
    }


    async OnChangeSelected(event, additionalCallback) {
        await this.props.context.ChangeSubSelection(parseInt(event.value))

        this.setState({ defaultValue: event }, () => { additionalCallback() });
    }

    async OnRemoveElement(deleteSecondayContent) {
        if (this.state.options.length > 0) {
            
            if (deleteSecondayContent != null) {
                deleteSecondayContent(this.props.context.subSelection);
            }

            this.props.context.RemoveSelectedContent();

            var subselection = this.props.context.subSelection;
            
            if(subselection > 0) {
                subselection -= 1
            }

            await this.props.context.ChangeSubSelection(subselection)
            this.setState({ defaultValue: event }, () => {});

            this.componentDidMount();    
        }
        else
            alert("There is nothing else to remove...");
    }


    IsValidElement(displayName, errorLabel) {
        var vowel = "";

        if ((errorLabel[0] == 'a') || (errorLabel[0] == 'e') || (errorLabel[0] == 'i') || (errorLabel[0] == 'o') || (errorLabel[0] == 'u'))
            vowel = "n"

        if (this.state.newDisplayName.length < 3)
            return "A" + vowel + " " + errorLabel + "'s display name must be at least 3 letters long";

        for (var index in this.props.context.content) {
            var value = this.props.context.content[index];

            if (value.admin_console_display_name == displayName)
                return "A" + vowel + " " + errorLabel + " with the name " + this.state.newDisplayName + " already exists";
        }

        return "";
    }

    async CreateElement(type, updateSchema = (schema) => { }) {        
        var errorMsg = this.IsValidElement(this.state.newDisplayName, type);


        if (errorMsg != "") {
            alert(errorMsg);
            return;
        }

        var idProvider = new UniqueIdProvider();

        var idNumber = await idProvider.GetUniqueId(upperCamelCase(type), this.props.context.content)

        var schema = await new SchemaCache().GetCacheValue(type);

        if ((this.state.isSingleton) && (this.props.context.content.length >= 1)) {
            //do nothing
        }
        else {
            var content = null;

            var buildContentContext = new ObjectManipulator().DeepCopy(this.props.context);
            buildContentContext.content.push({ admin_console_display_name: this.state.newDisplayName });
            content = BuildContent(schema, type, buildContentContext);
            content._id = new mongoose.Types.ObjectId();

            content["admin_console_display_name"] = this.state.newDisplayName;

            if (typeof (content["id"]) == "object"){//&&(!IsStandAlone(type))) {
                content["id"].value = idNumber;
            }
            else {
                content["id"] = idNumber;
            }

            await this.props.context.AddContent(content,this.state.options.length);
            await this.componentDidMount();
            await this.setState({ defaultValue: { label: snake(this.state.newDisplayName), value: snake(this.state.newDisplayName) } })

            updateSchema(schema)
        }

    }

    GetSelectionJSX(label, additionalCallback = () => { }) {
        var UiJsx = [];

        if (this.state.isSingleton)
            return UiJsx;

        if (this.state.options.length > 0) {
            UiJsx.push(<span>
                <label>{label}</label>
                <div style={{ width: "400px" }}>
                    <Select
                        className="form-control-main"
                        onChange={event => this.OnChangeSelected(event, additionalCallback)}
                        options={this.state.options}
                        value={{ label: this.state.defaultValue.label, value: this.state.defaultValue.value }}
                    />
                </div>
            </span>);
        }

        return UiJsx
    }

    GetDeleteJsx(label, deleteSecondayContent = null) {
        var UiJsx = [];

        if (this.state.isSingleton)
            return UiJsx;

        UiJsx.push(<button className="btn btn-danger" onClick={this.OnRemoveElement.bind(this, deleteSecondayContent)}>{label}</button>);

        return UiJsx
    }

    GetCreateJsx(label, callback) {
        var UiJsx = [];

        if (this.state.isSingleton)
            return UiJsx;

        UiJsx.push(<div className="form-inline"  >
            <label>Display Name</label>
            <input className="form-control" type="text" onChange={(event) => {
                //Update stored name
                this.setState({ newDisplayName: event.target.value })
                }} placeholder="Enter Display Name" id="DisplayName" />
            <button className="btn btn-success" onClick={callback}>{label}</button>
            <button className="btn btn-success" onClick={() => this.DuplicateContent(this.state.containerType)}>{"Duplicate"}</button>
        </div>);

        return UiJsx
    }

    async DuplicateContent(type) {
        var errorMsg = this.IsValidElement(this.state.newDisplayName, type);

        if (errorMsg != "") {
            alert(errorMsg);
            return;
        }

        var idProvider = new UniqueIdProvider();

        var idNumber = await idProvider.GetUniqueId(upperCamelCase(type), this.props.context.content);

        var schema = await new SchemaCache().GetCacheValue(type);

        //hacky way to get localization 
        var locContent = null;
        var buildContentContext = new ObjectManipulator().DeepCopy(this.props.context);
        buildContentContext.content.push({ admin_console_display_name: this.state.newDisplayName });
        locContent = BuildContent(schema, type, buildContentContext);

        //copy primary content
        var content = new ObjectManipulator().DeepCopy(this.props.context.content[this.props.context.subSelection]);
        content._id = new mongoose.Types.ObjectId();

        //copy secondary content
        var secondaryContent = new ObjectManipulator().DeepCopy(this.props.context.secondaryContent[content.id]);
        secondaryContent._id = new mongoose.Types.ObjectId();
        if (typeof (secondaryContent["id"]) == "object")
            secondaryContent["id"].value = idNumber;
        else
            secondaryContent["id"] = idNumber;

        //assign duplicated secondary content
        this.props.context.secondaryContent[idNumber] = secondaryContent;
        this.props.context.SetSecondaryContent(idNumber, secondaryContent)

        //ASSIGN PRIMARY CONTENT
        if (typeof locContent["localization"] != "undefined")
            content["localization"] = locContent["localization"];

        var languages =  GetLanguages();

        if (typeof locContent["description"] != "undefined"){
            for(var key in languages) { //copying over description
                var text = this.props.context.localization[key].localizations[content["description"].code];
                var language = languages[key].value;
                this.props.context.UpdateLocalization(locContent["description"].code, text, language);
            }
            content["description"] = locContent["description"];
        }

        if (typeof locContent["title"] != "undefined"){
            for(var key in languages) { //copying over title
                var text = this.props.context.localization[key].localizations[content["title"].code];
                var language = languages[key].value;
                this.props.context.UpdateLocalization(locContent["title"].code, text, language);
            }
            content["title"] = locContent["title"];
        }

        content["admin_console_display_name"] = this.state.newDisplayName;

        if (typeof (content["id"]) == "object"){//&&(!IsStandAlone(type))) {
            content["id"].value = idNumber;
        }
        else {
            content["id"] = idNumber;
        }

/*
        if (typeof (content["id"]) == "object")
            content["id"].value = idNumber;
        else
            content["id"] = idNumber;
*/
        //assign duplicated content
       // this.props.context.content.push(content);
        //this.props.context.SetContent(this.props.context.content);
       // await this.props.context.ChangeSubSelection(this.state.options.length);
        await this.props.context.AddContent(content,this.state.options.length);


        await this.componentDidMount();
        this.setState({ defaultValue: { label: snake(this.state.newDisplayName), value: snake(this.state.newDisplayName) } });

        /*
        this.setState({ selectedOption: this.state.options.length }, () => {
            this.componentDidMount();
            this.setState({ defaultValue: { label: snake(this.state.newDisplayName), value: snake(this.state.newDisplayName) } })
        });
        */
    }


    

    render() {

        return (
            <div className="container-main">

                {this.GetSelectionJSX("Select A " + this.state.containerType.replace(/_/gi, ' '))}

                <hr />
                {this.GetCreateJsx("Create New " + this.state.containerType.replace(/_/gi, ' '), () => this.CreateElement(this.state.containerType))}


                {this.GenerateContent()}

                <br />
                {this.GetDeleteJsx("Delete " + this.state.containerType.replace(/_/gi, ' '))}

                <br /><br /><hr />
                {this.state.publishDisplay}
            </div>
        );

    }

}

export default TypeContainer;
