import React, { useEffect } from "react";
import { Navbar, Nav, NavItem, NavLink } from "reactstrap";
var mongoose = require("mongoose");
import PropTypes from 'prop-types';
import Context from './../../models/Context'

interface Props {
    label?: string,
    depth?: Array<string>,
    value: Array<number>,
    options: Array<string>,
    OnChangeTab: Function,
    multiSelect?: boolean,
    context?: Context //you will need a context object eventually
}

export const OptionSelectorFieldComponent: React.FC<Props> = (props) => {

    var UiJsxStart: Array<JSX.Element> = []
    const [UiJsx, UpdateUiJsx] = React.useState(UiJsxStart);
    const [SelectedIndicies, UpdateSelectedIndicies] = React.useState(props.value);

    useEffect(() => {

        if (props.value.length > 0) {
            for (var index in props.value) {
                UpdateSelection(props.value[index]);
            }
        }
        else {
            UpdateSelection();
        }
    }, [])

    var UpdateMultiSelect = (index: number, updateDeselection : boolean) => {
        var newSelectedIndicies = SelectedIndicies;

        if (!(newSelectedIndicies.includes(index)))
        {
            newSelectedIndicies.push(index);
        } else if (updateDeselection){
            newSelectedIndicies = UpdateDeselection(index, newSelectedIndicies);
        }
          
        return newSelectedIndicies;
    }

    var UpdateDeselection = (index: number, newSelectedIndicies : Array<number>) => {
        if(newSelectedIndicies.includes(index)) {
            newSelectedIndicies.splice(newSelectedIndicies.indexOf(index), 1);
        }
        
        return newSelectedIndicies;
    }

    var UpdateSelection = (index: number = -1, updateDeselection : boolean = false) => {

        var newUiJsx = [];
        var newSelectedIndicies = [];

        if ((props.multiSelect) && (index >= 0)) {
           
            newSelectedIndicies = UpdateMultiSelect(index, updateDeselection)
        }
        else if (index >= 0)
        {
            newSelectedIndicies = [index];
        }
            

        for (var i = 0; i < props.options.length; i++) {
            if (newSelectedIndicies.includes(i)) {

                newUiJsx.push(
                    <NavItem key={i}>
                        <button className="btn btn-secondary" onClick={Update.bind(window, i)}>{props.options[i].replace(/_/gi, ' ')}</button>
                    </NavItem>);
            }
            else {
                newUiJsx.push(
                    <NavItem key={i}>
                        <button className="btn btn-primary" onClick={Update.bind(window, i)}>{props.options[i].replace(/_/gi, ' ')}</button>
                    </NavItem>);
            }
        }

        UpdateUiJsx(newUiJsx);
        UpdateSelectedIndicies(newSelectedIndicies);
    }

    var Update = (index: number) => {
        UpdateSelection(index, true);

        if (props.OnChangeTab != null)
            props.OnChangeTab(index);
        else if (props.multiSelect) {
            props.context.UpdateContent(SelectedIndicies, props.depth);
        }
    }

    return (

        <div>

            <Navbar expand="md">
                <Nav className="ml-left" navbar >
                    <p>{props.label}</p>
                    {UiJsx}
                </Nav>
            </Navbar>
        </div>)
}