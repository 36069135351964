import { ArrayFieldComponent } from "./../components/base types/ArrayFieldComponent";
import { IdSelectorComponent } from "./../components/base types/IdSelectorComponent";
import { ContentSelectorComponent } from "./ContentSelectorComponent";
import ContentCache from './../utilities/Cache Utilities/ContentCache';
//import { Linker } from './../utilities/linking utilities/Linker'
var snake = require('to-snake-case');
import PropTypes from 'prop-types';

import {GetUID} from './../utilities/Utilities';

import React, {useEffect} from "react";

import Context from './../models/Context'

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { json } from "body-parser";

interface Props
{
    value: Array<ID>,
    label: string,
    depth: Array<string>,
    allowDuplicates: boolean,
    keysToIgnore: Array<string>,
    schemaType: string,
	context: Context //you will need a context object eventually
}

interface ID {
    value: number,
    type: string
}

export const IdArrayComponent:React.FC<Props> = (props) => {
    var startContent : Array<JSX.Element> = [];
    const [content, UpdateContent] = React.useState(startContent);
    var startTypes : Array<any> = [];
    const [types, UpdateTypes] = React.useState(startTypes);

    var OnReorder = (newValue) => {

        props.value.splice(0, props.value.length);

        for(var key in newValue) {
            props.value.push(newValue[key]);
        }

        props.context.UpdateContent(props.value, props.depth);
        GenerateArray();
    }
    
    var GenerateArray = () => {

        var UiJsx = [];

        UiJsx.push(<ArrayFieldComponent key={JSON.stringify(props.value)} label={props.label} arrayType={IdSelectorComponent} value={props.value} schemaType={props.schemaType} context={props.context} depth={props.depth} keysToIgnore={props.keysToIgnore} onReorder={OnReorder}/>);

        new ContentCache().GetCacheValue(props.schemaType).then(result => {

            var selectedOptions = [];
            var types = [];

            //map accepted content to something easier to use
            var contentMappedOptions = {};
            for (var index in result)
            {
                if ("type" in result[index])
                    types.push(result[index].type);

                var key = JSON.stringify(result[index].id);
                var value = snake(result[index].admin_console_display_name.replace(/_/gi, ' '));
                contentMappedOptions[key] = value;
            }
            
            //check that selected properties are among the accept content
            for (var index in props.value)
            {
                var key = JSON.stringify(props.value[index]);

                if (Object.keys(contentMappedOptions).includes(key))
                    selectedOptions.push(contentMappedOptions[key]);
            }

            UiJsx.push(<ContentSelectorComponent selectedOptions={selectedOptions} schemaType={props.schemaType} add={OnAddId} remove={OnRemoveId} depth={props.depth} keysToIgnore={props.keysToIgnore} allowDuplicates={props.allowDuplicates} />);
            UpdateContent(UiJsx);
            UpdateTypes(types);
        });

        
    }

    var OnAddId = async (value, type) => {

        //var linker = new Linker();
        var contentIndex = props.depth[0];
        //var linkID = await linker.ConvertID(props.context.content[contentIndex].id, snake(props.context.contentMode));
        //var id = await linker.ConvertID(value, snake(props.schemaType));
        var depth = [...props.depth];
        depth.push(props.value.length + "")
        //await linker.AddLink(id, linkID, depth, props.context);

        var additionalContent = {
            "value": value,
            "type": snake(type)
        }

        props.value.push(value);

        props.context.UpdateContent(props.value, props.depth);

        //var link = this.props.depth;
        //link.push(this.props.value.length-1);
        //this.props.context.AddLink(value,link,this.props.context);
        GenerateArray();
    }

    var OnRemoveId = async (index) => {

        console.log("wuh 2");

        //var linker = new Linker();
        var contentIndex = props.depth[0];
        //var linkID = await linker.ConvertID(props.context.content[contentIndex].id, snake(props.context.contentMode));
        //var id = await linker.ConvertID(props.value[index], snake(props.schemaType));
        var depth = [...props.depth];
        depth.push(index + "")
        //await linker.RemoveLink(id, linkID, depth, props.context);

        props.value.splice(index, 1);
        props.context.UpdateContent(props.value, props.depth);
        GenerateArray();
    }

    useEffect(() => {
        GenerateArray();
    }, [props.value])

    return <View content={content} label={props.label} />
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));


const View = (props) => {

    const classes = useStyles();
    return (
        <div className={classes.root} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <ExpansionPanel >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className={classes.heading}>{props.label}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails >
                    <div style={{ width: "100%" }}>
                        {props.content}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}