var snake = require('to-snake-case');
var mongoose = require("mongoose");

export function IsFloat(val) {
    var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(val))
        return false;

    val = parseFloat(val);
    if (isNaN(val))
        return false;
    return true;
}


export function IsJsonArray(JsonFile) {
    var str = JsonFile;
    try {
        str = JSON.stringify(JsonFile);
    } catch (e) { }
    
    if (((str.charAt(0) == '[') && (str.charAt(str.length - 1) == ']'))||((JsonFile.charAt(0) == '[') && (JsonFile.charAt(JsonFile.length - 1) == ']')))
        return true;

    return false;
}

export function IsTypeArray(type)
{
    if (((type[type.length-2]== '[')&&(type[type.length-1]== ']'))||type.includes("System.Collections.Generic.List"))
        return true;
    return false;
}

export function IsSchemaUnityVector3(schema)
{
    if ("properties" in schema)
    { 
        var properties = schema.properties;
        if (('magnitude' in properties)&&('normalized' in properties)&&('sqr_magnitude' in properties)&&('x' in properties)&&('y' in properties)&&('z' in properties))
            return true;
    }
   
    return false;
}

 export function IsInt(val) {
     
    var intRegex = /^-?\d+$/;
    if (!intRegex.test(val))
        return false;

    var intVal = parseInt(val, 10);
    return parseFloat(val) == intVal && !isNaN(intVal);
}

//gets generic type of an object
export function GetGenericType(type)
{
    try 
    {
        var str = type.split("`")[1].split(",")[0];
        var wipeIndex = str.indexOf("[");
        str = str.slice(wipeIndex);
    
        str = str.replace(/\[/gi, "");

        str = str.split(".");
        str = str[str.length-1];
    }
    catch(error)
    {
        return "";
    }
    

    return str;
}

//todo refactor this
//gets base type of an object
export function GetNestedId(type,isArray,isSnake)
{
    try
    {
        var str;
        var genericVal = "";
    
        if (isArray)
        {
            if (type.includes("`"))
            {
                str = GetGenericType(type);
                str = str.split(".");
    
                if (isSnake)
                    return snake(str[str.length - 1]);
                else 
                    return str[str.length - 1];
            }
            else
            {
                str = type.split(".");
    
                if (isSnake)
                    return snake(str[str.length - 1].split("[]")[0]);
                else 
                    return str[str.length - 1].split("[]")[0];
            } 
        }
    
        else if (type.includes(", Assembly-CSharp, Version=0.0.0.0"))
        {
            str = type.split(", Assembly-CSharp, Version=0.0.0.0")[0].split(".");
        } 
        else if (type.includes("`"))
        {
           

            genericVal = "_"+type.split("`")[1].split('[')[0];
            str = type.split("`")[0].split(".");

            console.log("getting generic type?! ",str);
        } 
        else
        {
            str = type.split('.')
        }
    
        var idType ;
    
        if (isSnake)
            idType = snake(str[str.length - 1])+genericVal;
        else
            idType = str[str.length - 1]+genericVal;
    
        return idType;
    }
    catch(error)
    {   
        return type;
    }
    
}

export function GetUID()
{
    //return Math.random() * 9999999;
    return new mongoose.Types.ObjectId();
}





