import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText,Alert,Button,Label, Container,Row,Col,Input } from "reactstrap";
import React from "react";
import { VerifyPassword } from "./../../utilities/web interface utilities/WebApi";
import PropTypes from 'prop-types';

class VerificationComponent extends React.Component {

    constructor(props) {

        super();

        this.state = {
            UiJsx: [],
            verificationPassword:"",
        }
    }
    componentDidMount() 
    {
        this.OnStart();
    }

    OnStart()
    {
        var UiJsx = [];
        UiJsx.push(<Button color={this.props.buttonColor} onClick={()=>this.OnAreYouSure()}>{this.props.buttonText}</Button>);
        this.setState({UiJsx:UiJsx});
    }

    OnAreYouSure()
    {
        var UiJsx = [];
        UiJsx.push(<Alert key={new mongoose.Types.ObjectId().toString()} color="danger">
        <h4 className="Alert-heading">{this.props.verificationText}</h4>
        <hr/>
        <Container>
        <Row>
        <Col>
        <Button color="success"  onClick={()=>this.OnVerifyPassword()} block>Yes!</Button>
        </Col>
        <Col>
        <Button color="danger" onClick={()=>this.OnStart()} block>No!</Button>
        </Col>
        </Row>
        </Container>
        </Alert>);

        this.setState({UiJsx:UiJsx});
    }

    OnVerifyPassword()
    {
        var UiJsx = [];
        UiJsx.push(<Alert key={new mongoose.Types.ObjectId().toString()} color="danger">
        <h4 className="Alert-heading">Please enter your verification password</h4>
        <hr/>
        <Container>
        <Row>
        <Input type="password" placeholder="Enter verification password" onChange={(event)=>this.setState({verificationPassword:event.target.value})}/>
        </Row>
        <Row style={{ height: '25px' }} />
        <Row>
        <Col>
        <Button color="success" onClick={()=>this.OnPasswordVerification()} block>Verify</Button>   
        </Col>
        <Col>
        <Button color="danger" onClick={()=>this.OnStart()} block>Cancel</Button>
        </Col>
        </Row>
        </Container>
        </Alert>);

        this.setState({UiJsx:UiJsx});
    }

    OnPasswordVerification()
    {
        VerifyPassword(this.state.verificationPassword).then(result=>{
            if (result.length == 0)
            {
                alert("Your verification password was inccorect");
            }
            else
            {
                this.OnStart();
                this.props.callback();
            }
                
        })
    }



    render() {
        return (
            <div>
                {this.state.UiJsx}
            </div>
        );

    }

}

VerificationComponent.propTypes =
    {
        buttonColor:PropTypes.string.isRequired,
        buttonText:PropTypes.string.isRequired,
        verificationText :PropTypes.string.isRequired,
        callback: PropTypes.func.isRequired
    };

export default VerificationComponent;
