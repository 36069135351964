import { ArrayFieldComponent } from "./../components/base types/ArrayFieldComponent";
import { ExtraSchemaComponent } from "./../components/ExtraSchemaComponent";
import { SchemaSelectorComponent } from "./../components/SchemaSelectorComponent";
import SchemaCache from './../utilities/Cache Utilities/SchemaCache';
import { BuildContent } from './../utilities/website building utilities/ContentBuilder';
import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import Context from './../models/Context'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
    label: string,
    depth: Array<string>,
    value: Array<Rule>,
    allowDuplicates: boolean,
    schemaType: string,
    keysToIgnore: Array<string>,
    context: Context, //add alter
}

interface Rule {
    type: string,
}

export const RuleFieldComponent:React.FC<Props> = (props) => {

    const [content, SetContent] = React.useState([]);

    const classes = useStyles();

    useEffect(()=>{
        GenerateRules();
    },[props.value]);


    const GenerateRules= ()=> {

        var UiJsx = [];

        UiJsx.push(<ArrayFieldComponent key={JSON.stringify(props.value)} label={props.label} arrayType={ExtraSchemaComponent} value={props.value} schemaType={props.schemaType} context={props.context} depth={props.depth} keysToIgnore={props.keysToIgnore} />);

        var selectedOptions = [];

        for (var rule in props.value) {

            var ruleType = props.value[rule].type;
            selectedOptions.push(ruleType);
        }

        UiJsx.push(<SchemaSelectorComponent selectedOptions={selectedOptions} schemaType={props.schemaType} allowDuplicates={props.allowDuplicates} add={OnAddRule} remove={OnRemoveRule} depth={props.depth} keysToIgnore={props.keysToIgnore} />);

        SetContent(UiJsx);
    }

    //value is a string 
    const OnAddRule = (value, type)=> {

        if (value != null) {
            new SchemaCache().GetCacheValue(type).then(result => {
                var additionalContent = BuildContent(JSON.parse(result[value]), value, props.context, props.depth);

                props.value.push(additionalContent);
                props.context.UpdateContent(props.value, props.depth);
                GenerateRules();
            });
        }
    }

    //value is a string
    const OnRemoveRule =(index)=> {

        if (index != null) {
            props.value.splice(index, 1);
            props.context.UpdateContent(props.value, props.depth);
            GenerateRules();
        }
    }


    return (
        <div className={classes.root} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <ExpansionPanel >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className={classes.heading}>{props.label}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails >
                    <div style={{ width: "100%" }}>
                        {content}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}