import React, { useEffect } from "react";
import Select from 'react-select';
import ContentCache from '../../utilities/Cache Utilities/ContentCache';
//import style from './../../styles/Main.css';
import DropDownOption from './../../models/DropDownOption';
var snake = require('to-snake-case');

//models
import Id from './../../models/Id';

import Context from './../../models/Context'

//import { Linker } from './../../utilities/linking utilities/Linker'

interface Props {
    label: string,
    depth: Array<string>,
    value: Id
    schemaType: string,
    context: Context//TODO you will need a context object eventually
}

interface Option {
    label: string,
    value: Id | null
}

const defaultSelectedOption: DropDownOption<Id|null> = { label: "none", value: null }
const defaultOptions: Array<Option> = [];

export const IdSelectorComponent: React.FC<Props> = (props) => {

    const [value, UpdateValue] = React.useState(props.value);
    const [selectedOption, UpdateSelectedOption] = React.useState(defaultSelectedOption);
    const [options, UpdateOptions] = React.useState(defaultOptions);

    useEffect(() => {
        SetOptions();
    }, [props.value])

    const OnUpdateValue = async (event: any) => {
        //var linker = new Linker();
        var index = props.depth[0];
        //var linkID = props.context.content[index].id;
        //await linker.ChangeLink(value, event.value, linkID, props.depth, props.context);

        let value = event.value;

        if ((typeof(value) == "object")&&(!("type" in value)))
        {
            value = {"value":value.value,"type":snake(props.schemaType)};
        }

        UpdateValue(value);
        UpdateSelectedOption(event);
        
        var depth = props.depth.slice();
        props.context.UpdateContent(value, depth);
    }

    const OnLinkClicked = async ( ) =>{

        let dataType = "";

        if (selectedOption.value != null)
            dataType =selectedOption.value.type;

        if (typeof(dataType) == "undefined")
            dataType = snake(props.schemaType)
        
        var subSelection:number = 0;

        for(var index in options)
        {
            var option = options[index];
            if (option.label == selectedOption.label)
            {
                subSelection = parseInt(index);
            }
        }

        var substring = dataType.substring(dataType.length-10,dataType.length);

        if (substring == "_hex_model")
            dataType = "hex_rule_model"

        await props.context.ChangeMode(dataType,subSelection);
    }

    const SetOptions = async () => {

        var content = await new ContentCache().GetCacheValue(props.schemaType);
        var options: Array<Option> = [];
        var selectedOption: Option = { label: "none", value: null };

        for (var item in content) {
            var option = content[item].admin_console_display_name.replace(/_/gi, ' ');
            var id: any = content[item].id;

            let currentValue:any = value;

            if (typeof(value) =="object")
            {
                currentValue = value.value;
            }

            if (JSON.stringify(id) == JSON.stringify(value)) {
                selectedOption = { label: option, value: id };
            }

            else if (JSON.stringify(content[item].id) == JSON.stringify(currentValue)) {
                selectedOption = { label: option, value: id };
            }

            options.push({ label: option, value: id });
        }

        //case there is no value, we give a default value
        if ((selectedOption.value == null) && (options.length > 0)) {

            selectedOption = options[0];

            var depth = props.depth.slice();
            props.context.UpdateContent(selectedOption.value, depth);
        }

        UpdateOptions(options);
        UpdateSelectedOption(selectedOption);
    }

    return (
        <div>
            <div className="form-inline form-inline-id">
                <p>{props.label}</p>

                <Select
                    className="form-control-id"
                    options={options}
                    onChange={OnUpdateValue}
                    value={selectedOption}
                />
                <button onClick={OnLinkClicked}>link</button>
            </div>
        </div>
    )
}
