import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Context from './../../models/Context'
var snake = require('to-snake-case');

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
}));

interface Props
{
    value: string,
    depth: Array<string>,
	context: Context //you will need a context object eventually
}

const LabelComponent = (props : React.PropsWithChildren<Props>) => {
    const classes = useStyles();
  
    const [label, setLabel] = React.useState(props.value);
  
    return (
      <TextField
      id="standard-dense"
      value={label}
      className={classes.textField}
      margin="dense"
      onChange={(event)=>setLabel(event.target.value )}
      onBlur={()=>{props.context.UpdateContent(snake(label), props.depth);}}
    />
    );
}

export default LabelComponent