import React from "react";
import Select from 'react-select';
import { BuildComponentFromSchema } from "./../utilities/website building utilities/SchemaBuilder";
import {BuildContent} from "./../utilities/website building utilities/ContentBuilder";
import TypeContainer, {TypeContainerProps} from "./TypeContainer";
import SchemaCache from './../utilities/Cache Utilities/SchemaCache';
import PropTypes from 'prop-types';

import Context from './../models/Context'

var snake = require('to-snake-case');

interface HexRuleContainerProps extends TypeContainerProps {
    context: Context,
}

export class HexRuleContainer extends TypeContainer {

    constructor(props : HexRuleContainerProps) {
        super(props);

        var index = 0;
        var templates = [];
        var options = [];
        for (var template in props.schema)
        {
            templates.push(template);
            options.push({label:template.replace(/_/gi, ' '),value:index})
            index++;
        }

        var startSchema : Array<any> = [];

        this.state = {
            ...this.state,
            selectedTemplate: 0,
            templates: templates,
            schema: startSchema,
            templateOptions: options
        }
    }

    componentDidMount() 
    {
        super.componentDidMount();
        this.PullSchema();
    }


    PullSchema()
    {
        var type = this.props.context.content[this.props.context.subSelection].type

        new SchemaCache().GetCacheValue(type).then((result)=>{

            this.setState({schema:result});
        })
    }

    render() {

        if (Object.keys(this.state.schema).length > 0)
        {
            return (
                <div className="container-main">
                    {super.GetSelectionJSX("Select A Hex Rule",()=>this.PullSchema())}
    
                    <br/><hr></hr>
                    <div className="form-inline form-inline-custom">
                        <div className="row">
                            <label>Display Name</label>
                            <input className="form-control" type="text" placeholder="Enter Display Name" id="DisplayName" onChange={(event) => this.setState({ newDisplayName: snake(event.target.value) })} />
                        </div>
                    </div>
                    <div className="row">
                            <label>Select A Hex Rule Template</label>
    
                            <div style={{ width: "400px" }}>
                           <Select 
                                className="form-control-main"
                                value = {{label:this.state.templates[this.state.selectedTemplate],value:this.state.selectedTemplate}}
                                onChange={event =>  this.setState({selectedTemplate:event.value})}
                                options={ this.state.templateOptions}/>
                                </div>
                        </div>
                        <button className="btn btn-success" onClick={()=>super.CreateElement(this.state.templates[this.state.selectedTemplate],(schema)=>this.setState({schema:schema}))}>Create New Hex Rule</button>
                        <button className="btn btn-success" onClick={()=>super.DuplicateContent(this.state.templates[this.state.selectedTemplate])}>{"Duplicate"}</button>

                    <br/>
                    {super.GenerateContent(["location","encounter_data_id","_id"],this.state.schema)}
    
                    <br/><br/>
                    {super.GetDeleteJsx("Delete Hex Rule")}
    
                    <br/><br/><hr/>
                    {this.state.publishDisplay}
                </div>
            );
        }
        else
        {
            return <div>loading...</div>
        }
       
    }
}
