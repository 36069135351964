import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';


import Context from './../../models/Context'
import ContentCache from '../../utilities/Cache Utilities/ContentCache';
import SchemaCache from '../../utilities/Cache Utilities/SchemaCache';

import { MenuItem } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { ContextReplacementPlugin } from "webpack";


interface Props
{
    activeTab: number,
    context: Context
    schema: any
}

enum TerrainCondition {
    Road = "Road",
    Water = "Water",
    Park = "Park",
    Ground = "Ground",
    AreaOfIntrest = "AreaOfIntrest",
    Graveyard = "Graveyard",
    Freeway = "Freeway",
    Golfcourse = "Golfcourse"
}

export const SpawnInfoTableComponent:React.FC<Props> = (props) => {

    const emptyArray : Array<any> = [];
    const [CachedSpawnInfo, UpdateCachedSpawnInfo] = React.useState(emptyArray);
    const [Holidays, UpdateHolidays] = React.useState(emptyArray)
    const [SelectedHoliday, UpdateSelectedHoliday] = React.useState(null);
    const [TerrainConditions, UpdateTerrainConditions] = React.useState(emptyArray);
    const [SelectedTerrainCondition, UpdateSelectedTerrainCondition] = React.useState(null);
    const [Days, UpdateDays] = React.useState(emptyArray);
    const [SelectedDay, UpdateSelectedDay] = React.useState(null);
    const defaultTime = "07:30";
    const [IsUsingTime, UpdateIsUsingTime] = React.useState(false)
    const [SelectedTime, UpdateSelectedTime] = React.useState(defaultTime);

    var LoadData = async () => {
        var holidays = await new ContentCache().GetCacheValue("HolidaySchema");
        UpdateHolidays(holidays);

        var schema = await new SchemaCache().GetCacheValue("spawn_info_schema");

        var terrainString = schema.properties.terrain_conditions.AdminConsoleDictionaryAttribute;
        terrainString = terrainString.replaceAll('[', '');
        terrainString = terrainString.replaceAll(']', '');
        terrainString = terrainString.replaceAll('\"', '');
        var terrainStrings = terrainString.split(',');
        UpdateTerrainConditions(terrainStrings);

        var dayString = schema.properties.weekly_spawn_conditions.AdminConsoleDictionaryAttribute;
        dayString = dayString.replaceAll('[', '');
        dayString = dayString.replaceAll(']', '');
        dayString = dayString.replaceAll('\"', '');
        var dayStrings = dayString.split(',');
        UpdateDays(dayStrings);

        var spawnInfo = await new ContentCache().GetCacheValue("SpawnInfos");
        UpdateCachedSpawnInfo(spawnInfo);

    }

    useEffect(() => {
        LoadData();
    }, [])

    useEffect(() => {

    }, [props.context.secondaryContent])

    const classes = useStyles();

    var GetHolidayMenuItems = () => {
        var items = [];

        items.push(<MenuItem value={null}>None</MenuItem>);

        for(var key in Holidays) {
            var holiday = Holidays[key];
            items.push(<MenuItem value={holiday}>{holiday.admin_console_display_name}</MenuItem>);
        }

        return items
    }

    var GetTerrainMenuItems = () => {
        var items = [];

        items.push(<MenuItem value={null}>None</MenuItem>);

        for(var key in TerrainConditions) {
            var terrain = TerrainConditions[key];
            items.push(<MenuItem value={terrain}>{terrain}</MenuItem>);
        }

        return items;
    }

    var GetDayMenuItems = () => {
        var items = [];

        items.push(<MenuItem value={null}>None</MenuItem>);

        for(var key in Days) {
            var day = Days[key];
            items.push(<MenuItem value={day}>{day}</MenuItem>);
        }

        return items;
    }

    function GetSpawnRate (element) {
        var rate = 1;
        var data;

        for (var key in CachedSpawnInfo) {
            var spawnInfo = CachedSpawnInfo[key];
            if(spawnInfo.id.value == element.id) {
                data = spawnInfo;
                break;
            }
        }

        for (var key in props.context.secondaryContent) {
            var info = props.context.secondaryContent[key];
            if(info.id.value == element.id) {
                data = info;
                break;
            }
        }

        if(data != undefined) {

            if (SelectedHoliday != null && "holidays" in data && data.holidays != 0) {                
                for (var key in data.holidays) {
                    var holidaySpawnRate = data.holidays[key];

                    if(holidaySpawnRate.holiday.value == SelectedHoliday.id) {

                        rate *= holidaySpawnRate.weight;
                        break;
                    }
                }
            }

            if (SelectedTerrainCondition != null && "terrain_conditions" in data) {
                if(SelectedTerrainCondition in data.terrain_conditions) {
                    rate *= data.terrain_conditions[SelectedTerrainCondition]
                }
            }

            if (IsUsingTime && SelectedTime != null && "time_of_day_conditions" in data && data.time_of_day_conditions != 0) {
                for (var key in data.time_of_day_conditions) {
                    var timeOfDayCondition = data.time_of_day_conditions[key]

                    var earliest = timeOfDayCondition.earliest_spawn_time.substring(11, 16)
                    var latest = timeOfDayCondition.latest_spawn_time.substring(11, 16)

                    if(earliest <= SelectedTime && SelectedTime <= latest) {
                        rate *= timeOfDayCondition.weight;
                    }
                }
            }

            if (SelectedDay != null && "weekly_spawn_conditions" in data) {
                if (data.weekly_spawn_conditions != 0 && SelectedDay in data.weekly_spawn_conditions) {
                    rate *= data.weekly_spawn_conditions[SelectedDay]
                }
            }


        }
    
        return rate;  
    }

    return (
    <div>
        <form className={classes.container} noValidate>

            <span className ="form-inline form-inline-custom">
                <p>Holiday</p>
                <Select
                    className="form-control-id"
                    onChange={(event) => UpdateSelectedHoliday(event.target.value)}
                    value={SelectedHoliday}
                >
                    {GetHolidayMenuItems()}
                </Select>
            </span>

            <br></br>

            <span className ="form-inline form-inline-custom">
                <p>Terrain Conditions</p>
                <Select
                    className="form-control-id"
                    onChange={(event) => UpdateSelectedTerrainCondition(event.target.value)}
                    value={SelectedTerrainCondition}
                >
                    {GetTerrainMenuItems()}
                </Select>
            </span>

            <br></br>
            
            <span className ="form-inline form-inline-custom">
                <p>Time of Day Condition</p>
                <TextField
                    disabled={!IsUsingTime}
                    id="time"
                    type="time"
                    defaultValue={defaultTime}
                    className={classes.textField}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    inputProps={{
                    step: 300, // 5 min
                    }}
                    onChange={(event) => {
                        console.log(event.target.value)
                        UpdateSelectedTime(event.target.value)
                    }}
                />

                <p>Active</p>
                <Checkbox
                    checked={IsUsingTime}
                    onChange={(event) => UpdateIsUsingTime(event.target.checked)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </span>

            <br></br>

            <span className ="form-inline form-inline-custom">
                <p>Weekly Spawn Condition</p>
                <Select
                    className="form-control-id"
                    onChange={(event) => UpdateSelectedDay(event.target.value)}
                    value={SelectedDay}
                >
                    {GetDayMenuItems()}
                </Select>
            </span>
        </form>
        
        

        

        {BasicTable(props.context.content, GetSpawnRate)}
    </div>);
}

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 650,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
  }));
  
function createData(name : string, spawnRate : number) {
    return { name, spawnRate};
}

function createRows(content, GetSpawnRate) {
    var array : Array<{name, spawnRate}> = [];

    
    for (const key in content) {
        if (Object.prototype.hasOwnProperty.call(content, key)) {
            const element = content[key];
            array.push(createData(element.admin_console_display_name, GetSpawnRate(element)))
        }
    }

    return array;
}



export default function BasicTable(content, GetSpawnRate) {
    const classes = useStyles();
    var rows = createRows(content, GetSpawnRate);

return (
    <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="simple table">
        <TableHead>
        <TableRow>
            <TableCell>Item</TableCell>
            <TableCell align="right">Spawn Rate</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row) => (
            <TableRow key={row.name}>
            <TableCell component="th" scope="row">
                {row.name}
            </TableCell>
            <TableCell align="right">{row.spawnRate}</TableCell>
            </TableRow>
        ))}
        </TableBody>
    </Table>
    </TableContainer>
);
}