import React, { useEffect } from "react";
import Select from 'react-select';
import SchemaCache from '../utilities/Cache Utilities/SchemaCache';
import ContentCache from '../utilities/Cache Utilities/ContentCache';
import { Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

interface Props
{
    selectedOptions: any,
    schemaType: string,
    allowDuplicates: boolean,
    depth: Array<string>,
    keysToIgnore: Array<string>,
    add: Function,
    remove: Function
}

interface Option {
    label: string,
    value: any
}

export const SchemaSelectorComponent:React.FC<Props> = (props) => {
    var startOptions : Array<Option> = [];
    var startOption : Option = null;
    const [addOptions, UpdateAddOptions] = React.useState(startOptions);
    const [removeOptions, UpdateRemoveOptions] = React.useState(startOptions);
    const [selectedAddOption, UpdateSelectedAddOption] = React.useState(startOption);
    const [selectedRemoveOption, UpdateSelectedRemoveOption] = React.useState(startOption);

    useEffect(()=>{
        UpdateComponent();
    },[props.selectedOptions])

    var GetContentToAdd = () => {

        var options = [];

        new SchemaCache().GetCacheValue(props.schemaType).then(result => {
            var index = 0;
            for (var option in result) {

                if ((!props.selectedOptions.includes(option))||(props.allowDuplicates)) {
                    index++;

                    var label = option.replace(/_/gi, ' ');
                    options.push({ label: label, value: option });
                }
            }

            if (options.length > 0)
                UpdateAddOptions(options);

            if(addOptions === null || !addOptions.includes(selectedAddOption)) {
                UpdateSelectedAddOption(addOptions[0]);
            }
        });
    }
    
    var GetContentToRemove = () => {

        var options = [];

        new SchemaCache().GetCacheValue(props.schemaType).then(result => {

            var schemaTypes = Object.keys(result);
           
            var trueIndex = 0;
            for (var index in props.selectedOptions)
            {
                var option =  props.selectedOptions[index];
                if (schemaTypes.includes(option)) {
                    var label = option.replace(/_/gi, ' ');
                    options.push({ label: label, value: trueIndex });
                    trueIndex++;
                }
            }

            if (options.length > 0) {
                UpdateRemoveOptions(options);
            }

            if(removeOptions === null || !removeOptions.includes(selectedRemoveOption)) {
                UpdateSelectedRemoveOption(removeOptions[0]);
            }

        });

        
    }

    

    var UpdateComponent = () => {
        GetContentToAdd();
        GetContentToRemove();
    }

    useEffect(() => {
        UpdateComponent();
        UpdateSelectedAddOption(addOptions[0]);
        UpdateSelectedRemoveOption(removeOptions[0]);
    }, [])

    var OnChangeSelectedAdd = (selectedAddOption) => {
        UpdateSelectedAddOption(selectedAddOption);
    }

    var OnChangeSelectedRemove = (selectedRemoveOption) => {
        UpdateSelectedRemoveOption(selectedRemoveOption);
    }

    const addSelection = (<Select
        className="form-control-main"
        options={addOptions}
        onChange={OnChangeSelectedAdd}
        value={selectedAddOption}
    />);

    const addButton = (<Button color="success" onClick={(event) => {
        if (selectedAddOption.label.length > 0) {
                props.add(selectedAddOption.value, props.schemaType)
        }
    }} >Add Element</Button>);


    const removeSelection = (<Select
        className="form-control-main"
        options={removeOptions}
        onChange={OnChangeSelectedRemove}
        value={selectedRemoveOption}
    />);


    const removeButton = (<Button color="danger" onClick={(event) => {
        props.remove(selectedRemoveOption.value)
    }}>Remove Element</Button>);

    return <View addSelection={addSelection} addButton={addButton} removeSelection={removeSelection} removeButton={removeButton} depth={props.depth.length} />

}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));


const View = (props) => {

    const classes = useStyles();
    return (
        <div className={classes.root} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Grid container direction="row" spacing={3} style={{ padding: 10 }}>
                <Grid item xs={2}>
                    {props.addSelection}
                </Grid>
                <Grid item xs={2}>
                    {props.addButton}
                </Grid>

                <Grid item xs={2}>
                    {props.removeSelection}
                </Grid>
                <Grid item xs={2}>
                    {props.removeButton}
                </Grid>
            </Grid>
        </div>
    );
}