import React from 'react';
import { Tooltip } from 'reactstrap';

interface Props
{
    idPointer: string,
    schema: Schema,
}

interface Schema
{
    tool_tip: string
}

export const ToolTipFieldComponent:React.FC<Props> = (props) => {

    const [toolTipOpen, UpdateToolTipOpen] = React.useState(false);
    const [idPointer, UpdateIdPointer] = React.useState(props.idPointer);
    const [schema, UpdateSchema] = React.useState(props.schema);

    var toggle = () => {
        UpdateToolTipOpen(!toolTipOpen);
    }

    toggle = toggle.bind(this);

    

    if("tool_tip" in schema)
    {
        return (
            <div>
              <Tooltip placement="right" isOpen={toolTipOpen} target={idPointer} toggle={toggle}>
                {schema.tool_tip}
              </Tooltip>
            </div>
          );
    }
    else
        return( <div></div>); 
}