import React from 'react';
import { Container, Row, Col, Input, Progress, Button } from "reactstrap";
import { IsInt} from "./../Utilities";
import { ToolTipFieldComponent } from "./../../components/static components/ToolTipFieldComponent";
var snake = require('to-snake-case');
var mongoose = require('mongoose');

class ComponentConstructor 
{
    BuildTitle(key : string, depth : Array<string>) {

        var col = "col-xs-10" + " col-xs-offset-" + depth.length; //+ (depth.length-1);
    
        const CustomTag = 'h' + (depth.length + 1);
    
        if (IsInt(key)) {
            return <div className="row" id={key} key={key}>
                <Col sm={{ offset: 0 }}>
                    <b className="title-header">{key.replace(/_/gi, ' ')}</b>
                    <br/>
                    <b>-------------</b>
            </Col>
            </div>
        }
    
        return <div className="row" id={key} key={key}>
            <Col sm={{ offset: 0 }}>
                <b className="title-header">{key.replace(/_/gi, ' ')}</b>
                <br/>
                <b>-------------</b>
            </Col>
        </div>
    }

    BuildSubTitle(key : string, depth : Array<string>, toggleButton : Array<JSX.Element> = []) {
        //var col = "col-xs-10" + " col-xs-offset-"+depth.length; //+ (depth.length-1);
    
        const CustomTag = 'h' + (depth.length + 2);
    
        return <div key={key}>
        <div className="row">
            <div className="column">
            <b className ="sub-header">{key.replace(/_/gi, ' ')+"   "}
            </b>
            <p>----</p>
            </div>
            <div className="column">
            {toggleButton}
            </div>
        </div>

        </div>
    }
    
    //COMPONENT CONSTRUCTORS 
    ConstructComponent(componentData : {}, key : string, contentValue : string, context : any, depth : Array<string>, keysToIgnore : Array<string>, schemaValue,index : number)
    {
        
        var schemaType = undefined;
        var options = undefined;
        var arrayType = undefined;
        var defaultValue = undefined;
        var multiSelect = undefined;
        var genericType = undefined;
        var allowDuplicates = undefined;
        var constructedComponent = [];

        if ("allowDuplicates" in componentData)
        {
            allowDuplicates = componentData["allowDuplicates"];
        }

        if ("schemaType" in componentData)
            schemaType = componentData["schemaType"];

        if ("options" in componentData)
        {
            options = componentData["options"];
        }
           
        if ("arrayType" in componentData)
            arrayType = componentData["arrayType"];

        if ("defaultValue" in componentData)
            defaultValue = componentData["defaultValue"];

        if ("value" in componentData)
        {
            contentValue = componentData["value"];
        }

        if ("genericType" in componentData)
        {
            genericType = componentData["genericType"];
        }

        if ("depth" in componentData)
        {
            depth = componentData["depth"];
        }

        if ("multiSelect" in componentData)
        {
            multiSelect = componentData["multiSelect"];
        }
        
        //if ("header" in componentData)
            //constructedComponent.push(this.BuildSubTitle(key, depth));
        
        var Component = componentData["component"];

        var color = "#F5E7F9";
        if (index%2 != 0)
            color = "#FAF2E8";

        var subSelection = context.subSelection;
        var displayName = context.content[subSelection].admin_console_display_name;
        var uniqueKey = context.contentMode+displayName+depth.join();

        constructedComponent.push(<div style={{ backgroundColor: color }}  id={key} key={uniqueKey} >
            <ToolTipFieldComponent idPointer={key} schema={schemaValue} /> 
            <Component label={key} value={contentValue} context={context} depth={depth} keysToIgnore={keysToIgnore} schema = {schemaValue} schemaType = {schemaType} multiSelect={multiSelect} genericType={genericType} allowDuplicates={allowDuplicates} options ={options} arrayType = {arrayType} defaultValue = {defaultValue} />
        </div>);

        return constructedComponent;
    }
}

export default ComponentConstructor;