
import Context from "./../models/Context"

interface Link {
    id: string;
    contentType: any;
    path: any;
}

function BuildLink(baseLink,context)
{
    var contentMode = context.contentMode;

    var linkContentId = JSON.stringify(context.content[baseLink[0]].id);

    var link = {"id":linkContentId,"contentType":contentMode,"path":baseLink};
    return link;
}

export const ADD_LINK = "ADD_LINK";

export interface AddLink
{
	type:typeof ADD_LINK
	content: {sourceId: number, link:Link}
}

export const AddLink = (sourceId : number, link : Link, context : Context):AddLink => ({
	type: "ADD_LINK",
	content: {sourceId: sourceId, link: BuildLink(link, context)}
});

export const REMOVE_LINK = "REMOVE_LINK";

export interface RemoveLink
{
	type:typeof REMOVE_LINK
	content: {link: Link}
}

export const RemoveLink = (link: Link, context : Context):RemoveLink => ({
	type: "REMOVE_LINK",
	content: {link: BuildLink(link, context)}
});

export const REMOVE_LINK_CONTENT = "REMOVE_LINK_CONTENT";

export interface RemoveLinkContent
{
	type:typeof REMOVE_LINK_CONTENT
	content: {linkContentId: number, contentMode: any}
}

export const RemoveLinkContent = (linkedContentId : number, context : Context):RemoveLinkContent => ({
	type: "REMOVE_LINK_CONTENT",
	content: {linkContentId: linkedContentId, contentMode: context.contentMode}
});

export const REMOVE_SOURCE = "REMOVE_SOURCE";

export interface RemoveSource
{
	type:typeof REMOVE_SOURCE
	content: {sourceId:number}
}

export const RemoveSource = (content: {sourceId:number}):RemoveSource => ({
	type: "REMOVE_SOURCE",
	content: content
});