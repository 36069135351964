import React from "react";
import PropTypes from 'prop-types';
import Context from './../../models/Context'

interface Props
{
	label: string,
	depth: Array<string>,
    value: string,
	context: Context //you will need a context object eventually
}

export const DateTimeFieldComponent:React.FC<Props> = (props) => {
    const [value, UpdateValue] = React.useState(new Date(props.value));
    const [dateDisplay, UpdateDateDisplay] = React.useState(value.toISOString().split('T')[0]);

    var GetTimeAsString = (value : Date) => {
        var offset : number = value.getTimezoneOffset()/60
        var hours : number = value.getHours();

        var hours = hours+offset;

        if ((hours > 24)||((hours ==  24) && value.getMinutes()>0))
            hours = hours%24;

        var hours_string : string = hours.toString();

        if (hours_string.length < 2)
            hours_string = "0"+hours

        var minutes_string : string = String(value.getMinutes());

        if (minutes_string.length < 2)
            minutes_string = "0"+minutes_string

        return hours_string + ":"+minutes_string;
    }

    const [timeDisplay, UpdateTimeDisplay] = React.useState(GetTimeAsString(value));

    var UpdateDate = (event : React.ChangeEvent<HTMLInputElement>) => {
        var newValue : Date = value;
        
        var dateElements = event.target.value.split('-');
        newValue.setFullYear(parseInt(dateElements[0]),(parseInt(dateElements[1])-1),parseInt(dateElements[2]));
        newValue.setHours(0,0,0,0);
        UpdateValue(newValue);
        UpdateDateDisplay(newValue.toISOString().split('T')[0]);

        props.context.UpdateContent(newValue,props.depth);
    }

    var UpdateTime = (event : React.ChangeEvent<HTMLInputElement>) => {
        var newValue = value;
        var timeElements : string[] = event.target.value.split(':');
        var offset = newValue.getTimezoneOffset()/60
        var hours = parseInt(timeElements[0])-offset;
        if ((hours > 24)||((hours ==  24)&&newValue.getMinutes()>0))
            hours = hours%24;

        newValue.setHours(hours);
        newValue.setMinutes(parseInt(timeElements[1]));
        UpdateValue(newValue);
        UpdateTimeDisplay(GetTimeAsString(newValue));

        props.context.UpdateContent(newValue,props.depth);
    }

    return (
        <span className ="form-inline form-inline-custom">
            <p>{props.label}</p>
            <input type="date" onChange={UpdateDate} value={dateDisplay} min="2018-01-01" max="2025-12-31"/>
            <input type="time" onChange={UpdateTime} value ={timeDisplay} min="00:00" max="24:00" />
        </span>
    );
}