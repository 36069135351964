import React, {useEffect} from "react";
import SchemaCache from './../../utilities/Cache Utilities/SchemaCache';

import { BuildComponentFromSchema } from "./../../utilities/website building utilities/SchemaBuilder";
import { BuildContent } from "./../../utilities/website building utilities/ContentBuilder";
import ObjectManipulator from './../../utilities/ObjectManipulator';
import PropTypes from 'prop-types';

import Context from './../../models/Context'

var ObjectID = require("bson-objectid");

interface Props
{
    spawnInfoType: string,
    schema: any,
    content: any, 
    depth: Array<string>,
	context: Context
}

export const SpawnInfoFieldComponent:React.FC<Props> = (props) => {

    var UpdateContent = (newContent, depth) => {
        props.context.UpdateSecondaryContent(depth[0], newContent, depth);
        //this.state.context.UpdateSecondaryContent(depth[0], newContent, depth);
        //this.setState({context:this.props.context})
    }

    UpdateContent = UpdateContent.bind(this);

    //sets content keywords for spawn info if it exists on the content
    var SetContentKeywords = (primaryContentIndex)=>{

        var content = props.context.content[primaryContentIndex];
        var value = props.context.secondaryContent[props.depth[0]]; 

        if ("keywords" in content)
        {
            var keywords = content["keywords"];
            value.keywords = keywords;

            return {"value":value,"depth":props.depth,"type":"secondary"};
        }
        return null;
    }

    var contextCopy = new ObjectManipulator().DeepCopy(props.context);
    contextCopy.UpdateContent = (newContent, depth) => {UpdateContent(newContent, depth)};
    contextCopy["isSecondary"]  = true;

    props.context.AddContentListener(SetContentKeywords);
    contextCopy.AddContentListener(SetContentKeywords);


    const [context, UpdateContext] = React.useState(contextCopy);

    useEffect(() => {
      
        // returned function will be called on component unmount 
        return () => {
            var SetContentKeywords = ()=>{}
            props.context.RemoveContentListener(SetContentKeywords)
        }
      }, [])
    
    

    var GenerateJSX = () => {

        var content = props.content;
        var schema = props.schema;
        var spawn_id = {"value":props.depth[0],type:props.spawnInfoType};

        if (Object.keys(content).length == 0) {
            content = BuildContent(schema, "spawn_info_schema",context,props.depth);
            content["_id"] = ObjectID();
            content["id"] = spawn_id;
        }

        if ((!(spawn_id.value in context.secondaryContent))||("remove" in context.secondaryContent[spawn_id.value])) {
            context.SetSecondaryContent(spawn_id.value, content);
        }
        
        var keysToIgnore = ["display_name", 'id'];

        var contextCopy = new ObjectManipulator().DeepCopy(context);
        contextCopy.UpdateContent = (newContent, depth) => {UpdateContent(newContent, depth)};
        contextCopy["isSecondary"]  = true;

        var UiJsx = BuildComponentFromSchema(schema, content,contextCopy, props.depth, keysToIgnore);

        return UiJsx;
    }

    return (<div>
        {GenerateJSX()}
    </div>);
}